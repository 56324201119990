import { Endpoints } from '@shared/const';
import { useStores } from '@shared/lib/mobx';
import { Codes } from '@shared/const';

import { useFetchRefreshToken } from './use-fetch-refresh-token';

import type { ErrorResponse } from '@shared/api';
import type { RefreshTokenResponse } from '../types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useMutation,
  useMutationState,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigateProfile } from '@entities/profile';
import {
  ProtectedRoutes,
  PublicRoutes,
  protectedRoutePath,
  publicRoutePath,
} from '@shared/lib/react-router';
import { setAuthCsrfToken } from '@entities/auth';

export const useHandleFetchRefreshToken = () => {
  const {
    GlobalStore: {
      authStore: { clearTokens },
      userStore: { setUser },
      queryStore: { queryKey },
    },
  } = useStores();

  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  //TODO: вынести mutationState
  const mutationState = useMutationState({
    filters: {
      status: 'error',
      predicate: mutation => {
        const error = mutation.state.error as ErrorResponse;
        return (
          error?.response?.status === Codes.ClientErrorUnauthorized &&
          error?.config?.url !== Endpoints.REFRESH_TOKEN
        );
      },
    },
    select: mutation => mutation,
  });

  const lastMutation = mutationState[mutationState.length - 1];

  const { mutateAsync: fetchProfile } = useNavigateProfile();

  const { mutateAsync: fetchLastRequest } = useMutation(lastMutation?.options);

  const onSuccess = async (data: RefreshTokenResponse) => {
    const {
      authorization: { isVip, name, userId, csrfToken },
    } = data;

    setUser({ isVip, name, userId });
    setAuthCsrfToken(csrfToken);

    //TODO: Найти лаконичное решение
    if (queryKey) {
      await queryClient.invalidateQueries({ queryKey: [queryKey] });
    } else {
      if (lastMutation) {
        await fetchLastRequest(lastMutation?.state?.variables);
      }
    }

    if (location.pathname === protectedRoutePath[ProtectedRoutes.MAIN]) {
      fetchProfile({});
    }
  };

  const onError = (e: ErrorResponse) => {
    if (e.response?.status === Codes.ClientErrorUnauthorized) {
      clearTokens();
      navigate(publicRoutePath[PublicRoutes.AUTH]);
    }
  };

  const fetchResult = useFetchRefreshToken({ onSuccess, onError });

  return { fetchResult };
};
