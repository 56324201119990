import { styled } from '@pulse-web-ui/theme';
import { MainButton } from '@shared/ui';

export const Cancel = styled(MainButton)`
  background-color: ${({ theme }) => theme.colors.surface.primary};
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent.normal};
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;
