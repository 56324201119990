import { formattingDateByRu } from '@shared/utils';
import { CONTRACT_STATUS } from '../constants';
import type { Contract, InsuranceDetailContract } from '../types';
import { getSubscriptionTerms } from './get-subscription-terms';
import i18n from '@app/i18n/config';

export const getContract = (
  contracts: Contract[],
): InsuranceDetailContract | undefined => {
  const activeContract: Contract | undefined = contracts.find(
    item => item.type === CONTRACT_STATUS.ACTIVE,
  );
  const templateContract: Contract | undefined = contracts.find(
    item => item.type === CONTRACT_STATUS.TEMPLATE,
  );

  if (activeContract && templateContract?.mustBeShown) {
    return {
      tabs: [
        i18n.t('INSURANCE_DETAIL:tab.now'),
        i18n.t('INSURANCE_DETAIL:tab.next', {
          date: formattingDateByRu(
            templateContract?.effectiveSince as string,
            'short',
          ),
        }),
      ],
      active: getSubscriptionTerms(activeContract),
      template: getSubscriptionTerms(templateContract),
      activeInsuranceCoverages: activeContract.insuranceCoverages,
      templateInsuranceCoverages: templateContract.insuranceCoverages,
      activeInsuranceSubObject: activeContract.insuranceSubObjects || [],
      templateInsuranceSubObject: templateContract.insuranceSubObjects || [],
      activeInsuranceSum: activeContract.insuranceSum,
      templateInsuranceSum: templateContract.insuranceSum,
    };
  }

  return undefined;
};
