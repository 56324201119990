import type { RouteProps } from 'react-router-dom';

import {
  AuthPage,
  OtpPage,
  MaxAttemptsErrorPage,
  RegistrationPage,
} from '@pages/index';

export enum PublicRoutes {
  AUTH = 'auth',
  OTP = 'otp',
  NOT_FOUND = 'not_found',
  MAX_ATTEMPTS_ERROR = 'max_attempts_error',
  REGISTRATION = 'registration',
}

export const publicRoutePath: Record<PublicRoutes, string> = {
  [PublicRoutes.AUTH]: '/auth',
  [PublicRoutes.NOT_FOUND]: '*',
  [PublicRoutes.OTP]: '/otp',
  [PublicRoutes.MAX_ATTEMPTS_ERROR]: '/max-attempts-error',
  [PublicRoutes.REGISTRATION]: '/registration',
};

export const publicRouteConfig: Record<PublicRoutes, RouteProps> = {
  [PublicRoutes.AUTH]: {
    path: publicRoutePath.auth,
    element: <AuthPage />,
  },
  [PublicRoutes.OTP]: {
    path: publicRoutePath.otp,
    element: <OtpPage />,
  },
  [PublicRoutes.NOT_FOUND]: {
    path: publicRoutePath.not_found,
    element: <div>Страница не найдена</div>,
  },
  [PublicRoutes.MAX_ATTEMPTS_ERROR]: {
    path: publicRoutePath.max_attempts_error,
    element: <MaxAttemptsErrorPage />,
  },
  [PublicRoutes.REGISTRATION]: {
    path: publicRoutePath.registration,
    element: <RegistrationPage />,
  },
};
