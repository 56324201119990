import { InsuranceStatus } from '@shared/types';
import i18n from '@app/i18n/config';
import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { getSubscriptionTerms } from './get-subscription-terms';
import { AVAILABLE_STATUSES_FOR_SHOW_NEXT_PAYMENT } from '../constants';
import { formattingDateByRu, formattingNumberByRu } from '@shared/utils';
import { getPolicyValidatePeriod } from './get-policy-validate-period';
import { getContract } from './get-contract';
import { ProductName } from '@entities/product';
import { getOldPrice } from './get-old-price';
import { getPolicyId } from './get-policy-id';
import { getFooterBox } from './get-footer-box';
import { getAlertTexts } from './get-alert-texts';
import { getDocumentsBlock } from './get-documents-block';

export const prepareDetailHouse = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  const hasElement = AVAILABLE_STATUSES_FOR_SHOW_NEXT_PAYMENT.includes(
    insurance.status,
  );

  const commonProps: InsuranceDetail = {
    header: {
      title: insurance.product.name,
      subTitle: insurance?.address || '',
      productIcon: ProductName.HOUSE,
    },
    showMessageButton: true,
    policyId: getPolicyId(insurance.documents),
    alert: getAlertTexts(insurance),
    footerBox: getFooterBox(insurance),
  };

  if (insurance.status === InsuranceStatus.REVOKED) {
    return {
      ...commonProps,
    };
  }

  if (insurance.status === InsuranceStatus.FINISHED) {
    return {
      ...commonProps,
      subscriptionTerms: [
        {
          label: i18n.t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
          value: getPolicyValidatePeriod(
            insurance.effectiveSince,
            insurance.endsAt,
          ),
        },
      ],
    };
  }

  return {
    ...commonProps,
    subscriptionTerms: getSubscriptionTerms(insurance.contracts[0]),
    insuranceSum: insurance.insuranceSum,
    insuranceSubObjects: insurance.insuranceSubObjects,
    insuranceCoverages: insurance.insuranceCoverages,
    nextPayment: {
      showNextPayment: hasElement,
      price: formattingNumberByRu(insurance.nextPremiumAndDelta),
      oldPrice: getOldPrice(insurance.contracts),
      nextPaymentDateTime: insurance?.nextPaymentDatetime
        ? formattingDateByRu(insurance.nextPaymentDatetime)
        : '',
      creditNum: insurance.paymentInstrument?.name || '',
    },
    documents: getDocumentsBlock(insurance),
    contract: getContract(insurance?.contracts),
    buttonsOfAction: {
      hasCanceled: { open: hasElement },
      hasReported: { open: true },
    },
  };
};
