import { styled } from '@pulse-web-ui/theme';
import { SkeletonColor } from './skeleton.types';
import type { SkeletonProps } from './skeleton.types';

export const SkeletonStyled = styled.div<SkeletonProps>`
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  height: ${({ height }) => (height ? height + 'px' : '100%')};
  background-color: ${({ color = SkeletonColor.LIGHT, theme }) => {
    if (color === SkeletonColor.LIGHT) return theme.colors.neutral[10];
    if (color === SkeletonColor.DARK) return theme.colors.neutral[20];
    return color;
  }};

  animation: 1.5s shine linear infinite;
`;
