import { Info } from '../../info';
import {
  InsuranceSubObjectsContent,
  InsuranceSubObjectsWrapper,
} from './insurance-sub-objects-modal.styles';
import { Title } from '@shared/ui';
import {
  Tooltip,
  TooltipBgColor,
  TooltipDirection,
  TriggerDirection,
} from '@pulse-web-ui/tooltip';
import { formattingNumberByRu } from '@shared/utils';
import { useStores } from '@shared/lib/mobx';

export const InsuranceSubObjectsModal = () => {
  const {
    GlobalStore: {
      insuranceSubObjectStore: { insuranceSubObjects, insuranceSum },
    },
  } = useStores();

  return (
    <>
      <Title>{formattingNumberByRu(insuranceSum)}</Title>
      <InsuranceSubObjectsWrapper>
        {insuranceSubObjects.map(insuranceSubObject => (
          <InsuranceSubObjectsContent key={insuranceSubObject.code}>
            <Info
              item={{
                label: insuranceSubObject.name,
                value: formattingNumberByRu(insuranceSubObject.insuranceSum),
              }}
            />
            <Tooltip
              arrow
              trigger={TriggerDirection.HOVER}
              direction={TooltipDirection.UP}
              tooltipText={insuranceSubObject.description}
              tooltipBgColor={TooltipBgColor.BLUE}
            />
          </InsuranceSubObjectsContent>
        ))}
      </InsuranceSubObjectsWrapper>
    </>
  );
};
