const getDateForAge = (age: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - age);
  return date;
};

export const maxBirthDate = getDateForAge(18);
maxBirthDate.setDate(maxBirthDate.getDate() - 1);

export const minBirthDate = getDateForAge(100);
