import type { InsuranceCoverage } from '@shared/types';
import type { SubscriptionTerm } from '../types';
import { Info, ModalEnums } from '@shared/const';
import i18n from '@app/i18n/config';

export const getTermRisks = (risks: InsuranceCoverage[]): SubscriptionTerm => {
  const copyRisks = [...risks];
  let value = '';

  if (copyRisks.length > 1) {
    const lastRisk = copyRisks.pop();
    const riskString = copyRisks.map(risk => risk.name).join(', ');
    value = `${riskString} и ${lastRisk?.name}`;
  } else if (copyRisks.length !== 0) {
    value = copyRisks[0].name;
  }

  return {
    label: i18n.t('INSURANCE_DETAIL:terms.insuranceRisks'),
    value: value,
    type: Info.WITH_MODAL,
    modalType: ModalEnums.SPORT_INSURANCE_RISKS,
  };
};
