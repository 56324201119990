import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@pulse-web-ui/theme';

import AppleIcon from '@shared/assets/icons/apple.svg?react';
import GooglePlayIcon from '@shared/assets/icons/google-play.svg?react';
import { APP_LINK } from '@shared/const';

import {
  StyledBox,
  StyledExternalLink,
  StyledTitle,
} from './download-app.styles';
import { PULSE_INSURE_APPS_URL } from '@shared/const/links';

export const DownloadApp = memo(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div>
      <StyledTitle>{t('COMMON:downloads.downloadApp')}</StyledTitle>
      <StyledBox>
        <StyledExternalLink
          target='_blank'
          href={APP_LINK}
          color={theme.colors.text.primary}
        >
          <AppleIcon /> <span>{t('COMMON:downloads.iOS')}</span>
        </StyledExternalLink>
        <StyledExternalLink
          target='_blank'
          href={PULSE_INSURE_APPS_URL}
          color={theme.colors.text.primary}
        >
          <GooglePlayIcon /> <span>{t('COMMON:downloads.android')}</span>
        </StyledExternalLink>
      </StyledBox>
    </div>
  );
});
