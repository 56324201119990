import { AndFurther } from './territories.styles';
import { useCoverageSummary } from '@shared/hooks';
import type { EntityCodeAndName } from '@shared/types';
import { useStores } from '@shared/lib/mobx';
import { useCallback } from 'react';
import { ModalEnums } from '@shared/const';

export const Territories = ({
  territtories,
}: {
  territtories: EntityCodeAndName[];
}) => {
  const { fullItems, remainingText, isShortList } = useCoverageSummary({
    items: territtories,
  });

  const {
    GlobalStore: {
      modalStore: { setModal },
    },
  } = useStores();

  const handleClick = useCallback(
    () => setModal({ modal: ModalEnums.COUNTRIES_TRAVEL || null }),
    [setModal],
  );

  if (isShortList) {
    return <span>{fullItems}</span>;
  }

  return (
    <span>
      {fullItems + ' '}
      <AndFurther onClick={handleClick}>{remainingText}</AndFurther>
    </span>
  );
};
