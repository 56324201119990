import { NavLink } from 'react-router-dom';
import { media, styled } from '@pulse-web-ui/theme';

export const LinkStyled = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 8px;
  row-gap: 2px;
  width: 100%;
  height: 100%;
  border-radius: calc(${({ theme }) => theme.common.radius} * 2);
  color: ${({ theme }) => theme.colors.text.secondary};

  &.active {
    color: ${({ theme }) => theme.colors.text.interactive};
  }

  ${media.desktop} {
    padding: 0 12px;
    flex-direction: row;
    color: ${({ theme }) => theme.colors.text.primary};
    &.active {
      background-color: ${({ theme }) => theme.colors.surface.background};
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;
