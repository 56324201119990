import { media, typography, styled } from '@pulse-web-ui/theme';
import { TitleDetail } from '../shared';

export const StyledBox = styled.div`
  ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledTitle = styled(TitleDetail)`
  margin-bottom: 8px;
  ${media.desktop} {
    margin-bottom: 16px;
  }
`;

export const StyledHint = styled.div`
  color: ${({ theme }) => theme.colors.text.success};
  margin-top: 2px;
  ${typography.regular16}
  line-height: 24px;
`;

export const StyledPaymentBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${media.desktop} {
    margin-top: 0;
  }
  margin-top: 12px;
`;

export const StyledCardNumber = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const PriceWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const Price = styled.div`
  ${typography.medium18}
  color: ${({ theme }) => theme.colors.text.primary};

  ${media.desktop} {
    ${typography.medium20}
  }
`;

export const OldPrice = styled.div`
  ${typography.medium16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: line-through;
`;
