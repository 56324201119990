import { useTheme } from '@pulse-web-ui/theme';

export const useGetByCode = () => {
  const theme = useTheme();

  const bgByCode: Record<string, string> = {
    '08.15': `${theme.colors.product.apartment}`,
    '08.16': `${theme.colors.product.house}`,
    EXTERNAL_WEB: `${theme.colors.product.mortgage}`,
    '03.08': `${theme.colors.product.travel}`,
    '02.13': `${theme.colors.product.sport}`,
    '00.05': `${theme.colors.product.antimite}`,
    '00.04': `${theme.colors.product.pet}`,
  };

  return bgByCode;
};
