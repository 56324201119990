import { auth, authNamespaces } from './auth';
import { common, commonNamespaces } from './common';
import { otp, otpNamespaces } from './otp';
import { register, registerNamespaces } from './register';
import { agreement, agreementNamespaces } from './agreement';
import {
  maxAttemptsErrorNamespaces,
  maxAttemptsError,
} from './max-attempts-error';
import { errorNamespaces, error } from './error';
import { insuranceNamespaces, insurance } from './insurance';
import { insuranceDetailNamespaces, insuranceDetail } from './insurance-detail';
import { contract, contractNamespaces } from './contract';

export const resources = {
  ru: {
    ...auth,
    ...common,
    ...otp,
    ...agreement,
    ...register,
    ...maxAttemptsError,
    ...error,
    ...insurance,
    ...insuranceDetail,
    ...contract,
  },
};

export const namespaces = [
  ...authNamespaces,
  ...commonNamespaces,
  ...otpNamespaces,
  ...agreementNamespaces,
  ...registerNamespaces,
  ...maxAttemptsErrorNamespaces,
  ...errorNamespaces,
  ...insuranceNamespaces,
  ...insuranceDetailNamespaces,
  ...contractNamespaces,
];
