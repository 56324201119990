import type { EntityCodeAndName } from '@shared/types';
import { declineWord } from '@shared/utils/decline-word';
import type { SubscriptionTerm } from '../types';
import { Info, ModalEnums } from '@shared/const';
import { ProductName } from '@entities/product';
import i18n from '@app/i18n/config';

const declensions = {
  one: i18n.t('INSURANCE_DETAIL:declensions.sport-1'),
  few: i18n.t('INSURANCE_DETAIL:declensions.sport-2'),
  many: i18n.t('INSURANCE_DETAIL:declensions.sport-3'),
};

export const getWordListing = (
  entity: EntityCodeAndName[],
  variant: ProductName = ProductName.SPORT,
): SubscriptionTerm => {
  const length = entity.length;

  const label = i18n.t(
    `INSURANCE_DETAIL:terms.${variant === ProductName.SPORT ? 'kindsOfSports' : 'worksWhenPlayingSports'}`,
  );

  const modalType =
    variant === ProductName.SPORT
      ? ModalEnums.SPORTS_KINDS
      : ModalEnums.TRAVEL_ACTIVITIES;

  const joinSymbol = i18n.t('COMMON:unions:and');

  if (length >= 3) {
    return {
      label,
      modalType,
      value: declineWord(length, declensions),
      type: Info.WITH_MODAL,
    };
  }

  return {
    label,
    value: entity.map(sport => sport.name).join(joinSymbol),
  };
};
