import { Trans, useTranslation } from 'react-i18next';
import i18n from '@app/i18n/config';
import {
  BoxStyled,
  StyledInsuranceSubTitle,
  StyledPhoneLink,
  TitleDetail,
  DetailSubtitle,
} from '../shared';
import { openMobileAppLink } from '@shared/utils';

interface ShortMiteMessageProps {
  showOtherBiteMessage?: boolean;
}

export const ShortMiteMessage = ({
  showOtherBiteMessage,
}: ShortMiteMessageProps) => {
  const { t } = useTranslation();
  return (
    <BoxStyled>
      <TitleDetail>{t('INSURANCE_DETAIL:text:getHelpFromMite')}</TitleDetail>
      <DetailSubtitle>{t('INSURANCE_DETAIL:text:whenMiteBite')}</DetailSubtitle>
      <StyledInsuranceSubTitle>
        <Trans
          components={{
            a: <StyledPhoneLink />,
          }}
        >
          {i18n.t('INSURANCE_DETAIL:text:helpAfterMite')}
        </Trans>
      </StyledInsuranceSubTitle>
      {showOtherBiteMessage && (
        <>
          <DetailSubtitle>
            {t('INSURANCE_DETAIL:text:whenOtherBites')}
          </DetailSubtitle>
          <StyledInsuranceSubTitle>
            {i18n.t('INSURANCE_DETAIL:text:helpInOtherMites')}
          </StyledInsuranceSubTitle>
          <StyledInsuranceSubTitle>
            <Trans
              components={{
                a: <StyledPhoneLink />,
                button: <StyledPhoneLink onClick={openMobileAppLink} />,
              }}
            >
              {i18n.t('INSURANCE_DETAIL:text:whenNothingDangerYourHealth')}
            </Trans>
          </StyledInsuranceSubTitle>
        </>
      )}
    </BoxStyled>
  );
};
