import { Button } from '@pulse-web-ui/button';
import { styled } from '@pulse-web-ui/theme';

export const ButtonStyled = styled(Button)`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  padding: 8px;
  padding-left: 12px;
  border: none;

  &:hover,
  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.neutral[20]};
  }
`;
