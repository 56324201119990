import type { FC, ReactNode } from 'react';
import { HintInfoDetail, SubTitleInfoDetail, TitleInfoDetail } from '../shared';
import { Wrapper } from './info-static.styles';

type Props = {
  title?: string;
  subTitle: ReactNode;
  hint?: ReactNode;
};

export const InfoStatic: FC<Props> = ({ title, subTitle, hint }) => {
  return (
    <Wrapper>
      <TitleInfoDetail>{title}</TitleInfoDetail>
      <SubTitleInfoDetail>{subTitle}</SubTitleInfoDetail>
      {hint && <HintInfoDetail>{hint}</HintInfoDetail>}
    </Wrapper>
  );
};
