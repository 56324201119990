import { DURATION, Info, ModalEnums } from '@shared/const';
import type {
  Contract,
  GetSubscriptionInfoResponse,
  SubscriptionTerm,
} from '../types';
import { getPolicyValidatePeriod } from './get-policy-validate-period';
import { t } from 'i18next';
import { formattingNumberByRu } from '@shared/utils';
import { Risks } from '@shared/ui';
import { ContractDuration } from '@shared/types';

const CURRENT_PREMIUM_AND_DELTA = 'currentPremiumAndDelta';
const PREMIUM_AND_DELTA = 'premiumAndDelta';
const CURRENT_CONTRACT_DURATION = 'currentContractDuration';
const CONTRACT_DURATION = 'contractDuration';

export const getSubscriptionTerms = (
  insurance: Contract | GetSubscriptionInfoResponse,
): SubscriptionTerm[] => {
  let premiumAndDelta = 0;
  let contractDuration: ContractDuration = ContractDuration.ONE_MONTH;

  if (CURRENT_PREMIUM_AND_DELTA in insurance) {
    premiumAndDelta = insurance.currentPremiumAndDelta;
  } else if (PREMIUM_AND_DELTA in insurance) {
    premiumAndDelta = insurance.premiumAndDelta;
  }

  if (CURRENT_CONTRACT_DURATION in insurance) {
    contractDuration = insurance.currentContractDuration;
  } else if (CONTRACT_DURATION in insurance) {
    contractDuration = insurance.contractDuration;
  }

  const terms: SubscriptionTerm[] = [
    {
      label: t('INSURANCE_DETAIL:terms.costAndPaymentPeriod'),
      value: `${formattingNumberByRu(premiumAndDelta)} / ${DURATION[contractDuration]}`,
    },
    {
      label: t('INSURANCE_DETAIL:terms.totalSumInsured'),
      value: formattingNumberByRu(insurance.insuranceSum),
      type: Info.WITH_MODAL,
      modalType: ModalEnums.TOTAL_SUM_INSURED,
    },
    {
      label: t('INSURANCE_DETAIL:terms.insuranceRisks'),
      value: Risks({ risks: insurance.insuranceCoverages }),
      type: Info.WITH_MODAL,
      modalType: ModalEnums.INSURANCE_RISKS,
    },
    {
      label: t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
      value: getPolicyValidatePeriod(
        insurance.effectiveSince,
        insurance.endsAt,
      ),
    },
  ];

  if (insurance?.contractNumber) {
    terms.push({
      label: t('INSURANCE_DETAIL:terms.policyNumber'),
      value: insurance.contractNumber,
      type: Info.WITH_DOWNLOAD,
    });
  }

  return terms;
};
