import { Link } from 'react-router-dom';
import logo from '@shared/assets/icons/logo.png';
import {
  ProtectedRoutes,
  protectedRoutePath,
  useIsPublicRoute,
} from '@shared/lib/react-router';
import { PULSE_INSURE_URL } from '@shared/const';

import type { Size } from './types';

import { LogoStyled } from './logo.styles';

interface LogoProps {
  size?: Size;
}

export const Logo = ({ size }: LogoProps) => {
  const isPublicRoute = useIsPublicRoute();

  const logoLinkUrl = isPublicRoute
    ? PULSE_INSURE_URL
    : protectedRoutePath[ProtectedRoutes.MAIN];

  return (
    <Link to={logoLinkUrl}>
      <LogoStyled size={size} src={logo} alt='logo' />
    </Link>
  );
};
