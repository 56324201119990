import { css, styled } from '@pulse-web-ui/theme';


export const ExternalLinkStyled = styled.a<{
  color?: string
}>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.interactive};
  ${({ color }) => color && css`
      color: ${({ theme }) => (color ? color : theme.colors.text.interactive)};
  `}
`;
