const pluralRules = new Intl.PluralRules('ru');
export const declineWord = (number: number, words: Record<string, string>) => {
  return `${number} ${words[pluralRules.select(number)]}`;
};

export const declineTwoWords = ({
  firstWords,
  secondWords,
  number,
}: {
  firstWords: Record<string, string>;
  secondWords: Record<string, string>;
  number: number;
}) => {
  return `${firstWords[pluralRules.select(number)]} ${number} ${secondWords[pluralRules.select(number)]}`;
};

export const declineWordWithoutNumber = (
  number: number,
  words: Record<string, string>,
) => {
  return `${words[pluralRules.select(number)]}`;
};
