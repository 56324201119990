import { NavLink } from 'react-router-dom';

import { media, styled, typography } from '@pulse-web-ui/theme';

export const NavLinkStyled = styled(NavLink)<{
  color?: string;
}>`
  color: ${({ theme, color }) => color || theme.colors.surface.brand};
  width: 100%;

  ${media.desktop} {
    width: fit-content;
  }
`;

export const MobileNavLinkAsButtonStyled = styled(NavLinkStyled)`
  background-color: ${({ theme }) => theme.colors.surface.brand};
  color: ${({ theme }) => theme.colors.text.inverse};
  ${typography.medium12}
  letter-spacing: 0.04em;
  text-align: center;
  padding: 4px 8px;
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  text-transform: uppercase;
  width: fit-content;
`;
