import { useTranslation } from 'react-i18next';
import { QRCodeDownloadAppImage } from '@shared/assets';
import { ImageWrapper } from './qr-code-download-app.styles';
import { useState } from 'react';
import { Loader } from '../loader';

export const QRCodeDownloadApp = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadImage = () => setLoading(false);

  return (
    <ImageWrapper>
      {loading && <Loader />}
      <img
        onLoad={handleLoadImage}
        src={QRCodeDownloadAppImage}
        alt={t('INSURANCE_DETAIL:alt.QRCodeForDownloadApp')}
        style={{ display: loading ? 'none' : 'block' }}
      />
    </ImageWrapper>
  );
};
