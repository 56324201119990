import type { GetSubscriptionInfoResponse } from '@entities/insurance-detail/model/types';
import {
  StyledDetailHeaderSubTitle,
  StyledDetailSubTitleSpan,
} from '@entities/insurance-detail/ui/shared';
import { convertPerson } from '@shared/utils';

interface HeaderSubTitleProps {
  insurance: GetSubscriptionInfoResponse;
  showFullName?: boolean;
}

export const HeaderSubTitle = ({
  insurance,
  showFullName,
}: HeaderSubTitleProps) => {
  const dot = insurance.insuredPersons && `·`;
  return (
    <StyledDetailHeaderSubTitle>
      <StyledDetailSubTitleSpan>
        {insurance.antimiteDetails?.program} {dot}
      </StyledDetailSubTitleSpan>
      <div>{convertPerson(insurance.insuredPersons, showFullName)}</div>
    </StyledDetailHeaderSubTitle>
  );
};
