import { useAxiosInterceptors } from '@shared/lib/axios';
import './i18n/config';
import { AppRouter } from './providers/router';
import { observer } from 'mobx-react-lite';
import { RefreshBox } from '@features/refresh-box';

export const App = observer(() => {
  useAxiosInterceptors();
  return (
    <RefreshBox>
      <AppRouter />
    </RefreshBox>
  );
});
