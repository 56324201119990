export enum SkeletonColor {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export type SkeletonProps = Partial<{
  color: SkeletonColor | string;
  height: number;
  width: number;
}>;
