import { styled, media } from '@pulse-web-ui/theme';

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.neutral[0]};

  height: 58px;
  padding: 16px;

  ${media.desktop} {
    height: 80px;
    padding: 16px 56px;
  }
`;
