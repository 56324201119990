import type { FC, PropsWithChildren } from 'react';

import { WrapperStyled } from './wrapper.styles';
import { Loader } from '../loader';
import { observer } from 'mobx-react-lite';

type Props = {
  className?: string;
  loading?: boolean | number;
};

export const Wrapper: FC<PropsWithChildren<Props>> = observer(
  ({ children, className, loading }) => {
    return (
      <WrapperStyled className={className}>
        {loading ? <Loader /> : children}
      </WrapperStyled>
    );
  },
);
