import i18n from '@app/i18n/config';
import { PrevInsuranceStatus } from './prev-insurance-status';

export const PREV_INSURANCE_MESSAGE_STATUS = {
  [PrevInsuranceStatus.DRAFT]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.draft',
  ),
  [PrevInsuranceStatus.READY]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.ready',
  ),
  [PrevInsuranceStatus.SIGNED]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.signed',
  ),
  [PrevInsuranceStatus.CANCELLED]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.canceled',
  ),
  [PrevInsuranceStatus.REVOKED]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.revoked',
  ),
  [PrevInsuranceStatus.TERMINATED]: i18n.t(
    'INSURANCE_DETAIL:prevInsuranceStatus.terminated',
  ),
};
