import { mixins, typography, styled, css, media } from '@pulse-web-ui/theme';

export const TabLabel = styled.div`
  ${typography.regular16}
`;

export const TabsListWrapper = styled.div`
  ${mixins.flex({ gap: 4, wrap: false })};
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${media.desktop} {
    flex-wrap: wrap;
  }
`;

interface TabButtonProps {
  isActive: boolean;
}

export const TabButton = styled.div<TabButtonProps>`
  border: 2px solid ${({ theme }) => theme.colors.neutral[20]};
  ${({ isActive }) =>
    isActive &&
    css`
      border-color: ${({ theme }) => theme.colors.surface.brand};
      background: ${({ theme }) =>
        theme.colors.surface.interactive['interactive-light']};
    `}

  ${typography.regular16}
  line-height: 24px;
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  padding: 8px 16px;
  cursor: pointer;
`;
