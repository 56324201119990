import type { GetSubscriptionInfoResponse } from '../types';
import { getInsuranceStatuses } from './get-insurance-statuses';

export const getFooterBox = (insurance: GetSubscriptionInfoResponse) => {
  const { isRevoked } = getInsuranceStatuses(insurance.status);

  if (!insurance.previousContracts?.length || isRevoked) {
    return undefined;
  }

  return {
    hasActiveContract: insurance.hasActiveContract,
  };
};
