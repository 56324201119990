import { formattingNumberByRu } from '@shared/utils';
import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { getSubTitleForSport } from './get-sub-title-for-sport';
import i18n from '@app/i18n/config';
import { getTermInsurer } from './get-term-insurer';
import { getWordListing } from './get-word-listing';
import { getTermRisks } from './get-term-risks';
import { getTermPolicyValidityPeriod } from './get-term-policy-validity-period';
import { InsuranceStatus } from '@shared/types';
import { Info, ModalEnums } from '@shared/const';
import { ProductName } from '@entities/product';
import { STATUS_INSURANCE_SNIPPET } from '../constants';
import { getPolicyId } from './get-policy-id';

export const prepareDetailSport = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  return {
    header: {
      title: insurance.product.name,
      subTitle: '',
      productIcon: ProductName.SPORT,
      status: {
        status: STATUS_INSURANCE_SNIPPET[insurance.status],
        message: getSubTitleForSport({
          effectiveSince: insurance.effectiveSince,
          endsAt: insurance.endsAt,
          status: insurance.status,
        }),
      },
    },
    subscriptionTerms: [
      {
        label: i18n.t('INSURANCE_DETAIL:terms.coverageAmountPerPerson'),
        value: formattingNumberByRu(insurance.insuranceSumPerObject),
      },
      { ...getTermInsurer(insurance?.insuredPersons || []) },
      { ...getWordListing(insurance?.sports || []) },
      { ...getTermRisks(insurance.insuranceCoverages) },
      { ...getTermPolicyValidityPeriod(insurance) },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.policyNumber'),
        value: insurance.contractNumber,
        type: Info.WITH_DOWNLOAD,
      },
    ],
    documents: [...insurance.documents],
    showMessageButton: true,
    policyId: getPolicyId(insurance.documents),
    buttonsOfAction: {
      hasReported: {
        open: [
          InsuranceStatus.ACTIVE,
          InsuranceStatus.FINISHED,
          InsuranceStatus.TERMINATED,
        ].includes(insurance.status),
      },
      hasCanceled: {
        open: [
          InsuranceStatus.ACTIVE,
          InsuranceStatus.NEW_NOT_ACTIVE_YET,
        ].includes(insurance.status),
        text: i18n.t('INSURANCE_DETAIL:title.terminateTheContract'),
        modal: ModalEnums.TERMINATE_THE_CONTRACT,
      },
    },
  };
};
