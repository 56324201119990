import type { InsuredPerson } from '@shared/types';
import type { SubscriptionTerm } from '../types';
import { Info, ModalEnums } from '@shared/const';
import i18n from '@app/i18n/config';
import { formattingDateByRu } from '@shared/utils';
import { declineWord } from '@shared/utils';

const declensions = {
  one: i18n.t('INSURANCE_DETAIL:declensions.person-1'),
  few: i18n.t('INSURANCE_DETAIL:declensions.person-2'),
  many: i18n.t('INSURANCE_DETAIL:declensions.person-3'),
};

export const getTermInsurer = (
  insuredPersons: InsuredPerson[],
): SubscriptionTerm => {
  if (insuredPersons.length === 1) {
    const { firstName, lastName, middleName, birthDate } = insuredPersons[0];
    return {
      label: i18n.t('INSURANCE_DETAIL:terms.insuredPerson'),
      value: `${lastName} ${firstName} ${middleName ?? ''}`,
      hint: formattingDateByRu(birthDate),
    };
  }

  if (insuredPersons.length > 1) {
    return {
      label: i18n.t('INSURANCE_DETAIL:terms.insured'),
      value: declineWord(insuredPersons.length, declensions),
      type: Info.WITH_MODAL,
      modalType: ModalEnums.INSURED_PERSON,
    };
  }

  return {
    label: '',
    value: '',
  };
};
