import { media, styled } from '@pulse-web-ui/theme';

export const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  padding: 20px 16px;
  width: 100%;

  ${media.desktop} {
    padding: 24px;
  }
`;
