import i18n from '@app/i18n/config';
import { formattingDateByRu } from '@shared/utils';
import { Trans } from 'react-i18next';

interface AlertTitleCancelledStatusProps {
  endsAt: string;
}

export const AlertTitleCancelledStatus = ({
  endsAt,
}: AlertTitleCancelledStatusProps) => (
  <Trans values={{ date: formattingDateByRu(endsAt, 'short') }}>
    {i18n.t(
      'INSURANCE_DETAIL:alert.title.subscriptionCanceledWithDate',
    )}
  </Trans>
);
