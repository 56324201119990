import { InsuranceStatus } from '@shared/types';
import { getInsuranceConditionsTravel } from './get-insurance-conditions-travel';
import { ModalEnums } from '@shared/const';
import { ProductName } from '@entities/product';
import i18n from '@app/i18n/config';
import {
  getPolicyId,
  STATUS_INSURANCE_SNIPPET,
} from '@entities/insurance-detail';
import type {
  GetSubscriptionInfoResponse,
  InsuranceDetail,
} from '@entities/insurance-detail';
import { HeaderSubTitleTravel } from '../../ui/header-sub-title-travel';
import type { HeaderSubTitleProps } from '../types';
import { getSubTitle } from '@entities/insurance-detail';

export const prepareDetailTravel = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  return {
    header: {
      title: insurance.product.name,
      subTitle: HeaderSubTitleTravel(insurance as HeaderSubTitleProps),
      productIcon: ProductName.TRAVEL,
      status: {
        status: STATUS_INSURANCE_SNIPPET[insurance.status],
        message: getSubTitle({
          effectiveSince: insurance.effectiveSince,
          endsAt: insurance.endsAt,
          status: insurance.status,
        }),
      },
    },
    showMessageButton: true,
    subscriptionTitle: i18n.t('INSURANCE_DETAIL:title.insuranceTerms'),
    subscriptionTerms: getInsuranceConditionsTravel(insurance),
    insuranceSum: insurance.insuranceSum,
    insuranceSubObjects: insurance.insuranceSubObjects,
    insuranceCoverages: insurance.insuranceCoverages,
    policyId: getPolicyId(insurance.documents),
    documents: [...insurance.documents],
    buttonsOfAction: {
      hasReported: {
        open: [InsuranceStatus.ACTIVE].includes(insurance.status),
        text: i18n.t('INSURANCE_DETAIL:button.getHelp'),
        modal: ModalEnums.REPORT_INSURED_EVENT_SHORT_VZR,
      },
    },
  };
};
