import { TabButton, TabsListWrapper } from './tab.styles';

export type TabsListProps = {
  value: number;
  onChange?: (index: number) => void;
  children: React.ReactNode[];
  className?: string;
};

export const TabsList = ({
  value,
  onChange,
  children,
  className,
}: TabsListProps) => {
  return (
    <TabsListWrapper className={className}>
      {children.map((child, index) => (
        <TabButton
          key={index}
          isActive={value === index}
          onClick={() => onChange && onChange(index)}
        >
          {child}
        </TabButton>
      ))}
    </TabsListWrapper>
  );
};
