import { formattingNumberByRu } from '@shared/utils';
import { CONTRACT_STATUS } from '../constants';
import type { Contract } from '../types';

export const getOldPrice = (contracts: Contract[]): string | undefined => {
  const activeContract: Contract | undefined = contracts.find(
    item => item.type === CONTRACT_STATUS.ACTIVE,
  );
  const templateContract: Contract | undefined = contracts.find(
    item => item.type === CONTRACT_STATUS.TEMPLATE,
  );

  if (activeContract && templateContract?.mustBeShown) {
    return formattingNumberByRu(activeContract.premiumAndDelta);
  }

  return undefined;
};
