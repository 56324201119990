import { useStores } from '@shared/lib/mobx';
import { useFetchLogout } from './use-fetch-logout';
import { PULSE_INSURE_URL } from '@shared/const';
import { AnalyticEvents, sendAnalyticEvent } from '@shared/lib/web-analytic';

export const useHandleFetchLogout = () => {
  const {
    GlobalStore: {
      authStore: { logout },
    },
  } = useStores();

  const onSuccess = () => {
    sendAnalyticEvent(AnalyticEvents.LK_EXIT);
    logout();
    const timeout = setTimeout(() => {
      window.location.href = PULSE_INSURE_URL;
      clearTimeout(timeout);
    }, 500);
  };

  const fetchResult = useFetchLogout({ onSuccess });

  return fetchResult;
};
