import { PETS_KIND_OBJECT } from '@shared/const';
import type { GetSubscriptionInfoResponse } from '../types';

export const getSubTitleForPet = (insurance: GetSubscriptionInfoResponse) => {
  const { firstKind, secondKind } = PETS_KIND_OBJECT;
  const firstPetsKind = [firstKind.cat, firstKind.dog];
  const secondPetsKind = [secondKind.ferret, secondKind.chinchilla];

  const isFirstKind = firstPetsKind.includes(insurance.insuredPet!.kind);
  const isSecondKind = secondPetsKind.includes(insurance.insuredPet!.kind);

  let currentPetKind;

  if (isFirstKind) {
    currentPetKind = insurance.insuredPet?.breed;
  }

  if (isSecondKind) {
    currentPetKind = insurance.insuredPet?.kindName;
  }

  const textString = `
    ${currentPetKind} · 
    ${insurance.insuredPet?.gender} · 
    ${insurance.insuredPet?.age}
  `;

  return textString;
};