import i18n from '@app/i18n/config';
import { DAYS_TO_REMOVE } from '@shared/const';
import { ExternalLink } from '@shared/ui';
import { formattingDateByRu, openMobileAppLink } from '@shared/utils';
import { subDays } from 'date-fns';
import { Trans } from 'react-i18next';

export const AlertMessageSuspendedStatus = ({ endsAt }: { endsAt: string }) => (
  <Trans
    components={{
      button: <ExternalLink onClick={openMobileAppLink} />,
    }}
  >
    {i18n.t(
      'INSURANCE_DETAIL:alert.message.protectionValidUntilWithDownloadAppLink',
      {
        date1: formattingDateByRu(endsAt, 'short'),
        date2: formattingDateByRu(
          subDays(new Date(endsAt), DAYS_TO_REMOVE).toISOString(),
          'short',
        ),
      },
    )}
  </Trans>
);