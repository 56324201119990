import type { ModalEnums } from '@shared/const';
import { useEffect, useRef, useState } from 'react';

export const useResizeModal = (modal: ModalEnums | null) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [modalHeight, setModalHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(Boolean(modal));

    return () => {
      setModalHeight(0);
      setContentHeight(0);
    };
  }, [modal]);

  useEffect(() => {
    if (show && modalRef.current) {
      const screenHeight = window.innerHeight;
      const modalHeight = modalRef.current.clientHeight;
      if (modalHeight > screenHeight) {
        setModalHeight(Math.floor(screenHeight));
      }
    }
  }, [show, modal, modalHeight]);

  useEffect(() => {
    if (modalHeight > 0 && contentRef.current && modalRef.current) {
      const positionTop = contentRef.current.getBoundingClientRect().top;
      const paddingBottom = window.getComputedStyle(
        modalRef.current,
      ).paddingBottom;

      const contentHeight =
        modalHeight - positionTop - parseFloat(paddingBottom);
      setContentHeight(Math.floor(contentHeight));
    }
  }, [modalHeight]);

  return {
    modal: { ref: modalRef, height: modalHeight },
    modalContent: { ref: contentRef, height: contentHeight },
  };
};
