export enum ProductCode {
  HOUSE = '08.16', // Дом
  APARTMENT = '08.15', // Квартира
  PET = '00.04', // Питомец
  MITE = '00.05', // Антиклещ
  MITE_SUBSCRIBE = '00.09', // Антиклещ (подписочный)
  TRAVEL = '03.08', // Путешествие
  SPORT = '02.13', // Спорт
  MOTION = '02.09', // Движение
  MORTGAGE = '08.33', // Ипотека
  TELEVET = '00.10',
}

export enum PartnerProductCode {
  BIKE_PARTNER_1 = '00.01',
  SPORT_PARTNER_1 = '02.14',
  SPORT_PARTNER_2 = '02.15',
  MOTION_PARTNER_1 = '00.03',
  MOTION_PARTNER_2 = '02.18',
  MITE_PARTNER_1 = '00.02',
  MITE_PARTNER_2 = 'IFT00.02',
  HOUSE_PARTNER_1 = '08.21',
  HOUSE_PARTNER_2 = '08.27',
  CAMP_PARTNER_1 = '02.10',
  APARTMENT_PARTNER_1 = '08.17',
  APARTMENT_PARTNER_2 = '08.19',
  APARTMENT_PARTNER_3 = '08.20',
  APARTMENT_PARTNER_4 = '08.24',
  APARTMENT_PARTNER_5 = '08.25',
  APARTMENT_PARTNER_6 = '08.28',
  APARTMENT_PARTNER_7 = '08.31',
  APARTMENT_PARTNER_8 = '08.34',
  APARTMENT_PARTNER_9 = '08.35',
  APARTMENT_PARTNER_10 = '24.01',
}
