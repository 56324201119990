import { useStores } from '@shared/lib/mobx';
import { StyledModalBox } from '@shared/ui/modal-box';
import { InsuranceTextWrapper } from '../common';

export const MedAndIncidentExpensesModal = () => {
  const {
    GlobalStore: {
      insuranceCoverageStore: { insuranceCoverages },
    },
  } = useStores();

  const [{ options }] = insuranceCoverages;

  return options?.map(({ code, description, name }) => (
    <StyledModalBox key={code}>
      <InsuranceTextWrapper name={name} description={description} />
    </StyledModalBox>
  ));
};
