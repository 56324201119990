import { styled, typography } from '@pulse-web-ui/theme';

export const Header = styled.div`
  ${typography.medium16}
  line-height: 24px;
`;

export const IconWrapper = styled.div`
  width: 24px;
`;

export const Message = styled.div`
  ${typography.regular16}
  line-height: 24px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Wrapper = styled.div<{
  bgColor?: string;
}>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ?? theme.colors.surface.background};
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  display: flex;
  column-gap: 8px;
  padding: 12px;
`;
