import { useStores } from '@shared/lib/mobx';
import { ProtectedRoutes, protectedRoutePath } from '@shared/lib/react-router';
import { getGreeting } from '@shared/utils';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import type { VariantBackButton as VariantBackButtonType } from '../constants';
import { VariantBackButton } from '../constants';
import { REG_EXP_ROUTE_ID } from '@shared/const';

type TitleConfig = Partial<{
  title: string;
  showButtonChat: boolean;
  handleGoToBack: () => void;
  variantBackButton: VariantBackButtonType;
  isLoading: boolean;
}>;

export const useTitle = () => {
  const [title, setTitle] = useState<TitleConfig>({});
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const match = useMatch(protectedRoutePath[ProtectedRoutes.PRODUCT]);
  const navigate = useNavigate();
  const {
    GlobalStore: {
      titleStore: { title: titleFromStore },
      userStore: { profile, user },
    },
  } = useStores();

  const firstName = profile?.profile.firstName || user?.name || '';

  const handleGoToBack = useCallback(() => {
    navigate(state);
  }, [navigate]);

  useEffect(() => {
    if (pathname === protectedRoutePath[ProtectedRoutes.MAIN]) {
      const formatGreeting = getGreeting();
      let config: TitleConfig = {};

      if (!firstName) {
        config = { isLoading: true, title: undefined, showButtonChat: true };
      } else {
        config = {
          title: `${formatGreeting}, ${firstName}!`,
          showButtonChat: true,
          isLoading: false,
        };
      }

      return setTitle(config);
    }

    if (pathname === protectedRoutePath[ProtectedRoutes.BUY_INSURANCE]) {
      return setTitle({
        title: t('COMMON:insurance.buyInsurance'),
        showButtonChat: true,
      });
    }

    if (match) {
      return setTitle({
        title: titleFromStore,
        variantBackButton: VariantBackButton.ICON,
        handleGoToBack,
      });
    }

    if (pathname === protectedRoutePath[ProtectedRoutes.ALL_INSURANCE]) {
      return setTitle({
        title: t('INSURANCE:header.myInsurance'),
        variantBackButton: VariantBackButton.ICON,
        showButtonChat: true,
      });
    }

    if (
      pathname.replace(REG_EXP_ROUTE_ID, ':id') ===
        protectedRoutePath[ProtectedRoutes.PREV_INSURANCE_DETAIL] ||
      pathname.replace(REG_EXP_ROUTE_ID, ':id') ===
        protectedRoutePath[ProtectedRoutes.INSURANCE_DETAIL] ||
      pathname.replace(REG_EXP_ROUTE_ID, ':id') ===
        protectedRoutePath[ProtectedRoutes.PREV_INSURANCES] ||
      pathname.replace(REG_EXP_ROUTE_ID, ':id') ===
        protectedRoutePath[ProtectedRoutes.CONTRACT_DETAIL]
    ) {
      return setTitle({
        variantBackButton: VariantBackButton.TEXT,
        showButtonChat: true,
      });
    }
  }, [firstName, match, pathname, t, titleFromStore, navigate, handleGoToBack]);

  return title;
};
