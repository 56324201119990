import { useStores } from '@shared/lib/mobx';
import { Title } from './insured-persons.styles';
import { formattingDateByRu } from '@shared/utils';
import { TitleInfoDetail } from '../../shared';
import { StyledModalBox } from '@shared/ui/modal-box';

export const InsuredPersonsModal = () => {
  const {
    GlobalStore: {
      insuredPersonStore: { insuredPersons },
    },
  } = useStores();

  return insuredPersons.map(
    ({ lastName, firstName, middleName, birthDate }, key) => (
      <StyledModalBox key={key}>
        <Title>{`${lastName} ${firstName} ${middleName ?? ''}`}</Title>
        <TitleInfoDetail>{formattingDateByRu(birthDate)}</TitleInfoDetail>
      </StyledModalBox>
    ),
  );
};
