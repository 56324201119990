import { media, styled, typography } from '@pulse-web-ui/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  ${media.desktop} {
    padding: 8px 24px 8px 16px;
    margin: -8px -16px -8px -16px;
  }
`;

export const InfoStaticLine = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 20px;
`;
