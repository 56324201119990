import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { ProductName } from '@entities/product';
import { STATUS_INSURANCE_SNIPPET } from '../constants';
import { getSubTitle } from './get-sub-title';
import { ShortMiteMessage } from '@entities/insurance-detail';
import { getInsuranceStatuses } from './get-insurance-statuses';

export const prepareDetailMite = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  const { isRevoked, isNewNotActiveYet } = getInsuranceStatuses(
    insurance.status,
  );

  const isOtherBiteMessageShown = Boolean(
    insurance.antimiteDetails?.insuranceSumNS,
  );

  return {
    header: {
      title: insurance.product.name,
      productIcon: ProductName.MITE,
      status: {
        status: STATUS_INSURANCE_SNIPPET[insurance.status],
        message: getSubTitle({
          effectiveSince: insurance.effectiveSince,
          endsAt: insurance.endsAt,
          status: insurance.status,
        }),
      },
    },
    documents: insurance.documents,
    shortInfoBlockComponent: !isRevoked && !isNewNotActiveYet && (
      <ShortMiteMessage showOtherBiteMessage={isOtherBiteMessageShown} />
    ),
    showAlertInfo: true,
    showMessageButton: false,
  };
};
