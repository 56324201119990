import { styled, media } from '@pulse-web-ui/theme';

export const ButtonStyled = styled.button`
  font-family: 'Roboto Flex', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  color: ${({ theme }) => theme.colors.text.secondary};

  ${media.desktop} {
    justify-content: flex-start;
    flex-direction: row;
    font-weight: 600;
    padding: 0 12px;
    column-gap: 8px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
