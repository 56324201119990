import type { FC, ReactNode } from 'react';

import { LabelStyled } from './label.styles';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Label: FC<Props> = ({ children, className }) => {
  return <LabelStyled className={className}>{children}</LabelStyled>;
};
