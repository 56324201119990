import i18n from '@app/i18n/config';
import { formattingDateByRu } from '@shared/utils';
import { Trans } from 'react-i18next';

interface AlertMessageForMotionProductProps {
  endsAt: string;
}

export const AlertMessageForMotionProduct = ({
  endsAt,
}: AlertMessageForMotionProductProps) => (
  <Trans values={{ date: formattingDateByRu(endsAt, 'short') }}>
    {i18n.t('INSURANCE_DETAIL:alert.message.protectionValidUntil')}
  </Trans>
);
