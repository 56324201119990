import {
  StyledDetailHeaderSubTitle,
  StyledDetailSubTitle,
} from './short-morgage.styles';
import { convertPerson } from '@shared/utils';
import type { InsuredPerson } from '@shared/types';

interface HeaderSubTitleProps {
  address?: string;
  insuredPersons?: InsuredPerson[];
}

export const HeaderMorgageSubTitle = ({
  address,
  insuredPersons,
}: HeaderSubTitleProps) => {
  const hasInsuredPersons = Boolean(insuredPersons?.length);
  const dot = hasInsuredPersons && `·`;
  return (
    <StyledDetailHeaderSubTitle>
      {address && (
        <StyledDetailSubTitle hasInsuredPersons={hasInsuredPersons}>
          {address} {dot}
        </StyledDetailSubTitle>
      )}
      {hasInsuredPersons && <div> {convertPerson(insuredPersons, true)}</div>}
    </StyledDetailHeaderSubTitle>
  );
};
