import { styled, typography } from '@pulse-web-ui/theme';
import { MainButton } from '@shared/ui';

export const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  ${typography.bold24}
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 24px;
  margin-bottom: 24px;
`;

export const MainButtonStyled = styled(MainButton)`
  width: 147px;
`;

export const IconWrapper = styled.div`
  margin-bottom: 4px;
`;
