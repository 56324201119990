import { makeAutoObservable } from 'mobx';

export class ErrorBoundaryStore {
  hasError: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setHasError = (e: boolean) => {
    this.hasError = e;
  };
}
