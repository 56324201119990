import { styled, media, css } from '@pulse-web-ui/theme';

export const TitleStyled = styled.span<{
  textAlign?: string;
}>`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${media.desktop} {
    font-size: 24px;
    line-height: 32px;
  }
`;
