import { setAuthCsrfToken } from '@entities/auth';
import { addHeaderAuth } from './add-header-auth';

export const addTokens = ({
  authToken,
  csrfToken,
}: {
  authToken: string;
  csrfToken?: string;
}) => {
  addHeaderAuth(authToken);
  setAuthCsrfToken(csrfToken);
};
