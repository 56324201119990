import type { FC, ReactNode } from 'react';

import { HeaderStyled } from './header.styles';

type Props = {
  children: ReactNode;
};

export const Header: FC<Props> = ({ children }) => {
  return <HeaderStyled>{children}</HeaderStyled>;
};
