import { CURRENCY } from '@shared/const';

export const formattingNumberByRu = (n?: number, options?: Intl.NumberFormatOptions ) => {
  const ruPrice = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: CURRENCY.RUB,
    trailingZeroDisplay: 'stripIfInteger', 
    ...options
  } as Intl.NumberFormatOptions);

  if (!n) {
    return ruPrice.format(0);
  }

  return ruPrice.format(n);
};
