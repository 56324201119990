import { useTranslation } from 'react-i18next';
import { ButtonsWrapperStyled } from './buttons-wrapper.styles';
import { Report } from '../report';
import { Cancel } from '../cancel';
import { useStores } from '@shared/lib/mobx';
import { ModalEnums } from '@shared/const';
import type { ButtonsOfAction } from '@entities/insurance-detail/model/types';
import {
  sendAnalyticEventCase,
  AnalyticEventCases,
  useParamsForAnalytic,
} from '@shared/lib/web-analytic';
import { observer } from 'mobx-react-lite';

interface ButtonsWrapperProps {
  buttonsOfAction?: ButtonsOfAction;
}

export const ButtonsWrapper = observer(
  ({ buttonsOfAction }: ButtonsWrapperProps) => {
    const { t } = useTranslation();
    const {
      GlobalStore: {
        modalStore: { setModal },
      },
    } = useStores();

    const params = useParamsForAnalytic();

    const openModal = (key: ModalEnums) => setModal({ modal: key });

    const handleClickReportButton = () => {
      openModal(
        buttonsOfAction?.hasReported?.modal || ModalEnums.REPORT_INSURED_EVENT,
      );
      sendAnalyticEventCase(AnalyticEventCases.INSURANCE_CASE, params);
    };

    const handleClickCancelButton = () => {
      openModal(
        buttonsOfAction?.hasReported?.modal || ModalEnums.CANCEL_SUBSCRIPTION,
      );
      sendAnalyticEventCase(AnalyticEventCases.CANCEL, params);
    };

    return (
      <ButtonsWrapperStyled>
        {buttonsOfAction?.hasReported?.open && (
          <Report onClick={handleClickReportButton}>
            {buttonsOfAction?.hasReported?.text ||
              t('INSURANCE_DETAIL:button.reportInsuredEvent')}
          </Report>
        )}
        {buttonsOfAction?.hasCanceled?.open && (
          <Cancel onClick={handleClickCancelButton}>
            {buttonsOfAction?.hasCanceled?.text ||
              t('INSURANCE_DETAIL:button.cancelSubscription')}
          </Cancel>
        )}
      </ButtonsWrapperStyled>
    );
  },
);
