import { styled, typography } from '@pulse-web-ui/theme';

export const ContractAboutPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin: 12px 0px 0px 0px;
`;

export const ContractAboutPolicyItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ContractAboutPolicyTitle = styled.h3`
  ${typography.medium16}
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;
`;

export const ContractAboutPolicyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ContractAboutPolicyListItem = styled.h4`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;
`;

export const ContractAboutPolicyItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BulletWrapper = styled.div`
  flex: 0 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px 0px 0px;
`;

export const Bullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.text.primary};
`;
