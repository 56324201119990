import {
  InsuranceCoveragesContent,
  InsuranceCoveragesImg,
  InsuranceCoveragesWrapper,
} from './insurance-coverages-modal.styles';
import {
  Tooltip,
  TooltipDirection,
  TriggerDirection,
  TooltipBgColor,
} from '@pulse-web-ui/tooltip';
import { useStores } from '@shared/lib/mobx';
import { InsuranceTextWrapper } from '../common';

export const InsuranceCoveragesModal = () => {
  const {
    GlobalStore: {
      insuranceCoverageStore: { insuranceCoverages },
    },
  } = useStores();

  return (
    <InsuranceCoveragesWrapper>
      {insuranceCoverages.map(insuranceCoverage => {
        const replacedUrlImage = insuranceCoverage.pictureUri
          ?.replace('pics-mw', 'pics-web-lk')
          .replace('png', 'svg');
        return (
          <InsuranceCoveragesContent key={insuranceCoverage.code}>
            <InsuranceCoveragesImg src={replacedUrlImage} />
            <InsuranceTextWrapper
              name={insuranceCoverage.name}
              description={insuranceCoverage.description}
            />
            <Tooltip
              arrow
              trigger={TriggerDirection.HOVER}
              direction={TooltipDirection.UP}
              tooltipText={insuranceCoverage.extendedDescription}
              tooltipBgColor={TooltipBgColor.BLUE}
            />
          </InsuranceCoveragesContent>
        );
      })}
    </InsuranceCoveragesWrapper>
  );
};
