import { PromoHeader } from '@entities/mobile-app';
import { styled, media } from '@pulse-web-ui/theme';

const MENU_HEIGHT = 64;

export const MainStyled = styled.main`
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  ${media.desktop} {
    flex-direction: row;
  }
`;

export const ContentStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0 ${MENU_HEIGHT + 24}px 0;

  ${media.desktop} {
    padding: 40px 24px 40px 32px;
    width: 100%;
  }
`;

export const MaxContentContainer = styled.div`
  max-width: 856px;
  width: 100%;
`;

export const FooterStyled = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  flex: 0 0 auto;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.neutral[0]};
`;

export const StickyPromoHeader = styled(PromoHeader)`
  position: sticky;
  top: 0;
  z-index: 1;
`;
