import { media, styled } from '@pulse-web-ui/theme';

export const DownloadWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: flex-end;

  ${media.desktop} {
    align-items: center;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  text-align: left;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
