import { media, styled, typography } from '@pulse-web-ui/theme';

export const Title = styled.h3`
  ${typography.medium16};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 26px;
  margin-bottom: 2px;

  ${media.desktop} {
    ${typography.medium18};
  }
`;
