import type {
  PrevInsuranceDetail,
  PrevInsuranceResponse,
} from '@entities/previous-insurance/model/types';
import { formattingDateByRu, formattingNumberByRu } from '@shared/utils';
import i18n from '@app/i18n/config';
import { Info, ModalEnums } from '@shared/const';
import {
  PREV_INSURANCE_COLOR_STATUS,
  PREV_INSURANCE_MESSAGE_STATUS,
} from '../constants';
import { Risks } from '@shared/ui';

export const preparePrevInsuranceDetailLongVersion = (
  insurance: PrevInsuranceResponse,
): PrevInsuranceDetail => {
  return {
    productCode: insurance.product.code,
    contractNumber: insurance.contractNumber,
    status: {
      message: PREV_INSURANCE_MESSAGE_STATUS[insurance.status],
      status: PREV_INSURANCE_COLOR_STATUS[insurance.status],
    },
    subscriptionTerms: [
      {
        label: i18n.t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
        value: `${formattingDateByRu(insurance.effectiveSince)} - ${formattingDateByRu(insurance.endsAt)}`,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.price'),
        value: formattingNumberByRu(Number(insurance.premiumAndDelta)),
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.totalSumInsured'),
        value: formattingNumberByRu(insurance.insuranceSum),
        type: Info.WITH_MODAL,
        modalType: ModalEnums.TOTAL_SUM_INSURED,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.insuranceRisks'),
        value: Risks({ risks: insurance.insuranceCoverages }),
        type: Info.WITH_MODAL,
        modalType: ModalEnums.INSURANCE_RISKS,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.propertyAddress'),
        value: insurance?.address || '',
      },
    ],
    documents: insurance.documents,
    insuranceSum: insurance.insuranceSum,
    insuranceCoverages: insurance.insuranceCoverages,
    insuranceSubObjects: insurance.insuranceSubObjects,
    buttonsOfAction: {
      hasReported: { open: true },
    },
  };
};
