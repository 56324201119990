import { breakpoints } from '@pulse-web-ui/theme';
import { useLayoutEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        if (width <= breakpoints.DESKTOP) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      }
    });

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.unobserve(document.documentElement);
    };
  }, []);

  return isMobile;
};
