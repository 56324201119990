import { Trans, useTranslation } from 'react-i18next';
import {
  BoxStyled,
  StyledInsuranceSubTitle,
  TitleDetail,
} from '../shared';
import { openMobileAppLink } from '@shared/utils';
import { ExternalLink } from '@shared/ui';

export const ShortMotionMessage = () => {
  const { t } = useTranslation();
  return (
    <BoxStyled>
      <TitleDetail>{t('INSURANCE_DETAIL:text:getHelpFromMotion')}</TitleDetail>
      <StyledInsuranceSubTitle>
        <Trans
          components={{
            a: <ExternalLink />,
            button: (
              <ExternalLink
                onClick={openMobileAppLink}
              />
            ),
          }}
        >
          {t('INSURANCE_DETAIL:text:helpAfterMotion')}
        </Trans>
      </StyledInsuranceSubTitle>
    </BoxStyled>
  );
};