export const USER_PERSONAL_DATA_AGREEMENTS = `${window.envUrls.STORAGE_URL}/consents/pd_agreement.pdf`;
export const INSURANCE_POLICIES = `${window.envUrls.STORAGE_URL}/consents/Soglasie_na_bezakceptnoe_spisanie.pdf`;
export const USER_AGREEMENTS_URL = `${window.envUrls.STORAGE_URL}/consents/Polzovatelskie_soglashenia_ob_ispolzovanii_mobilnogo_prilozhenia_i_saita_OOO_SK_Pulse.pdf`;
export const USER_RECL_AGREEMENTS_URL = `${window.envUrls.STORAGE_URL}/consents/Soglasie_na_poluchenie_reklamno_informacionnih_soobschenii.pdf`;
export const APP_LINK =
  'https://apps.apple.com/ru/app/%D0%BF%D1%83%D0%BB%D1%8C%D1%81-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8/id1609383096';
export const GOOGLE_LINK =
  'https://play.google.com/store/apps/details?id=ru.pulse.insure&hl=ru&gl=US&pli=1';
export const PULSE_INSURE_URL = 'https://pulse.insure/main/';
export const PULSE_INSURE_APPS_URL = 'https://pulse.insure/apps/';
