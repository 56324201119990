import { ProductCode } from '@entities/product';
import { preparePrevInsuranceDetailLongVersion } from './prepare-prev-insurance-detail-long-version';
import { preparePrevInsuranceDetailShortVersion } from './prepare-prev-insurance-detail-short-version';

import type { PrevInsuranceDetail, PrevInsuranceResponse } from '../types';

export const preparePrevInsuranceDetail = (
  insurance?: PrevInsuranceResponse,
): PrevInsuranceDetail | null => {
  if (!insurance) return null;
  const productCode = insurance.product.code;

  if (
    productCode === ProductCode.APARTMENT ||
    productCode === ProductCode.HOUSE
  ) {
    return preparePrevInsuranceDetailLongVersion(insurance);
  }

  return preparePrevInsuranceDetailShortVersion(insurance);
};
