import { usePostMutation } from '@shared/api';
import { Endpoints } from '@shared/const';
import {
  convertBase64ToBlob,
  downloadBlob,
  isAppleSafari,
  openPdf,
} from '@shared/utils';
import { useCallback } from 'react';

export const useDownloadFile = ({
  documentId,
  documentName,
}: {
  documentId: string;
  documentName?: string;
}) => {
  const isIOS = isAppleSafari();

  const onSuccess = (data: string) => {
    const base64Data = data;
    if (!isIOS) {
      openPdf({ base64Pdf: base64Data });
    } else {
      const { blob, fileName } = convertBase64ToBlob({
        base64: base64Data,
        nameDocument: documentName || documentId,
      });

      downloadBlob(blob, fileName);
    }
  };

  const { mutateAsync, isPending } = usePostMutation<
    Record<string, never>,
    string
  >({
    url: Endpoints.FILE + documentId,
    options: {
      onSuccess,
    },
    config: {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/pdf',
      },
    },
  });

  const handleClick = useCallback(() => {
    mutateAsync({});
  }, [mutateAsync]);

  return { handleClick, isPending };
};
