import { makeAutoObservable } from 'mobx';

export class TitleStore {
  title: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setTitle = (e: string) => {
    this.title = e;
  };
}
