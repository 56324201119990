import { usePostMutation } from '@shared/api';
import { Endpoints } from '@shared/const';

import type { ErrorResponse } from '@shared/api';
import type { RefreshTokenRequest, RefreshTokenResponse } from '../types';
import type { MutationOptions } from '@tanstack/react-query';
import { getAuthCsrfToken } from '@entities/auth';

export const useFetchRefreshToken = (
  options: MutationOptions<
    RefreshTokenResponse,
    ErrorResponse,
    RefreshTokenRequest
  >,
) => {
  const csrfToken = getAuthCsrfToken();

  const fetchResult = usePostMutation<
    RefreshTokenRequest,
    RefreshTokenResponse
  >({
    url: Endpoints.REFRESH_TOKEN,
    options,
    config: {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    },
  });

  return fetchResult;
};
