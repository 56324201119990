import i18n from '@app/i18n/config';

export const getGreeting = () => {
  const hour = new Date().getHours();
  let greeting;

  if (hour >= 5 && hour < 12) {
    greeting = i18n.t('COMMON:greeting:goodMorning');
  } else if (hour >= 12 && hour < 18) {
    greeting = i18n.t('COMMON:greeting:goodAfternoon');
  } else if (hour >= 18 && hour < 24)
    greeting = i18n.t('COMMON:greeting:goodEvening');
  else if (hour >= 0 && hour < 5) {
    greeting = i18n.t('COMMON:greeting:goodNight');
  }

  return greeting;
};
