import { useTranslation } from 'react-i18next';

import AttentionIcon from '@shared/assets/icons/attention.svg?react';

import {
  Box,
  IconWrapper,
  MainButtonStyled,
  SubTitle,
  Title,
  Wrapper,
} from './main-error.styles';

import type { FC } from 'react';

type Props = {
  isLoading: boolean;
  onUpdate: () => void;
};

export const MainError: FC<Props> = ({ isLoading, onUpdate }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Wrapper>
        <IconWrapper>
          <AttentionIcon />
        </IconWrapper>
        <Title>{t('ERROR:error.somethingWentWrong')}</Title>
        <SubTitle>{t('ERROR:error.tryUpdatePage')}</SubTitle>
        <MainButtonStyled isLoading={isLoading} onClick={onUpdate}>
          {t('ERROR:error.update')}
        </MainButtonStyled>
      </Wrapper>
    </Box>
  );
};
