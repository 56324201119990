import { phoneRegExpRemoveValueCasting } from '@shared/regexps';
import type { AuthResponse } from '../types';
import { useNavigate } from 'react-router-dom';

import { useFetchAuth } from './use-fetch-auth';
import { PublicRoutes, publicRoutePath } from '@shared/lib/react-router';

import type { SubmitHandler, UseFormSetError } from 'react-hook-form';
import type { AuthRequest, PhoneValidateType } from '../types';
import type { ErrorResponse } from '@shared/api';
import { useStores } from '@shared/lib/mobx';
import { useState } from 'react';
import { useHandleAuthErrors } from './handle-auth-errors';
import { AnalyticEvents, sendAnalyticEvent } from '@shared/lib/web-analytic';

export const useFormAuth = (setError?: UseFormSetError<PhoneValidateType>) => {
  const {
    GlobalStore: {
      authStore: {
        setPhoneNumber,
        setAuthDisabled,
        setConfirmationId,
        isDisabledAuth,
      },
    },
  } = useStores();
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');

  const handleAuthError = useHandleAuthErrors(
    setError as UseFormSetError<PhoneValidateType>,
  );

  const onError = (e: ErrorResponse) => {
    const errorStatus = e.response?.status;
    const unlockingTime = e.response?.data.unlockingTime;
    const errorCode = e.response?.data?.code;
    handleAuthError(errorCode, errorStatus, unlockingTime);
  };

  const onSuccess = (data: AuthResponse) => {
    setConfirmationId(data.confirmationId);
    setPhoneNumber(phone);
    isDisabledAuth && setAuthDisabled(false);
    navigate(publicRoutePath[PublicRoutes.OTP]);
  };

  const { mutateAsync, isPending } = useFetchAuth({
    onSuccess,
    onError,
  });

  const onSubmit: SubmitHandler<AuthRequest['authenticationUser']> = data => {
    sendAnalyticEvent(AnalyticEvents.USER_AUTH_TOSTEP_OTP);
    const params = {
      authenticationUser: {
        phoneNumber: phoneRegExpRemoveValueCasting(data.phoneNumber),
      },
    };
    mutateAsync(params);
    setPhone(data.phoneNumber);
  };

  return { onSubmit, isPending };
};
