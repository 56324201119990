import { InsuranceStatus } from '@shared/types';
import { formattingDateByRu } from '@shared/utils';
import i18n from '@app/i18n/config';

type Args = {
  endsAt: string;
  effectiveSince: string;
  status: InsuranceStatus;
};

export const getSubTitleForSport = ({
  effectiveSince,
  endsAt,
  status,
}: Args): string => {
  switch (status) {
    case InsuranceStatus.ACTIVE:
    case InsuranceStatus.AWAITING_APPROVAL:
    case InsuranceStatus.SUSPENDED:
    case InsuranceStatus.CANCELLED:
    case InsuranceStatus.PAYMENT_STOPPED:
    case InsuranceStatus.PAYMENT_FAILED:
      return i18n.t('INSURANCE_DETAIL:actionStatusByDate.until', {
        date: formattingDateByRu(endsAt),
      });

    case InsuranceStatus.NEW_NOT_ACTIVE_YET:
    case InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET:
      return i18n.t('INSURANCE_DETAIL:actionStatusByDate.willTake', {
        date: formattingDateByRu(effectiveSince),
      });

    case InsuranceStatus.FINISHED:
      return i18n.t('INSURANCE_DETAIL:actionStatusByDate.ended', {
        date: formattingDateByRu(endsAt),
      });

    case InsuranceStatus.REVOKED:
    case InsuranceStatus.TERMINATED:
      return i18n.t('INSURANCE_DETAIL:actionStatusByDate.terminate', {
        date: formattingDateByRu(endsAt),
      });

    default:
      return '';
  }
};
