export enum Endpoints {
  AUTHENTICATION = '/v1/user/authentication',
  CONFIRMAUTH = '/v3/user/confirm-authentication',
  GET_PROFILE = '/v1/user/get-profile',
  REG = '/v1/user/registration',
  DADATA = '/v3/dadata/suggest',
  BUSINESS_LINES = '/v6/references/business-lines',
  REFRESH_TOKEN = '/v1/user/refresh-token',
  LOGOUT = '/v1/user/logout',
  GET_SUB_BY_USER = '/v4/subscription/get-subscriptions-by-user',
  CONTRACTS = '/v1/insurance-contracts/1c-contracts',
  GET_SUBSCRIPTION_INFO = '/v2/subscription/get-subscription-info',
  FIND_BY_ID = '/v1/insurance-contracts/1c-contracts/find-by-id',
  PREV_INSURANCE = '/v1/insurance-contracts/get-info',
  FILE = '/v1/file/',
}
