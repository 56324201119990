import type { FC, PropsWithChildren } from 'react';
import { TextWrapper, TextWrapperTitle } from './insurance-text-wrapper.styles';
import { SubTitle } from '@shared/ui';

type Props = {
  name?: string;
  description?: string;
};

export const InsuranceTextWrapper: FC<PropsWithChildren<Props>> = ({
  description,
  name,
  children,
}) => {
  return (
    <TextWrapper>
      {name && <TextWrapperTitle>{name}</TextWrapperTitle>}
      {description && <SubTitle>{description}</SubTitle>}
      {children}
    </TextWrapper>
  );
};
