import { formattingNumberByRu } from '@shared/utils';
import type { ContractDetail, FindByIdResponse } from '../types';
import i18n from '@app/i18n/config';
import { Risks } from '@shared/ui';
import { getPolicyValidatePeriod } from '@entities/insurance-detail';
import { Info, ModalEnums } from '@shared/const';
import { getAboutPolicy } from './get-about-poilicy';
import { getProductNameByCode, checkIsPartnerProduct } from '@entities/product';

export const prepareContractDetail = (
  contract?: FindByIdResponse,
): ContractDetail | undefined => {
  if (!contract) return;

  const risks = contract.risks?.map(risk => ({ name: risk }));

  const common: ContractDetail = {
    header: {
      title: contract.name,
      subTitle: contract.insuranceObject,
      productIcon: getProductNameByCode(contract.code),
      isPartner: checkIsPartnerProduct(contract.code),
    },
    subscriptionTerms: [
      {
        label: i18n.t('INSURANCE_DETAIL:terms.coverageAmount'),
        value: `${formattingNumberByRu(Number(contract.insuranceSum))}`,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.insured'),
        value: contract.insuredPersons,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.insuranceRisks'),
        value: Risks({
          risks,
        }),
        type: Info.WITH_MODAL,
        modalType: ModalEnums.CONTRACT_INSURANCE_RISKS,
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
        value: getPolicyValidatePeriod(
          contract.effectiveSince,
          contract.endsAt,
        ),
      },
      {
        label: i18n.t('INSURANCE_DETAIL:terms.policyNumber'),
        value: contract.agreementNumber,
        type: Info.WITH_DOWNLOAD,
      },
      { ...(contract.coverage && getAboutPolicy()) },
    ],
    documents: contract.documents,
    buttonsOfAction: {
        hasReported: {
            open: true,
            modal: ModalEnums.REPORT_INSURED_EVENT
        }
    }
  };

  return common;
};
