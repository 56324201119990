import type { RouteProps } from 'react-router-dom';

import {
  AllInsurancePage,
  BuyInsurancePage,
  InsuranceDetailPage,
  MainPage,
  PrevInsuranceDetailPage,
  ChatPage,
  ProductPage,
  ContractDetailPage,
  PrevInsurancePage,
} from '@pages/index';

export enum ProtectedRoutes {
  MAIN = 'main',
  BUY_INSURANCE = 'buy-insurance',
  CHAT = 'CHAT',
  PRODUCT = 'product',
  ALL_INSURANCE = 'all-insurance',
  INSURANCE_DETAIL = 'insurance-detail',
  CONTRACT_DETAIL = 'contract-detail',
  PREV_INSURANCES = 'prev-insurances',
  PREV_INSURANCE_DETAIL = 'prev-insurance-detail',
}

export const protectedRoutePath: Record<ProtectedRoutes, string> = {
  [ProtectedRoutes.MAIN]: '/',
  [ProtectedRoutes.BUY_INSURANCE]: '/buy-insurance',
  [ProtectedRoutes.CHAT]: '/chat',
  [ProtectedRoutes.PRODUCT]: '/product/:id',
  [ProtectedRoutes.ALL_INSURANCE]: '/all-insurance',
  [ProtectedRoutes.INSURANCE_DETAIL]: '/insurance-detail/:id',
  [ProtectedRoutes.CONTRACT_DETAIL]: '/contract-detail/:id',
  [ProtectedRoutes.PREV_INSURANCES]: '/previous-insurances/:id',
  [ProtectedRoutes.PREV_INSURANCE_DETAIL]: '/previous-insurance-detail/:id',
};

export const protectedRouteConfig: Record<ProtectedRoutes, RouteProps> = {
  [ProtectedRoutes.MAIN]: {
    path: protectedRoutePath[ProtectedRoutes.MAIN],
    element: <MainPage />,
  },

  [ProtectedRoutes.BUY_INSURANCE]: {
    path: protectedRoutePath[ProtectedRoutes.BUY_INSURANCE],
    element: <BuyInsurancePage />,
  },
  [ProtectedRoutes.CHAT]: {
    path: protectedRoutePath[ProtectedRoutes.CHAT],
    element: <ChatPage />,
  },
  [ProtectedRoutes.PRODUCT]: {
    path: protectedRoutePath[ProtectedRoutes.PRODUCT],
    element: <ProductPage />,
  },
  [ProtectedRoutes.ALL_INSURANCE]: {
    path: protectedRoutePath[ProtectedRoutes.ALL_INSURANCE],
    element: <AllInsurancePage />,
  },
  [ProtectedRoutes.INSURANCE_DETAIL]: {
    path: protectedRoutePath[ProtectedRoutes.INSURANCE_DETAIL],
    element: <InsuranceDetailPage />,
  },
  [ProtectedRoutes.CONTRACT_DETAIL]: {
    path: protectedRoutePath[ProtectedRoutes.CONTRACT_DETAIL],
    element: <ContractDetailPage />,
  },
  [ProtectedRoutes.PREV_INSURANCES]: {
    path: protectedRoutePath[ProtectedRoutes.PREV_INSURANCES],
    element: <PrevInsurancePage />,
  },
  [ProtectedRoutes.PREV_INSURANCE_DETAIL]: {
    path: protectedRoutePath[ProtectedRoutes.PREV_INSURANCE_DETAIL],
    element: <PrevInsuranceDetailPage />,
  },
};
