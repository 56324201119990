import { styled, media } from '@pulse-web-ui/theme';

export const ProductSkeletonFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ProductSkeletonTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0px 0px 16px 0px;

  ${media.desktop} {
    gap: 24px;
    margin: 0px 0px 24px 0px;
  }
`;

export const ProductSkeletonTopWrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
