import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Input } from '@pulse-web-ui/input';
import { HelperText } from '@pulse-web-ui/helper-text';

import { zodResolver } from '@hookform/resolvers/zod';

import { useFormAuth } from '../model/hooks';
import { PhoneValidateSchema } from '../model/schema/auth.schema';

import { FormStyled } from './auth-form.styles';

import type { AuthRequest } from '../model/types';
import { useStores } from '@shared/lib/mobx';
import { phoneDisplayValueForm } from '@shared/utils';
import { MainButton, Submit } from '@shared/ui';

export const AuthForm = observer(() => {
  const { t } = useTranslation();
  const {
    GlobalStore: {
      authStore: { phoneNumber, isDisabledAuth },
    },
  } = useStores();

  const phoneValue = phoneDisplayValueForm(phoneNumber);

  const { control, handleSubmit, setError } = useForm<
    AuthRequest['authenticationUser']
  >({
    defaultValues: {
      phoneNumber: phoneValue,
    },
    resolver: zodResolver(PhoneValidateSchema),
  });

  const { isPending, onSubmit } = useFormAuth(setError);

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='phoneNumber'
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <HelperText
            noMargin
            status={error ? 'error' : 'default'}
            message={error?.message}
          >
            <Input
              type='tel'
              disabled={isDisabledAuth}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('COMMON:labels:phone')}
            />
          </HelperText>
        )}
      />
      <Submit type='submit' disabled={isPending}>
        <MainButton disabled={isPending} adaptiveWidth>
          {t('AUTH:button.submit')}
        </MainButton>
      </Submit>
    </FormStyled>
  );
});
