import { useStores } from '@shared/lib/mobx';
import type { ErrorResponse } from '@shared/api';
import { Codes } from '@shared/const';
import { QueryCache, QueryClient } from '@tanstack/react-query';

export const useClientQuery = () => {
  const {
    GlobalStore: {
      queryStore: { setQueryKey },
    },
  } = useStores();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (_, query) => {
        const error = query.state.error as ErrorResponse;
        if (error.response?.status === Codes.ClientErrorUnauthorized) {
          setQueryKey(query.queryKey[0] as string);
        }
      },
      onSuccess: () => {
        setQueryKey(null);
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return { queryClient };
};
