export const formattingDateByRu = (date: Date | string, type?: 'short') => {
  const currentDate = new Date(date);
  if (type === 'short') {
    return Intl.DateTimeFormat('ru-RU', {
      day: 'numeric',
      month: 'long',
    }).format(currentDate);
  }

  return Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
    .format(currentDate)
    .replace('г.', '');
};
