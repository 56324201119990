export enum InsuranceStatus {
  NEW_NOT_ACTIVE_YET = 'NEW_NOT_ACTIVE_YET',
  ACTIVE = 'ACTIVE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_STOPPED = 'PAYMENT_STOPPED',
  CANCELLED = 'CANCELLED',
  SUSPENDED = 'SUSPENDED',
  SUSPENDED_NOT_ACTIVE_YET = 'SUSPENDED_NOT_ACTIVE_YET',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  REVOKED = 'REVOKED',
  FINISHED = 'FINISHED',
  TERMINATED = 'TERMINATED',
}
