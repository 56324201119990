import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';

import { Home, Store } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import { useIsDesktop } from '@shared/hooks';
import { Exit } from '@features/exit';
import { ProtectedRoutes, protectedRoutePath } from '@shared/lib/react-router';
import { NavLink } from '@shared/ui';

import { NavStyled } from './menu.styles';

export const Menu = memo(() => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  const iconColor = isDesktop
    ? theme.colors.icon.primary
    : theme.colors.icon.quaternary;

  const ShowcaseLink = useMemo(
    () => (
      <NavLink
        to={protectedRoutePath[ProtectedRoutes.BUY_INSURANCE]}
        text={t('COMMON:insurance:showcase')}
        Icon={Store}
      />
    ),
    [isDesktop, t],
  );

  return (
    <NavStyled>
      <ul>
        <li>
          <NavLink
            to={protectedRoutePath[ProtectedRoutes.MAIN]}
            text={t('COMMON:navigation:main')}
            Icon={Home}
          />
        </li>
        <li>{ShowcaseLink}</li>
        <li>
          <Exit iconColor={iconColor} />
        </li>
      </ul>
    </NavStyled>
  );
});
