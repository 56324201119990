import type { User, ProfileResponse } from './types';
import { makeAutoObservable } from 'mobx';

export class UserStore {
  user: User | null = null;
  profile?: ProfileResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (user: User) => {
    this.user = { ...this.user, ...user };
  };

  setProfile = (profile: ProfileResponse) => {
    this.profile = profile;
  };
}
