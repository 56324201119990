import { LoadingStyled } from './loading.styles';

export const Loading = () => {
  return (
    <LoadingStyled
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 22C42 33.0457 33.0457 42 22 42C10.9543 42 2 33.0457 2 22C2 10.9543 10.9543 2 22 2'
        stroke='#1698D9'
        strokeWidth='3.75'
        strokeLinecap='round'
      />
    </LoadingStyled>
  );
};
