import { makeAutoObservable } from 'mobx';
import { getAuthPhone, setAuthPhone, setAuthCsrfToken } from './auth.storage';

export class AuthStore {
  confirmationId: string = '';

  phoneNumber: string | null = getAuthPhone();

  isUpdateRefreshToken: boolean = false;

  isDisabledAuth: boolean = false;

  newCodeActivated: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthDisabled = (isDisabled: boolean) => {
    this.isDisabledAuth = isDisabled;
  };

  updateRefreshToken = () => {
    this.isUpdateRefreshToken = !this.isUpdateRefreshToken;
  };

  setConfirmationId = (e: string) => {
    this.confirmationId = e;
  };

  setPhoneNumber = (e: string | null) => {
    setAuthPhone(e);
    this.phoneNumber = e;
  };

  setSendNewCodeActivated = (e: boolean) => {
    this.newCodeActivated = e;
  };

  clearTokens = () => {
    setAuthCsrfToken();
  };

  logout = () => {
    this.clearTokens();
    this.setPhoneNumber(null);
    localStorage.clear();
  };
}
