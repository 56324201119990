import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '@app/styles/reset.css';
import '@app/styles/index.css';

import { QueryProvider } from '@app/providers/query-provider';
import { AppThemeProvider } from '@app/providers/app-theme-provider/ui/app-theme-provider';
import { App } from './app';
import { WebAnalytic } from '@shared/lib/web-analytic';
import { Modal } from '@app/modal';

const container = document.getElementById('root');

if (!container) {
  throw new Error(
    'Контейнер root не найден. Не удалось вмонтировать реакт приложение',
  );
}

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <WebAnalytic />
    <QueryProvider>
      <AppThemeProvider>
        <Modal />
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </AppThemeProvider>
    </QueryProvider>
  </BrowserRouter>,
);
