export const convertBase64ToBlob = ({
  base64,
  nameDocument,
  typeDocument,
}: {
  base64: string;
  nameDocument: string;
  typeDocument?: string;
}) => {
  const typeDocumentDefault = typeDocument || 'pdf';

  const blob = new Blob([base64], {
    type: `application/${typeDocumentDefault}`,
  });

  const fileName = `${nameDocument}.${typeDocumentDefault}`;

  return { blob, fileName };
};
