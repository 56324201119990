import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { ProductName } from '@entities/product';
import { ShortMotionMessage } from '@entities/insurance-detail';
import { convertPerson } from '@shared/utils';
import { getAlertTexts } from './get-alert-texts';
import { getFooterBox } from './get-footer-box';
import { getInsuranceStatuses } from './get-insurance-statuses';
import { getDocumentsBlock } from './get-documents-block';

export const prepareDetailMotion = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  const { isRevoked, isFinished } = getInsuranceStatuses(insurance.status);
  const isNotRevokedAndIsNotFinished = !isRevoked && !isFinished;

  return {
    header: {
      title: insurance.product.name,
      subTitle: convertPerson(insurance.insuredPersons, true),
      productIcon: ProductName.MOTION,
    },
    alert: getAlertTexts(insurance),
    showAlertInfo: isNotRevokedAndIsNotFinished,
    documents: getDocumentsBlock(insurance),
    footerBox: getFooterBox(insurance),
    shortInfoBlockComponent: isNotRevokedAndIsNotFinished && (
      <ShortMotionMessage />
    ),
    showMessageButton: false,
  };
};
