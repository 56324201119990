import { styled, typography } from '@pulse-web-ui/theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const Content = styled.div`
  ${typography.regular14};
  background-color: ${({ theme }) => theme.colors.surface.brand2};
  border-radius: calc(${({ theme }) => theme.common.radius} * 2);
  color: ${({ theme }) => theme.colors.text.inverse};
  padding: 8px 12px;
  position: absolute;
  top: -14px;
  transform: translateY(-100%);
  right: 0;
  width: max-content;

  &::before {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.colors.surface.brand2};
    bottom: 0;
    transform: translateY(100%);
    right: 8px;
  }
`;
