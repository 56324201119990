import { makeAutoObservable } from 'mobx';
import type { Insurance } from './types/insurance.types';

export class InsuranceStore {
  activeSubscriptions: Array<Insurance> = [];
  completedSubscriptions: Array<Insurance> = [];
  currentEndsAt: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setActiveSubscriptions = (e: Array<Insurance>) => {
    this.activeSubscriptions = e;
  };

  setCompletedSubscriptions = (e: Array<Insurance>) => {
    this.completedSubscriptions = e;
  };

  setCurrentEndsAt = (e: string) => {
    this.currentEndsAt = e;
  };

  get hasSubscriptions() {
    return Boolean(
      this.activeSubscriptions?.length || this.completedSubscriptions?.length,
    );
  }

  get mainInsurances() {
    if (this.activeSubscriptions.length) {
      return this.activeSubscriptions.slice(0, 3);
    }

    return this.completedSubscriptions.slice(0, 3);
  }
}
