import type { EntityCodeAndName } from '@shared/types';
import { makeAutoObservable } from 'mobx';

export class KindSportStore {
  kindSports: EntityCodeAndName[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setKindSports = (e: EntityCodeAndName[]) => {
    this.kindSports = e;
  };
}
