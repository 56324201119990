import { makeAutoObservable } from 'mobx';
import type { Contract } from './types';

export class ContractStore {
  currentContracts: Array<Contract>;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentContracts = (contracts: Array<Contract>) => {
    this.currentContracts = contracts;
  };
}
