import { Info, ModalEnums } from '@shared/const';
import { ProductName } from '@entities/product';
import type { GetSubscriptionInfoResponse } from '@entities/insurance-detail';
import { formattingNumberByRu } from '@shared/utils';
import {
  getTermInsurer,
  getWordListing,
  getTermPolicyValidityPeriod,
} from '@entities/insurance-detail';
import type { SubscriptionTerm } from '@shared/types';
import i18n from '@app/i18n/config';
import { Risks } from '@shared/ui';

export const getInsuranceConditionsTravel = (
  insurance: GetSubscriptionInfoResponse,
): SubscriptionTerm[] => {
  const terms: SubscriptionTerm[] = [
    {
      label: i18n.t('INSURANCE_DETAIL:terms.coverageAmount'),
      value: `${formattingNumberByRu(insurance.insuranceSum, { currency: insurance.currency })}`,
    },
    { ...getTermInsurer(insurance.insuredPersons) },
    {
      label: i18n.t('INSURANCE_DETAIL:terms.insuranceRisks'),
      value: Risks({ risks: insurance.insuranceCoverages }),
      type: Info.WITH_MODAL,
      modalType: ModalEnums.TRAVEL_INSURANCE_RISKS,
    },
    {
      ...getWordListing(insurance.activities || [], ProductName.TRAVEL),
    },
    {
      label: i18n.t('INSURANCE_DETAIL:terms.policyCost'),
      value: `${formattingNumberByRu(insurance.currentPremiumAndDelta)}`,
    },
    { ...getTermPolicyValidityPeriod(insurance) },
    {
      label: i18n.t('INSURANCE_DETAIL:terms.policyNumber'),
      value: insurance.contractNumber,
      type: Info.WITH_DOWNLOAD,
    },
  ];

  return terms;
};
