import { memo, useState } from 'react';
import { useLocation } from 'react-router';

import { ProtectedRoutes, protectedRoutePath } from '@shared/lib/react-router';
import { Tab, TabPanel } from '@shared/ui/tab';
import { filterName } from '../../model/constants';
import { ProductCard } from '../product-card';
import { useTranslation } from 'react-i18next';
import { Box } from '@shared/ui';
import { StyledBox, StyledLabel, TabListStyled } from './product.styles';

import { useProductItems } from '../../model/hooks';
import { filteredProduct } from '../../model/utils';
import { ProductSkeleton } from '../skeleton';

export const Product = memo(() => {
  const { data, isLoading } = useProductItems();
  const { t } = useTranslation();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  if (isLoading || !data) {
    return <ProductSkeleton />;
  }

  const handleActiveTab = (val: number) => {
    setActiveTab(val);
  };

  const { all, property, sportAndHealth, pets } = filteredProduct(data);

  const productTypes = [
    filterName.all,
    filterName.property,
    filterName.sportAndHealth,
    filterName.pets,
  ];

  const productItemsType = [all, property, sportAndHealth, pets];

  const isMainPath =
    location.pathname === protectedRoutePath[ProtectedRoutes.MAIN];

  return (
    <Box>
      {isMainPath && (
        <StyledLabel>
          {t('COMMON:insurance:letsGetYourFirstInsurance')}
        </StyledLabel>
      )}
      <TabListStyled onChange={handleActiveTab} value={activeTab}>
        {productTypes.map(product => (
          <Tab key={product} label={product} />
        ))}
      </TabListStyled>
      {productItemsType?.map((products, idx) => {
        return (
          <TabPanel key={idx} value={activeTab} index={idx}>
            <StyledBox>
              {products?.map(product => (
                <ProductCard key={product.productCode} item={product} />
              ))}
            </StyledBox>
          </TabPanel>
        );
      })}
    </Box>
  );
});
