import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import type { IconProps } from '@pulse-web-ui/icons/lib/icons.types';
import { useTheme } from '@pulse-web-ui/theme';

import { useIsDesktop } from '@shared/hooks';

import { LinkStyled } from './nav-link.styles';

interface Props {
  to: string;
  text: string;
  Icon: FC<IconProps>;
}

export const NavLink: FC<Props> = ({ to, text, Icon }) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const match = useMatch(location.pathname);

  const isActive = to === match?.pathname;

  const iconColor = isDesktop
    ? theme.colors.icon.primary
    : theme.colors.icon.quaternary;

  const activeIconColor = isDesktop
    ? theme.colors.icon.primary
    : theme.colors.icon.secondary;

  return (
    <LinkStyled to={to}>
      <Icon
        width={24}
        color={isActive ? activeIconColor : iconColor}
        viewBox='0 0 24 24'
      />
      {text}
    </LinkStyled>
  );
};
