import { Copy } from '@pulse-web-ui/icons';
import {
  ButtonInsurance,
  SubTitleInfoDetail,
  TitleInfoDetail,
} from '../shared';
import {
  DownloadWrapper,
  InfoWrapper,
  Wrapper,
} from './info-with-download.styles';
import { useDownloadFile, useIsMobile } from '@shared/hooks';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@pulse-web-ui/theme';
import { addToClipboard } from '@shared/utils';
import { ButtonDownload, Tooltip } from '@shared/ui';
import { observer } from 'mobx-react-lite';
import {
  sendAnalyticEventCase,
  AnalyticEventCases,
  useParamsForAnalytic,
} from '@shared/lib/web-analytic';

interface InfoWithDownloadProps {
  title?: string;
  subTitle: ReactNode;
  policyId: string;
}

export const InfoWithDownload = observer(
  ({ title, subTitle, policyId }: InfoWithDownloadProps) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const theme = useTheme();
    const { handleClick: downloadFile, isPending } = useDownloadFile({
      documentId: policyId,
    });
    const params = useParamsForAnalytic();

    const buttonType = isMobile ? 'square' : undefined;

    const handleClickAddToClipboardButton = () => {
      if (typeof subTitle === 'string') {
        addToClipboard(subTitle);
        sendAnalyticEventCase(AnalyticEventCases.COPY_POLIS, params);
      }
    };

    const handleClickDownloadButton = () => {
      downloadFile();
      sendAnalyticEventCase(AnalyticEventCases.DOWNLOAD_POLIS, params);
    };

    return (
      <Wrapper>
        <InfoWrapper>
          <TitleInfoDetail>{title}</TitleInfoDetail>
          <SubTitleInfoDetail>{subTitle}</SubTitleInfoDetail>
        </InfoWrapper>
        <DownloadWrapper>
          {policyId && (
            <ButtonDownload
              typeButton={buttonType}
              onClick={handleClickDownloadButton}
              isPending={isPending}
            >
              {!isMobile && t('INSURANCE_DETAIL:button.downloadPolicy')}
            </ButtonDownload>
          )}
          <Tooltip content={t('INSURANCE_DETAIL:tooltip.copied')}>
            <ButtonInsurance
              type={buttonType}
              onClick={handleClickAddToClipboardButton}
            >
              <Copy color={theme.colors.icon.primary} width={24} />
            </ButtonInsurance>
          </Tooltip>
        </DownloadWrapper>
      </Wrapper>
    );
  },
);
