import { AUTH_CSRF_TOKEN, AUTH_PHONE } from './auth.constants';
import type { AuthPhoneType } from './types';

export const getAuthPhone = () => localStorage.getItem(AUTH_PHONE);
export const setAuthPhone = (phone: AuthPhoneType | null) =>
  phone
    ? localStorage.setItem(AUTH_PHONE, phone)
    : localStorage.removeItem(AUTH_PHONE);

export const getAuthCsrfToken = () => localStorage.getItem(AUTH_CSRF_TOKEN);
export const setAuthCsrfToken = (token?: string) =>
  token
    ? localStorage.setItem(AUTH_CSRF_TOKEN, token)
    : localStorage.removeItem(AUTH_CSRF_TOKEN);
