import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { ProductCode } from '@entities/product';
//TODO: Создать сущность по каждой анкете по примеру Travel
import { prepareDetailApartment } from './prepare-detail-apartment';
import { prepareDetailSport } from './prepare-detail-sport';
import { prepareDetailHouse } from './prepare-detail-house';
import { prepareDetailTravel } from '@entities/travel';
import { prepareDetailPet } from './prepare-detail-pet';
import { prepareDetailMite } from './prepare-detail-mite';
import { prepareDetailMiteSubscription } from './prepare-detail-mite-subscription';
import { prepareDetailMotion } from './prepare-detail-motion';
import { prepareDetailMortgage } from './prepare-detail-mortgage';

export const prepareInsuranceDetail = (
  insurance?: GetSubscriptionInfoResponse,
): InsuranceDetail | null => {
  const productCode = insurance?.product.code;
  if (!productCode) return null;

  switch (productCode) {
    case ProductCode.APARTMENT: {
      return prepareDetailApartment(insurance);
    }

    case ProductCode.HOUSE: {
      return prepareDetailHouse(insurance);
    }

    case ProductCode.TRAVEL: {
      return prepareDetailTravel(insurance);
    }

    case ProductCode.SPORT: {
      return prepareDetailSport(insurance);
    }

    case ProductCode.PET: {
      return prepareDetailPet(insurance);
    }

    case ProductCode.MITE: {
      return prepareDetailMite(insurance);
    }

    case ProductCode.MITE_SUBSCRIBE: {
      return prepareDetailMiteSubscription(insurance);
    }

    case ProductCode.MOTION: {
      return prepareDetailMotion(insurance);
    }

    case ProductCode.MORTGAGE: {
      return prepareDetailMortgage(insurance);
    }

    default: {
      return null;
    }
  }
};
