import { media, styled } from '@pulse-web-ui/theme';

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 16px;

  ${media.desktop} {
    column-gap: 16px;
    flex-direction: row;
    padding: 0;
  }
`;
