import { css, styled, typography } from '@pulse-web-ui/theme';
import { ColorStatus } from '../model/constants';

export const Status = styled.div<{
  status: ColorStatus;
}>`
  ${typography.regular12};
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  line-height: 18px;
  padding: 1px 8px;
  width: max-content;

  ${({ status }) => {
    if (status === ColorStatus.ERROR) {
      return css`
        background-color: ${({ theme }) => theme.colors.accent.error.faded};
        color: ${({ theme }) => theme.colors.text.error};
      `;
    }

    if (status === ColorStatus.INFO) {
      return css`
        background-color: ${({ theme }) => theme.colors.surface.background};
        color: ${({ theme }) => theme.colors.text.secondary};
      `;
    }

    if (status === ColorStatus.SUCCESS) {
      return css`
        background-color: ${({ theme }) => theme.colors.accent.success.faded};
        color: ${({ theme }) => theme.colors.text.success};
      `;
    }

    if (status === ColorStatus.WARNING) {
      return css`
        background-color: ${({ theme }) => theme.colors.accent.warning.faded};
        color: ${({ theme }) => theme.colors.text.warning};
      `;
    }
  }}
`;
