import { useCoverageSummary } from '@shared/hooks';
import type { FC } from 'react';

interface RisksProps {
  name: string;
}

export const Risks: FC<{
  risks?: RisksProps[];
}> = ({ risks }) => {
  if (!risks) return null;

  const { fullItems, remainingText, isShortList } = useCoverageSummary({
    items: risks,
  });

  if (isShortList) {
    return <>{fullItems}</>;
  }

  return (
    <>
      {fullItems} {remainingText}
    </>
  );
};
