import { styled, typography } from '@pulse-web-ui/theme';

export const PeriodTravel = styled.span`
  ${typography.medium16}
  &::after {
    content: '·';
    display: inline-block;
    margin: 0px 3px 0px 0px;
  }
`;

export const HeaderSubTitleTravelWrapper = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;
`;
