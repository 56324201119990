import { checkIsPartnerProduct } from '@entities/product';
import { useStores } from '@shared/lib/mobx';
import type { AnalyticEventCaseParams } from '../utils';

export const useParamsForAnalytic = () => {
  const {
    GlobalStore: {
      insuranceDetailStore: { insuranceDetail },
      contractDetailStore: { contractDetail },
    },
  } = useStores();

  const params: AnalyticEventCaseParams = {};
  const productCode = insuranceDetail?.product?.code || contractDetail?.code;

  if (!productCode) {
    return params;
  }

  const isProductPartner = checkIsPartnerProduct(productCode);
  params.productCode = productCode;

  if (isProductPartner) {
    params.productName = contractDetail?.name;
  } else {
    params.productName = insuranceDetail?.product.name;
  }

  return params;
};
