import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { ProductName } from '@entities/product';
import { ShortPetsMessage } from '@entities/insurance-detail/ui/short-pets-message';
import { getSubTitleForPet } from './get-subtitle-for-pet';
import { getDocumentsBlock } from './get-documents-block';
import { getFooterBox } from './get-footer-box';
import { getInsuranceStatuses } from './get-insurance-statuses';
import { getAlertTexts } from './get-alert-texts';

export const prepareDetailPet = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  const { isRevoked, isFinished } = getInsuranceStatuses(insurance.status);
  const isNotRevokedAndIsNotFinished = !isRevoked && !isFinished;

  return {
    header: {
      title: insurance.insuredPet?.name || '',
      subTitle: getSubTitleForPet(insurance) || '',
      productIcon: ProductName.PET,
    },
    alert: getAlertTexts(insurance),
    showAlertInfo: isNotRevokedAndIsNotFinished,
    documents: getDocumentsBlock(insurance),
    footerBox: getFooterBox(insurance),
    shortInfoBlockComponent: isNotRevokedAndIsNotFinished && <ShortPetsMessage />,
    showMessageButton: false,
  };
};
