import { styled } from '@pulse-web-ui/theme';

export const LoadingStyled = styled.svg`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: rotate 3s linear infinite;
`;
