import { getProductNameByCode } from '@entities/product';
import type { AlertType } from '../types';
import type { GetSubscriptionInfoResponse } from '../types';
import { getAlertTitle } from './get-alert-title';
import { getAlertMessage } from './get-alert-message';

export const getAlertTexts = (
  insurance: GetSubscriptionInfoResponse,
): AlertType => {
  const productName = getProductNameByCode(insurance.product.code);
  const { status, endsAt } = insurance;

  return {
    title: getAlertTitle({ productName, status, endsAt }),
    message: getAlertMessage({ productName, status, endsAt }),
  };
};
