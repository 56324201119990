import { media, styled, typography } from '@pulse-web-ui/theme';

export const IconWithTextBlockIconWrapper = styled.div`
  border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  background-color: ${({ theme }) => theme.colors.surface.background};
  flex: 0 0 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex: 0 0 24px;
  }
`;

export const IconWithTextBlockItem = styled.button`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);

  ${media.desktop} {
    cursor: pointer;
    padding: 0 16px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.surface.background};
      border-radius: calc(${({ theme }) => theme.common.radius} * 4);

      ${IconWithTextBlockIconWrapper} {
        background-color: ${({ theme }) => theme.colors.surface.primary};
      }
    }
  }
`;

export const IconWithTextBlockName = styled.h4`
  ${typography.medium16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.desktop} {
    ${typography.medium18}
    line-height: 26px;
    text-overflow: ellipsis;
  }
`;

export const IconWithTextBlockBadge = styled.span`
  padding: 1px 8px 1px 8px;
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  ${typography.regular12}
  background-color: ${({ theme }) =>
    theme.colors.surface.interactive['interactive-light']};
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.surface.accent};
  margin-left: auto;
`;
