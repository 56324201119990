import { styled, typography, media } from '@pulse-web-ui/theme';

export const NavStyled = styled.nav`
  width: 100%;
  height: 100%;

  ul {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    justify-content: center;
    gap: 32px;

    li {
      flex: 0 0 54px;
      list-style-type: none;
      ${typography.bold10};
    }
  }

  ${media.desktop} {
    ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 24px;

      li {
        ${typography.bold18};
        flex: 0 0 40px;
        font-weight: 600;
        line-height: 26px;
        width: 100%;
      }
    }
  }
`;
