import { useMutation, useMutationState } from '@tanstack/react-query';

import { useStores } from '@shared/lib/mobx';

import { SecondaryError } from '../secondary-error';
import { MainError } from '../main-error';

import type { FC } from 'react';

type Props = {
  errorSecondaryType?: boolean;
};

export const Error: FC<Props> = ({ errorSecondaryType }) => {
  const {
    GlobalStore: {
      errorBoundaryStore: { setHasError },
    },
  } = useStores();

  const mutationState = useMutationState({
    select: mutation => mutation,
  });

  const lastMutation = mutationState[mutationState.length - 1];

  const variables = lastMutation?.state.variables;
  const options = lastMutation?.options;

  const { mutateAsync, isPending } = useMutation(options);

  const handleClick = () => {
    mutateAsync(variables).then(() => {
      setHasError(false);
    });
  };

  if (errorSecondaryType) {
    return <SecondaryError isLoading={isPending} onUpdate={handleClick} />;
  }

  return <MainError isLoading={isPending} onUpdate={handleClick} />;
};
