import type { ReactNode } from 'react';
import { memo } from 'react';
import {
  IconWithTextBlockIconWrapper,
  IconWithTextBlockItem,
  IconWithTextBlockName,
  IconWithTextBlockBadge,
} from './icon-with-text-block.styles';
import { Spinner } from '@pulse-web-ui/spinner';
import type { IconProps } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import type { CSSProperties } from '@pulse-web-ui/theme';
import i18n from '@app/i18n/config';

interface IconWithTextBlockProps {
  isPending?: boolean;
  Icon: (props: IconProps) => ReactNode;
  name: string;
  isNew?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

export const IconWithTextBlock = memo((props: IconWithTextBlockProps) => {
  const { isPending, name, Icon, onClick, style, isNew } = props;
  const theme = useTheme();

  return (
    <IconWithTextBlockItem style={style} onClick={onClick} disabled={isPending}>
      <IconWithTextBlockIconWrapper>
        <Icon width={24} color={theme.colors.icon.primary} />
      </IconWithTextBlockIconWrapper>
      <IconWithTextBlockName>{name}</IconWithTextBlockName>
      {isNew && (
        <IconWithTextBlockBadge>
          {i18n.t('INSURANCE_DETAIL:badge.new')}
        </IconWithTextBlockBadge>
      )}
      {isPending && <Spinner />}
    </IconWithTextBlockItem>
  );
});
