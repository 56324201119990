import { Label } from '@shared/ui';
import { useStores } from '@shared/lib/mobx';
import { StyledModalBox } from '@shared/ui/modal-box';
import { observer } from 'mobx-react-lite';

export const TravelActivitieslModal = observer(() => {
  const {
    GlobalStore: {
      insuranceDetailStore: { insuranceDetail },
    },
  } = useStores();

  const activities = insuranceDetail?.activities;

  if (!activities) return null;

  return activities?.map(item => (
    <StyledModalBox key={item.code}>
      <Label>{item.name}</Label>
    </StyledModalBox>
  ));
});
