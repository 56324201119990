import { useEffect, useState, type FC, type ReactNode } from 'react';

import { useHandleFetchRefreshToken } from '@entities/refresh/hooks';

import { useStores } from '@shared/lib/mobx';
import { observer } from 'mobx-react-lite';
import { getAuthCsrfToken } from '@entities/auth';
import { addHeaderAuth } from '@shared/lib/axios';
import { Loader } from '@shared/ui/loader';

type Props = {
  children: ReactNode;
};

export const RefreshBox: FC<Props> = observer(({ children }) => {
  const {
    GlobalStore: {
      authStore: { isUpdateRefreshToken: isRefreshToken },
    },
  } = useStores();
  const [loading, setLoading] = useState(true);
  const csrfToken = getAuthCsrfToken();

  const {
    fetchResult: { mutateAsync, status },
  } = useHandleFetchRefreshToken();

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (csrfToken) {
      addHeaderAuth();
      mutateAsync({});
    } else {
      setLoading(false);
    }
  }, [isRefreshToken]);

  if (loading) {
    return <Loader />;
  }

  return <>{children}</>;
});
