import { ProductName } from '@entities/product';
import { InsuranceStatus } from '@shared/types';
import type { AlertType } from '../types';
import i18n from '@app/i18n/config';
import { AlertTitleCancelledStatus } from '../../ui/alert-texts';

export interface GetAlertTitleParams {
  productName: ProductName;
  status: InsuranceStatus;
  endsAt: string;
}

type InsuranceStatusAlertTitleType = Partial<
  Record<InsuranceStatus, AlertType['title']>
>;
type AlertTitleMapType = Partial<
  Record<ProductName, InsuranceStatusAlertTitleType>
>;

export const getAlertTitle = ({
  productName,
  status,
  endsAt,
}: GetAlertTitleParams) => {
  const AlertTitleMap: AlertTitleMapType = {
    [ProductName.APARTMENT]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.SUSPENDED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.CANCELLED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.REVOKED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.FINISHED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
      [InsuranceStatus.TERMINATED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
    },
    [ProductName.HOUSE]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.SUSPENDED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.CANCELLED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.REVOKED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.FINISHED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
      [InsuranceStatus.TERMINATED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
    },

    [ProductName.MOTION]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.SUSPENDED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.CANCELLED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.REVOKED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.FINISHED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
    },
    [ProductName.MITE_SUBSCRIBE]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.SUSPENDED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.CANCELLED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.REVOKED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.FINISHED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
    },
    [ProductName.PET]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.SUSPENDED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.CANCELLED]: (
        <AlertTitleCancelledStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.REVOKED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionCanceled',
      ),
      [InsuranceStatus.FINISHED]: i18n.t(
        'INSURANCE_DETAIL:alert.title.subscriptionFinished',
      ),
    },
  };

  return AlertTitleMap[productName]?.[status] || '';
};
