import { useNavigate } from 'react-router-dom';

import { Codes } from '@shared/const';
import type { ErrorResponse } from '@shared/api';
import { useFetchProfile } from './use-fetch-profile';
import { useStores } from '@shared/lib/mobx';
import type { ProfileResponse } from '../types';
import { PublicRoutes, publicRoutePath } from '@shared/lib/react-router';

export const useNavigateProfile = () => {
  const navigate = useNavigate();
  const {
    GlobalStore: {
      userStore: { setProfile },
    },
  } = useStores();

  const onSuccess = (data: ProfileResponse) => {
    setProfile(data);
  };

  const onError = (e: ErrorResponse) => {
    const statusCode = e.response?.status;

    if (statusCode === Codes.ClientErrorForbidden) {
      navigate(publicRoutePath[PublicRoutes.REGISTRATION]);
    }
  };

  return useFetchProfile({
    onError,
    onSuccess,
  });
};
