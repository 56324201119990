import { getPolicyValidatePeriod } from '@entities/insurance-detail';
import {
  HeaderSubTitleTravelWrapper,
  PeriodTravel,
} from './header-sub-title-travel.styles';
import { Territories } from '../territories';
import type { HeaderSubTitleProps } from '../../model/types';

export const HeaderSubTitleTravel = (insurance: HeaderSubTitleProps) => {
  const { effectiveSinceForPeriod, endsAtForPeriod, territories } = insurance;

  const period: string = getPolicyValidatePeriod(
    effectiveSinceForPeriod as string,
    endsAtForPeriod as string,
  );

  return (
    <HeaderSubTitleTravelWrapper>
      <PeriodTravel>{period}</PeriodTravel>
      <Territories territtories={territories} />
    </HeaderSubTitleTravelWrapper>
  );
};
