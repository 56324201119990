import { Logo } from '@shared/ui';
import { AsideStyled, Sticky, TopSidebar } from './sidebar.styles';
import { Menu } from '@entities/menu';
import { DownloadApp } from '@entities/mobile-app';

export const Sidebar = () => {
  return (
    <AsideStyled>
      <Sticky>
        <TopSidebar>
          <Logo size='small' />
          <Menu />
        </TopSidebar>
        <DownloadApp />
      </Sticky>
    </AsideStyled>
  );
};
