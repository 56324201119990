import i18n from '@app/i18n/config';

type Props = {
  items: Array<{ name: string }>;
  maxCountSlice?: number;
};

export const useCoverageSummary = ({ items, maxCountSlice = 2 }: Props) => {
  const fullItems = items
    .slice(0, maxCountSlice)
    .map(item => item.name)
    .join(', ');

  const countRemaining =
    items.length > maxCountSlice ? items.length - maxCountSlice : 0;

  const isShortList = items.length <= maxCountSlice;

  return {
    fullItems,
    isShortList,
    remainingText: i18n.t('INSURANCE_DETAIL:terms.andFurther', {
      count: countRemaining,
    }),
  };
};
