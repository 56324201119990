import type { PreviousContract } from '@shared/types';

export const getPrevInsurancesForFilter = (
  prevInsurances: PreviousContract[],
) => {
  const filteredInsurances = new Map();

  prevInsurances.forEach(insurance => {
    const year = new Date(insurance.effectiveSince).getFullYear().toString();

    if (filteredInsurances.has(year)) {
      const InsurancesByCurrentYear = filteredInsurances.get(year);

      filteredInsurances.set(year, [...InsurancesByCurrentYear, insurance]);
    } else {
      filteredInsurances.set(year, [insurance]);
    }
  });

  return filteredInsurances;
};
