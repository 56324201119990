export enum ProductName {
  HOUSE = 'house', // Дом
  APARTMENT = 'apartment', // Квартира
  PET = 'pet', // Питомец
  MITE = 'antimite', // Антиклещ
  MITE_SUBSCRIBE = 'antimite_subscribe', // Антиклещ подписочный
  TRAVEL = 'travel', // Путешествие
  SPORT = 'sport', // Спорт
  MOTION = 'health', // Движение
  MORTGAGE = 'mortgage', // Ипотека
  BIKE = 'bike',
  CAMP = 'camp',
  PARTNER = 'partner',
  TELEVET = 'televet',
}

export type ExcludeProducts = Exclude<
  ProductName,
  | ProductName.BIKE
  | ProductName.PARTNER
  | ProductName.CAMP
  | ProductName.MORTGAGE
  | ProductName.MOTION
  | ProductName.MITE
  | ProductName.TELEVET
>;
