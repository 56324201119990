import { useIsMobile } from '@shared/hooks';
import { Box, Skeleton, SkeletonColor } from '@shared/ui';
import { StyledBox } from '../product/product.styles';
import { StyledContent } from '../product-card/product-card.styles';
import { useTheme } from '@pulse-web-ui/theme';
import {
  ProductSkeletonFilterWrapper,
  ProductSkeletonTopWrapper,
  ProductSkeletonTopWrapperBlock,
} from './product-skeleton.styles';

export const ProductSkeleton = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Box>
      <ProductSkeletonTopWrapper>
        <Skeleton width={!isMobile ? 520 : undefined} height={32} />
        <ProductSkeletonFilterWrapper>
          {isMobile ? (
            <>
              {[...new Array(3)].map(el => {
                return <Skeleton key={el} width={106} height={40} />;
              })}
            </>
          ) : (
            <>
              <Skeleton width={59} height={40} />
              <Skeleton width={118} height={40} />
              <Skeleton width={163} height={40} />
              <Skeleton width={101} height={40} />
            </>
          )}
        </ProductSkeletonFilterWrapper>
      </ProductSkeletonTopWrapper>
      <StyledBox>
        {[...new Array(7)].map(el => {
          return (
            <StyledContent key={el} bg={theme.colors.surface.background}>
              <ProductSkeletonTopWrapperBlock>
                <Skeleton color={SkeletonColor.DARK} width={180} height={26} />
                <Skeleton color={SkeletonColor.DARK} width={280} height={20} />
              </ProductSkeletonTopWrapperBlock>
              <Skeleton color={SkeletonColor.DARK} width={242} height={46} />
            </StyledContent>
          );
        })}
      </StyledBox>
    </Box>
  );
};
