import { BusinessCodes } from '@shared/const';

export const isValidErrorCode = (errorCode: string) => {
  return Object.values(BusinessCodes).includes(errorCode as BusinessCodes);
};

export const isTechnicalError = (errorCode: string) => {
  return errorCode === BusinessCodes.TECHNICAL_ERROR;
};

export const isBadRequestError = (errorCode: string) => {
  return errorCode === BusinessCodes.ERR_BAD_REQUEST;
};

export const isTechnicalOrAnotherError = (
  errorCode: string,
): boolean | undefined => {
  return (
    isTechnicalError(errorCode) ||
    isBadRequestError(errorCode) ||
    !isValidErrorCode(errorCode)
  );
};
