import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import { ProductName } from '@entities/product';
import {
  STATUS_INSURANCE_SNIPPET,
  getSubTitle,
} from '@entities/insurance-detail';
import { HeaderMorgageSubTitle, ShortMorgageMessage } from '@shared/ui/morgage';
import { getFooterBox } from './get-footer-box';

export const prepareDetailMortgage = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  return {
    header: {
      title: insurance.product.name,
      subTitle: (
        <HeaderMorgageSubTitle
          address={insurance.address}
          insuredPersons={insurance.insuredPersons}
        />
      ),
      productIcon: ProductName.MORTGAGE,
      isPartner: true,
      status: {
        status: STATUS_INSURANCE_SNIPPET[insurance.status],
        message: getSubTitle({
          effectiveSince: insurance.effectiveSince,
          endsAt: insurance.endsAt,
          status: insurance.status,
        }),
      },
    },
    documents: insurance.documents,
    footerBox: getFooterBox(insurance),
    shortInfoBlockComponent: <ShortMorgageMessage status={insurance.status} />,
    showAlertInfo: true,
    showMessageButton: true,
  };
};
