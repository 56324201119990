import { media, styled } from '@pulse-web-ui/theme';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${media.desktop} {
    row-gap: 24px;
  }
`;
