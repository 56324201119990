import { NavLink } from 'react-router-dom';

import { styled, typography } from '@pulse-web-ui/theme';

export const NavLinkAsText = styled(NavLink)<{
  color?: string;
}>`
  ${typography.regular16}
  color: ${({ theme, color }) => color || theme.colors.surface.brand};
  line-height: 24px;
`;
