import { css, media, styled, typography } from '@pulse-web-ui/theme';

export const BackWrapper = styled.div`
  ${typography.medium16}
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

export const Header = styled.h1<{ size?: 'small' }>`
  ${typography.medium32}

  ${({ size }) => {
    if (size == 'small') {
      return css`
        ${typography.medium24}

        ${media.desktop} {
          ${typography.medium32}
        }
      `;
    }
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;

  padding-left: 16px;

  ${media.desktop} {
    padding: 0;
  }
`;
