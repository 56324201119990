import type { InsuredPerson } from '@shared/types';
import { makeAutoObservable } from 'mobx';

export class InsuredPersonStore {
  insuredPersons: InsuredPerson[];

  constructor() {
    makeAutoObservable(this);
  }

  setInsuredPersons = (e: InsuredPerson[]) => {
    this.insuredPersons = e;
  };
}
