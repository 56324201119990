import { styled, media } from '@pulse-web-ui/theme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  min-height: 280px;
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  padding: 20px 16px;

  ${media.desktop} {
    padding: 24px;
    max-width: 384px;
  }
`;
