import { businessLineType, productCodes } from '@shared/const';
import type { ProductResponse, ProductCardType  } from '../types';

export const filteredProduct = (product: ProductResponse) => {
  const { businessLines } = product;

  const all = businessLines.map((item: ProductCardType) => item);

  const property = businessLines.filter(item =>
    productCodes.property.includes(
      item.product || businessLineType.externalWeb,
    ),
  );

  const sportAndHealth = businessLines.filter(item =>
    productCodes.sportAndHealth.includes(item.product),
  );

  const pets = businessLines.filter(item =>
    productCodes.pets.includes(item.product),
  );

  return {
    all,
    property,
    sportAndHealth,
    pets,
  };
};
