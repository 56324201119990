import { usePostMutation } from '@shared/api';
import { Endpoints } from '@shared/const';

import type { ErrorResponse } from '@shared/api';
import type { LogoutRequest, LogoutResponse } from '../types';
import type { MutationOptions } from '@tanstack/react-query';

export const useFetchLogout = (
  options: MutationOptions<LogoutResponse, ErrorResponse, LogoutRequest>,
) => {
  const fetchResult = usePostMutation<LogoutRequest, LogoutResponse>({
    url: Endpoints.LOGOUT,
    options,
  });

  

  return fetchResult;
};
