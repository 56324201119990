import { styled, media, css } from '@pulse-web-ui/theme';
import type { Size } from './types';

type Props = {
  size?: Size;
};

export const LogoStyled = styled.img<Props>`
  width: 190px;

  ${media.desktop} {
    ${({ size }) => {
      if (size === 'small') {
        return css`
          width: 240px;
        `;
      }

      return css`
        width: 368px;
      `;
    }}
  }
`;
