import { useStores } from '@shared/lib/mobx';
import { StyledModalBox } from '@shared/ui/modal-box';
import { ContractInsuranceRisksTitle } from './contract-insurance-risks-modal.styles';

export const ContractInsuranceRisksModal = () => {
  const {
    GlobalStore: {
      contractDetailStore: { contractDetail },
    },
  } = useStores();

  const risks = contractDetail?.risks

  if (!risks) return null;

  return risks.map((risk, id) => (
    <StyledModalBox key={id}>
      <ContractInsuranceRisksTitle>{risk}</ContractInsuranceRisksTitle>
    </StyledModalBox>
  ));
};
