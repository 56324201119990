import { useEffect, useCallback } from 'react';
import { TITLE_MODAL } from '../constants';
import { useStores } from '@shared/lib/mobx';

export const useModal = () => {
  const {
    GlobalStore: {
      modalStore: { modal, setModal, prevModal },
    },
  } = useStores();

  const toogleModal = useCallback(() => {
    if (prevModal && prevModal !== modal) {
      return setModal({ modal: prevModal });
    }
    setModal({ modal: null });
  }, [modal, prevModal, setModal]);

  useEffect(() => {
    if (!modal) return;

    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = 'visible';
    };
  }, [modal]);

  return {
    toogleModal,
    title: modal ? TITLE_MODAL[modal] : null,
    modal,
  };
};
