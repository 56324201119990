import { InfoWithModal } from '../info-with-modal';
import { InfoWithDownload } from '../info-with-download';
import { InfoStatic } from '../info-static';
import type { Info as Props } from '../../model/types';
import { Info as InfoEnum } from '@shared/const';
import { InfoWithAccordion } from '../info-with-accordion';

export const Info = ({ policyId, item }: Props) => {
  const { label, value, hint, modalType, type } = item;

  if (!value) return null;

  if (type === InfoEnum.WITH_MODAL) {
    return (
      <InfoWithModal title={label} subTitle={value} modalType={modalType} />
    );
  }

  if (type === InfoEnum.WITH_DOWNLOAD) {
    return (
      <InfoWithDownload
        title={label}
        subTitle={value}
        policyId={policyId as string}
      />
    );
  }

  if (type === InfoEnum.WITH_ACCORDION) {
    return <InfoWithAccordion hint={hint} title={label} subTitle={value} />;
  }

  return <InfoStatic title={label} subTitle={value} hint={hint} />;
};
