import type { AxiosError } from 'axios';

export type ErrorResponse = AxiosError<{
  code: string;
  description: string;
  status?: number;
  unlockingTime?: string
}>;

export enum MethodApi {
  GET = 'get',
  POST = 'post',
}
