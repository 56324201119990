import { PartnerProductCode, ProductCode, ProductName } from '../types';

const productNameByCode: Record<ProductCode | PartnerProductCode, ProductName> =
  {
    [ProductCode.TELEVET]: ProductName.TELEVET,
    [ProductCode.HOUSE]: ProductName.HOUSE,
    [ProductCode.APARTMENT]: ProductName.APARTMENT,
    [ProductCode.SPORT]: ProductName.SPORT,
    [ProductCode.TRAVEL]: ProductName.TRAVEL,
    [ProductCode.MOTION]: ProductName.MOTION,
    [ProductCode.PET]: ProductName.PET,
    [ProductCode.MITE]: ProductName.MITE,
    [ProductCode.MITE_SUBSCRIBE]: ProductName.MITE_SUBSCRIBE,
    [ProductCode.MORTGAGE]: ProductName.MORTGAGE,
    [PartnerProductCode.BIKE_PARTNER_1]: ProductName.BIKE,
    [PartnerProductCode.SPORT_PARTNER_1]: ProductName.SPORT,
    [PartnerProductCode.SPORT_PARTNER_2]: ProductName.SPORT,
    [PartnerProductCode.MOTION_PARTNER_1]: ProductName.MOTION,
    [PartnerProductCode.MOTION_PARTNER_2]: ProductName.MOTION,
    [PartnerProductCode.MITE_PARTNER_1]: ProductName.MITE,
    [PartnerProductCode.MITE_PARTNER_2]: ProductName.MITE,
    [PartnerProductCode.HOUSE_PARTNER_1]: ProductName.HOUSE,
    [PartnerProductCode.HOUSE_PARTNER_2]: ProductName.HOUSE,
    [PartnerProductCode.CAMP_PARTNER_1]: ProductName.CAMP,
    [PartnerProductCode.APARTMENT_PARTNER_1]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_2]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_3]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_4]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_5]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_6]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_7]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_8]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_9]: ProductName.APARTMENT,
    [PartnerProductCode.APARTMENT_PARTNER_10]: ProductName.APARTMENT,
  };

export const getProductNameByCode = (
  productCode: ProductCode | PartnerProductCode,
) => {
  return productNameByCode[productCode] || ProductName.HOUSE;
};
