// Преобразование ссылок, подготовленных исторически изначально для мобил, на корректные для веб-приложений
export const prepareImageUrl = (imageUrl: string) => {
  let correctImgUrl = imageUrl.replace(/pics-mw/, 'pics-web-lk');

  if (correctImgUrl.includes('VTB')) {
    correctImgUrl = correctImgUrl.replace('.1000', '');
  }

  return correctImgUrl;
};
