import { StyledThemeProvider, baseTheme } from '@pulse-web-ui/theme';
import type { ReactNode } from 'react';

type AppThemeProviderProps = {
  children: ReactNode;
};

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  return (
    <StyledThemeProvider theme={baseTheme}>{children}</StyledThemeProvider>
  );
};
