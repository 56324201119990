import {
  phoneDisplayCastingRegExp,
  phoneDisplayFormRegExp,
  phoneRemoveCastingRegExp,
} from '@shared/regexps';

export const phoneRemoveValueCasting = (value: string) =>
  `+7${value?.replace(phoneRemoveCastingRegExp, '') || ''}`;

export const phoneDisplayValueCasting = (value: string) =>
  `+7 ${value.replace(phoneDisplayCastingRegExp, '$1 ($2) $3-$4-$5')}`;

export const phoneDisplayValueForm = (value: string | null) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(phoneDisplayFormRegExp);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3] + '-' + match[4];
  }

  return '';
};
