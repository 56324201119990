import i18n from '@app/i18n/config';
import { declineWord } from '@shared/utils';

enum TimeUnitEnum {
  YEAR = 'Y',
  MONTH = 'M',
  DAY = 'D',
}

const ACCESS_TIME_UNIT = [
  TimeUnitEnum.DAY,
  TimeUnitEnum.MONTH,
  TimeUnitEnum.YEAR,
];

const declination = {
  [TimeUnitEnum.YEAR]: {
    one: i18n.t('INSURANCE_DETAIL:declensions.year-1'),
    few: i18n.t('INSURANCE_DETAIL:declensions.year-2'),
    many: i18n.t('INSURANCE_DETAIL:declensions.year-3'),
  },
  [TimeUnitEnum.MONTH]: {
    one: i18n.t('INSURANCE_DETAIL:declensions.month-1'),
    few: i18n.t('INSURANCE_DETAIL:declensions.month-2'),
    many: i18n.t('INSURANCE_DETAIL:declensions.month-3'),
  },
  [TimeUnitEnum.DAY]: {
    one: i18n.t('INSURANCE_DETAIL:declensions.day-1'),
    few: i18n.t('INSURANCE_DETAIL:declensions.day-2'),
    many:i18n.t('INSURANCE_DETAIL:declensions.day-3'),
  },
};

// period формата P15D, P1Y, P2M
export const getDuration = (period: string) => {
  const timeUnit: TimeUnitEnum = period.slice(-1) as TimeUnitEnum;

  if (!ACCESS_TIME_UNIT.includes(timeUnit)) {
    return '';
  }

  const duration = parseInt(period.slice(1, -1));
  const words = declination[timeUnit];

  return declineWord(duration, words);
};
