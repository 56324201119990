import { makeAutoObservable } from 'mobx';
import type { FindByIdResponse } from './types';
import { prepareContractDetail } from './utils';

export class ContractDetailStore {
  contractDetail?: FindByIdResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setContractDetail = (e: FindByIdResponse) => {
    this.contractDetail = e;
  };

  clearContractDetail = () => {
    this.contractDetail = undefined
  }

  get preparedContractDetail() {
    return prepareContractDetail(this.contractDetail);
  }
}
