import { Outlet } from 'react-router-dom';

import { ErrorBoundaryProvider } from '@app/providers/error-boundary-provider';
import { Header } from '@widgets/header';
import { Logo } from '@shared/ui';

import { MainStyled, ContentStyled } from './layout.styles';

export const Layout = () => {
  return (
    <MainStyled>
      <Header>
        <Logo />
      </Header>
      <ContentStyled>
        <ErrorBoundaryProvider errorSecondaryType>
          <Outlet />
        </ErrorBoundaryProvider>
      </ContentStyled>
    </MainStyled>
  );
};
