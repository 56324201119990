import { useTheme } from '@pulse-web-ui/theme';
import {
  ButtonModalStyled,
  ButtonsWrapperStyled,
} from './buttons-wrapper-for-modal.styles';
import { Message } from '@pulse-web-ui/icons';
import { PhoneClassic } from '@pulse-web-ui/icons';

import { useTranslation } from 'react-i18next';
import { ModalEnums, SUPPORT_TEL } from '@shared/const';
import { observer } from 'mobx-react-lite';
import {
  sendAnalyticEventCase,
  AnalyticEventCases,
  useParamsForAnalytic,
} from '@shared/lib/web-analytic';

interface ButtonsWrapperForModalProps {
  hasFreePhoneBtn?: boolean;
  hasWriteInChatBtn?: boolean;
  modalType?: ModalEnums;
  tel?: string;
}

export const ButtonsWrapperForModal = observer(
  ({
    modalType,
    hasFreePhoneBtn = true,
    hasWriteInChatBtn = false,
    tel,
  }: ButtonsWrapperForModalProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const params = useParamsForAnalytic();

    let handleClickFreePhoneButton = () => {};
    let handleClickWriteInChatButton = () => {};

    if (
      modalType === ModalEnums.REPORT_INSURED_EVENT ||
      modalType === ModalEnums.REPORT_INSURED_EVENT_SHORT
    ) {
      handleClickFreePhoneButton = () => {
        sendAnalyticEventCase(AnalyticEventCases.INSURANCE_CASE_CALL, params);
      };

      handleClickWriteInChatButton = () => {
        sendAnalyticEventCase(AnalyticEventCases.INSURANCE_CASE_CHAT, params);
      };
    }

    if (modalType === ModalEnums.CANCEL_SUBSCRIPTION) {
      handleClickFreePhoneButton = () => {
        sendAnalyticEventCase(AnalyticEventCases.CANCEL_CALL, params);
      };

      handleClickWriteInChatButton = () => {
        sendAnalyticEventCase(AnalyticEventCases.CANCEL_CHAT, params);
      };
    }

    return (
      <ButtonsWrapperStyled>
        {hasWriteInChatBtn && (
          <ButtonModalStyled onClick={handleClickWriteInChatButton}>
            <Message width={24} color={theme.colors.icon.secondary} />
            {t('MAXATTEMPTSERROR:errorMaxAttempt.writeInChat')}
          </ButtonModalStyled>
        )}
        {hasFreePhoneBtn && (
          <ButtonModalStyled
            as='a'
            href={tel || SUPPORT_TEL}
            onClick={handleClickFreePhoneButton}
          >
            <PhoneClassic width={20} color={theme.colors.icon.secondary} />
            {t('MAXATTEMPTSERROR:errorMaxAttempt.call')}
          </ButtonModalStyled>
        )}
      </ButtonsWrapperStyled>
    );
  },
);
