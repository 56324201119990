import { useStores } from '@shared/lib/mobx';
import {
  ContractAboutPolicyItem as ContractAboutPolicyItemStyled,
  ContractAboutPolicyList,
  ContractAboutPolicyTitle,
  ContractAboutPolicyWrapper,
} from './contract-about-policy.styles';
import { ContractAboutPolicyItem } from './contract-about-policy-item';

export const ContractAboutPolicy = () => {
  const {
    GlobalStore: {
      contractDetailStore: {
        contractDetail,
      },
    },
  } = useStores();

  const coverage = contractDetail?.coverage
  if (!coverage) return null;

  return (
    <ContractAboutPolicyWrapper>
      {coverage.map(coverage => {
        const { title, points } = coverage;

        const isPoints = points?.length > 1;

        return (
          <ContractAboutPolicyItemStyled key={title}>
            <ContractAboutPolicyTitle>{title}</ContractAboutPolicyTitle>
            <ContractAboutPolicyList>
              {points?.map((point, idx) => (
                <ContractAboutPolicyItem
                  key={`${point}${idx}`}
                  point={point}
                  isPoints={isPoints}
                />
              ))}
            </ContractAboutPolicyList>
          </ContractAboutPolicyItemStyled>
        );
      })}
    </ContractAboutPolicyWrapper>
  );
};
