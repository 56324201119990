import { ColorStatus } from '@entities/status';
import { PrevInsuranceStatus } from './prev-insurance-status';

export const PREV_INSURANCE_COLOR_STATUS = {
  [PrevInsuranceStatus.DRAFT]: ColorStatus.INFO,
  [PrevInsuranceStatus.READY]: ColorStatus.INFO,
  [PrevInsuranceStatus.SIGNED]: ColorStatus.INFO,
  [PrevInsuranceStatus.CANCELLED]: ColorStatus.ERROR,
  [PrevInsuranceStatus.REVOKED]: ColorStatus.ERROR,
  [PrevInsuranceStatus.TERMINATED]: ColorStatus.ERROR,
};
