import { Button } from '@pulse-web-ui/button';

import { styled, typography } from '@pulse-web-ui/theme';

export const BackStyled = styled(Button)`
  ${typography.regular16}
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: min-content;
`;
