import type { ReactNode } from 'react';

import { ExternalLinkStyled } from './external-link.styles';

interface ExternalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  href?: string;
  target?: string;
  className?: string;
  color?: string;
}

export const ExternalLink = ({
  href,
  target,
  children,
  className,
  color,
  ...rest
}: ExternalLinkProps) => {
  return (
    <ExternalLinkStyled
      color={color}
      href={href}
      target={target}
      className={className}
      {...rest}
    >
      {children}
    </ExternalLinkStyled>
  );
};
