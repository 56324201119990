import { styled } from '@pulse-web-ui/theme';
import { MainButton } from '@shared/ui';

export const Report = styled(MainButton)`
  background-color: ${({ theme }) => theme.colors.accent.error.normal};
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent.error.darkened};
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;
