import { Exit as ExitIcon } from '@pulse-web-ui/icons';

import { useHandleFetchLogout } from '@entities/logout';

import { ButtonStyled } from './exit.styles';

import type { FC } from 'react';
import { observer } from 'mobx-react-lite';

type Props = {
  iconColor: string;
};

export const Exit: FC<Props> = observer(({ iconColor }) => {
  const { mutate } = useHandleFetchLogout();

  const handleClick = () => {
    mutate({ logoutOnAllUserDevices: false });
  };

  return (
    <ButtonStyled onClick={handleClick}>
      <ExitIcon width={24} color={iconColor} viewBox='0 0 24 24' />
      Выйти
    </ButtonStyled>
  );
});
