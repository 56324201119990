import { useClientQuery } from '@shared/lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';

export const QueryProvider = ({ children }: PropsWithChildren) => {
  const { queryClient } = useClientQuery();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
