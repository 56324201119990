import { t } from 'i18next';
import { z } from 'zod';

import { phoneRegExp } from '@shared/regexps';

export const PhoneValidateSchema = z.object({
  phoneNumber: z
    .string({ required_error: t('AUTH:error.enterPhone') })
    .regex(phoneRegExp, t('AUTH:error.wrongPhone')),
});

export const phoneNumberSchema = z
  .string({ required_error: t('AUTH:error.enterPhone') })
  .regex(phoneRegExp, t('AUTH:error.wrongPhone'));

export const AuthSchema = z.object({
  authenticationUser: PhoneValidateSchema,
});
