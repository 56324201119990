import { styled, typography } from '@pulse-web-ui/theme';
import { NavLink } from 'react-router-dom';

export const ViewContractsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin: 24px 0px 0px 0px;
`;

export const ViewContractsItem = styled(NavLink)`
  padding: 11px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  background-color: ${({ theme }) => theme.colors.surface.background};
  cursor: pointer;
`;

export const ViewContractsItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  ${typography.medium18}
  line-height: 26px;
`;
