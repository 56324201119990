import { media, styled, typography } from '@pulse-web-ui/theme';

export const TitleDetail = styled.h2`
  ${typography.medium20};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0;

  ${media.desktop} {
    ${typography.medium24};
  }
`;
