import {
  Bullet,
  BulletWrapper,
  ContractAboutPolicyItemWrapper,
  ContractAboutPolicyListItem,
} from './contract-about-policy.styles';

interface ContractAboutPolicyItemProps {
  isPoints?: boolean;
  point: string;
}

export const ContractAboutPolicyItem = ({
  point,
  isPoints,
}: ContractAboutPolicyItemProps) => {
  return (
    <ContractAboutPolicyItemWrapper>
      {isPoints && (
        <BulletWrapper>
          <Bullet />
        </BulletWrapper>
      )}
      <ContractAboutPolicyListItem>{point}</ContractAboutPolicyListItem>
    </ContractAboutPolicyItemWrapper>
  );
};
