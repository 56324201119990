import { useNavigate } from 'react-router-dom';

import { Back as IconBack } from '@pulse-web-ui/icons';

import type { FC } from 'react';

import { ButtonStyled } from './back.styles';
import { useStores } from '@shared/lib/mobx';
import { observer } from 'mobx-react-lite';

type Props = {
  to?: string;
  onBack?: () => void;
};

export const Back: FC<Props> = observer(({ to, onBack }) => {
  const {
    GlobalStore: {
      errorBoundaryStore: { hasError, setHasError },
    },
  } = useStores();
  const navigate = useNavigate();

  const goToBack = () => {
    if (hasError) {
      setHasError(false);
      navigate(-1);
    } else if (onBack instanceof Function) {
      onBack();
    } else {
      to ? navigate(to) : navigate(-1);
    }
  };

  return (
    <ButtonStyled variant='square' onClick={goToBack}>
      <IconBack width={24} />
    </ButtonStyled>
  );
});
