import { addHeaderAuth } from './../utils/add-header-auth';
import { axiosInstance } from '../config';
import { Codes, Endpoints } from '@shared/const';
import { useCallback, useEffect } from 'react';

import { useStores } from '@shared/lib/mobx';
import { isTechnicalOrAnotherError } from '@shared/utils';

import type { AxiosError, AxiosResponse } from 'axios';
import type { ErrorResponse } from '@shared/api';

import { IGNORE_URLS } from '../constants';
import { getAuthCsrfToken } from '@entities/auth';
import type { RefreshTokenResponse } from '@entities/refresh/types';
import type { AuthResponse } from '@entities/auth/model/types';
import { addTokens } from '../utils';
import { useNavigate } from 'react-router-dom';
import { publicRoutePath, PublicRoutes } from '@shared/lib/react-router';
import type { RegistrationResponse } from '@entities/registration-form/models/types';
import type { OtpResponse } from '@entities/otp/types';

export const useAxiosInterceptors = () => {
  const {
    GlobalStore: {
      authStore: { updateRefreshToken, setAuthDisabled },
      errorBoundaryStore: { setHasError },
    },
  } = useStores();

  const navigate = useNavigate();

  const handleResponse = useCallback((response: AxiosResponse) => {
    const url = response.config.url;

    if (url === Endpoints.AUTHENTICATION) {
      const { authenticationToken } = response.data as AuthResponse;
      addTokens({ authToken: authenticationToken });
    }

    if (url === Endpoints.REFRESH_TOKEN) {
      const {
        authorization: { csrfToken, accessToken },
      } = response.data as RefreshTokenResponse;
      addTokens({ authToken: accessToken, csrfToken });
    }

    if (url === Endpoints.REG) {
      const {
        authorization: { accessToken, csrfToken },
      } = response.data as RegistrationResponse;
      addTokens({ authToken: accessToken, csrfToken });
    }

    if (url === Endpoints.CONFIRMAUTH) {
      const { accessToken } = response.data as OtpResponse;
      addTokens({ authToken: accessToken });
    }

    return response;
  }, []);

  //TODO: refactor
  const handleError = useCallback(
    async (error: AxiosError<ErrorResponse>) => {
      const url = error.config?.url || '';
      const csrfToken = getAuthCsrfToken();

      const isError = isTechnicalOrAnotherError(
        error.response?.data.code as string,
      );
      const status = error.response?.status;

      if (
        (status === Codes.ClientErrorBadRequest ||
          status === Codes.ClientErrorNotFound) &&
        isError &&
        !IGNORE_URLS.includes(url)
      ) {
        return setHasError(true);
      }

      if (error.response?.status === Codes.ClientErrorUnauthorized) {
        addHeaderAuth();
        if (url === Endpoints.REFRESH_TOKEN) {
          return Promise.reject(error);
        }

        if (csrfToken) {
          updateRefreshToken();
        } else {
          setAuthDisabled(true);
          navigate(publicRoutePath[PublicRoutes.AUTH]);
        }
      }

      return Promise.reject(error);
    },
    [navigate, setAuthDisabled, setHasError, updateRefreshToken],
  );

  const interceptor = useCallback(() => {
    return axiosInstance.interceptors.response.use(handleResponse, handleError);
  }, [handleError, handleResponse]);

  useEffect(() => {
    interceptor();
    return () => {
      axiosInstance.interceptors.response.eject(interceptor());
    };
  }, []);
};
