import { media, styled, typography } from '@pulse-web-ui/theme';

// TODO: Перенести схожии стили на уровень shared слоя
export const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const StyledInsuranceSubTitle = styled.div`
  ${typography.regular18}
  line-height: 26px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPhoneLink = styled.a`
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const StyledLinkButton = styled.button`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.text.interactive};
  ${media.desktop} {
    display: inline-block;
  }
`;

export const StyledDetailHeaderSubTitle = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  ${typography.medium16}
  font-weight: 400;
  line-height: 24px;
`;

export const StyledDetailSubTitleSpan = styled.div`
  font-weight: 600;
`;
