import type { ErrorResponse } from '@shared/api';
import type { MutationOptions } from '@tanstack/react-query';
import type { AuthRequest, AuthResponse } from '../types';
import { usePostMutation } from '@shared/api/hooks';

export const useFetchAuth = (
  options: MutationOptions<AuthResponse, ErrorResponse, AuthRequest>,
) => {
  const fetchResult = usePostMutation<AuthRequest, AuthResponse>({
    url: '/v1/user/authentication',
    options,
  });

  return fetchResult;
};

