import { css, styled, typography } from '@pulse-web-ui/theme';

export const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const StyledInsuranceSubTitle = styled.div`
  ${typography.regular18}
  line-height: 26px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPhoneLink = styled.a`
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const StyledDetailHeaderSubTitle = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  ${typography.regular16}
  line-height: 24px;
`;

export const StyledDetailSubTitle = styled.div<{
  hasInsuredPersons?: boolean;
}>`
  ${typography.medium16}
  ${({ hasInsuredPersons }) => !hasInsuredPersons && css`
    font-weight: 400;
  `}
`;
