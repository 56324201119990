import {
  ButtonsWrapperForModal,
  Description,
  DescriptionSpan,
} from '../common';
import { Trans } from 'react-i18next';

export const TerminateTheContractModal = () => {
  return (
    <>
      <Description>
        <Trans
          components={{ span: <DescriptionSpan /> }}
          defaults='INSURANCE_DETAIL:text.terminateTheContract'
        />
      </Description>
      <ButtonsWrapperForModal />
    </>
  );
};
