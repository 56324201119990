import { useStores } from '@shared/lib/mobx';
import { InsuranceTextWrapper, MODAL_INSURANCE_COVERAGES } from '../common';
import { TravelInsuredRisksOptions } from './travel-insured-risks.styles';
import { useTranslation } from 'react-i18next';
import { StyledModalBox } from '@shared/ui/modal-box';
import { useCallback } from 'react';
import { InsuranceCoveragesCode, ModalEnums } from '@shared/const';

export const TravelInsuredRisksModal = () => {
  const {
    GlobalStore: {
      insuranceCoverageStore: { insuranceCoverages },
      modalStore: { setModal },
    },
  } = useStores();
  const { t } = useTranslation();

  const handleClick = useCallback(
    (code: InsuranceCoveragesCode) => {
      setModal({
        modal: MODAL_INSURANCE_COVERAGES[code],
        prevModal: ModalEnums.TRAVEL_INSURANCE_RISKS,
      });
    },
    [setModal],
  );


  return (
    <>
      {insuranceCoverages?.map(item => (
        <StyledModalBox key={item.code}>
          <InsuranceTextWrapper name={item.name} description={item.description}>
            {item.options && item.options.length > 0 && (
              <TravelInsuredRisksOptions onClick={() => handleClick(item.code)}>
                {t('INSURANCE_DETAIL:terms.options', {
                  count: item.options.length,
                })}
              </TravelInsuredRisksOptions>
            )}
          </InsuranceTextWrapper>
        </StyledModalBox>
      ))}
    </>
  );
};
