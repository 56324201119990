import { media, styled, typography } from '@pulse-web-ui/theme';

export const SubTitleInfoDetail = styled.div`
  ${typography.medium16};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;
  text-align: left;

  ${media.desktop} {
    ${typography.medium18};
    line-height: 26px;
  }
`;
