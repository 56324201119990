import { mixins, typography, styled } from '@pulse-web-ui/theme';
import { Alignment } from '@pulse-web-ui/theme/lib/flex';

export const StyledMainBox = styled.div`
  ${mixins.flex({
    gap: 16,
    alignItems: 'center',
    wrap: false,
    horizontalAlignment: 'space-between',
  })}
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.surface.brand2};
  color: ${({ theme }) => theme.colors.text.inverse};
  
`;

export const StyledBox = styled.div<{
  direction?: 'row' | 'column';
  gap?: number;
  alignItems?: Alignment;
}>`
  ${props =>
    mixins.flex({
      gap: props.gap,
      alignItems: props.alignItems,
      wrap: false,
      direction: props.direction,
      horizontal: props.direction !== 'column',
    })}
`;

export const StyledTitle = styled.h3`
  ${typography.medium14}
  line-height: 20px;
`;

export const StyledDescription = styled.p`
  ${typography.regular14}
  line-height: 20px;
`;
