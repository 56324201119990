import type {
  PrevInsuranceDetail,
  PrevInsuranceResponse,
} from '@entities/previous-insurance/model/types';

export const preparePrevInsuranceDetailShortVersion = (
  insurance: PrevInsuranceResponse,
): PrevInsuranceDetail => {
  return {
    documents: insurance.documents,
    productCode: insurance.product.code,
  };
};