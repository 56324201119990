import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/lib/mobx';
import { businessLineType } from '@shared/const';

import { ProductLink } from './product-link';

import {
  StyledButton,
  StyledContent,
  StyledDescription,
  StyledLabel,
  StyledName,
} from './product-card.styles';
import { useTranslation } from 'react-i18next';
import { useGetByCode } from '@shared/hooks';
import { ProductCode, type ProductCardType } from '@entities/product';
import { AnalyticEvents, sendAnalyticEvent } from '@shared/lib/web-analytic';
import { getProductNameByCode, prepareImageUrl } from '../../model/utils';

interface ProductCardProps {
  item: ProductCardType;
}

const analytics: Record<string, AnalyticEvents> = {
  [ProductCode.TRAVEL]: AnalyticEvents.NEW_TRAVEL,
  [ProductCode.APARTMENT]: AnalyticEvents.NEW_APARTMENT,
  [ProductCode.HOUSE]: AnalyticEvents.NEW_HOUSE,
  [ProductCode.PET]: AnalyticEvents.NEW_PET,
  [ProductCode.SPORT]: AnalyticEvents.NEW_SPORT,
  [ProductCode.MITE]: AnalyticEvents.NEW_MITE,
  [ProductCode.MITE_SUBSCRIBE]: AnalyticEvents.NEW_MITE,
  [ProductCode.MORTGAGE]: AnalyticEvents.NEW_MORTGAGE,
};

export const ProductCard = observer(({ item }: ProductCardProps) => {
  const {
    GlobalStore: {
      titleStore: { setTitle },
    },
  } = useStores();
  const bgByCode = useGetByCode();
  const { t } = useTranslation();

  const bg = bgByCode[item.product || item.businessLineType];

  const isExternalLink = Boolean(
    item.webUrl && item.businessLineType === businessLineType.externalWeb,
  );
  const to = isExternalLink
    ? item.webUrl
    : `/product/${getProductNameByCode(item.product)}`;

  const handleClick = (product?: ProductCode) => () => {
    const event = product ? analytics[product] : AnalyticEvents.NEW_MORTGAGE;

    sendAnalyticEvent(event);
    setTitle(item.name);
  };

  return (
    <ProductLink
      to={to}
      state={location.pathname}
      isExternal={isExternalLink}
      onClick={handleClick(item.product)}
    >
      <StyledContent
        bg={bg}
        key={item.productCode}
        imgUrl={prepareImageUrl(item.imageUrl)}
      >
        <StyledLabel>
          <StyledName>{item.name}</StyledName>
          <StyledDescription>{item.description}</StyledDescription>
        </StyledLabel>
        <StyledButton>
          {t('COMMON:product:order')} {item.productConditions}
        </StyledButton>
      </StyledContent>
    </ProductLink>
  );
});
