import { useTranslation } from 'react-i18next';
import { Description } from '../common';
import { ArrowRight } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import {
  ViewContractsItem,
  ViewContractsItemTitle,
  ViewContractsWrapper,
} from './view-contracts-modal.styles';
import { useStores } from '@shared/lib/mobx';
import { protectedRoutePath, ProtectedRoutes } from '@shared/lib/react-router';
import { observer } from 'mobx-react-lite';
import { useModal } from '@app/modal';

export const ViewContractsModal = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toogleModal } = useModal();

  const {
    GlobalStore: {
      contractStore: { currentContracts },
    },
  } = useStores();

  if (!currentContracts) return;

  return (
    <>
      <Description>{t('CONTRACT:text:getContracts')}</Description>
      <ViewContractsWrapper>
        {currentContracts.map(({ agreementId, subProduct }) => {
          const to = protectedRoutePath[
            ProtectedRoutes.CONTRACT_DETAIL
          ].replace(':id', agreementId);

          return (
            <ViewContractsItem onClick={toogleModal} to={to} key={agreementId}>
              <ViewContractsItemTitle>{subProduct}</ViewContractsItemTitle>
              <ArrowRight color={theme.colors.text.primary} width={24} />
            </ViewContractsItem>
          );
        })}
      </ViewContractsWrapper>
    </>
  );
});
