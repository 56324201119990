import { useTranslation } from 'react-i18next';

import ErrorIcon from '@shared/assets/icons/error.svg?react';
import { MainButton, Wrapper } from '@shared/ui';

import {
  IconBoxStyled,
  StyledSubTitle,
  StyledTitle,
} from './secondary-error.styles';

import type { FC } from 'react';

type Props = {
  isLoading: boolean;
  onUpdate: () => void;
};

export const SecondaryError: FC<Props> = ({ isLoading, onUpdate }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <IconBoxStyled>
        <ErrorIcon />
      </IconBoxStyled>
      <StyledTitle>{t('ERROR:error.somethingWentWrong')}</StyledTitle>
      <StyledSubTitle>
        {t('ERROR:error.somethingWentWrongSubTitle')}
      </StyledSubTitle>
      <MainButton isLoading={isLoading} adaptiveWidth onClick={onUpdate}>
        {t('ERROR:error.update')}
      </MainButton>
    </Wrapper>
  );
};
