import { media, styled, typography } from '@pulse-web-ui/theme';

export const DetailSubtitle = styled.h3`
  ${typography.medium18};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0;

  ${media.desktop} {
    ${typography.medium20};
  }
`;
