import { styled, typography } from '@pulse-web-ui/theme';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TextWrapperTitle = styled.h3`
  ${typography.medium16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
`;
