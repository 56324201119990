import type { RouteProps } from 'react-router-dom';
import { AuthPage, MainPage, OtpPage } from '@pages/index';
import { RegistrationPage } from '@pages/registration';
import { MaxAttemptsErrorPage } from '@pages/max-attempts-error-page';

export enum AppRoutes {
  MAIN = 'main',
  AUTH = 'auth',
  REGISTRATION = 'registration',
  OTP = 'otp',
  NOT_FOUND = 'not_found',
  MAX_ATTEMPTS_ERROR = 'max_attempts_error',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: '/',
  [AppRoutes.AUTH]: '/auth',
  [AppRoutes.REGISTRATION]: '/registration',
  [AppRoutes.OTP]: '/otp',
  [AppRoutes.MAX_ATTEMPTS_ERROR]: '/max-attempts-error',

  // последний
  [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.AUTH]: {
    path: RoutePath.auth,
    element: <AuthPage />,
  },
  [AppRoutes.REGISTRATION]: {
    path: RoutePath.registration,
    element: <RegistrationPage />,
  },
  [AppRoutes.OTP]: {
    path: RoutePath.otp,
    element: <OtpPage />,
  },

  [AppRoutes.MAX_ATTEMPTS_ERROR]: {
    path: RoutePath.max_attempts_error,
    element: <MaxAttemptsErrorPage />,
  },

  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <div>Страница не найдена</div>,
  },
};
