import { formattingDateByRu } from '@shared/utils';

export const getPolicyValidatePeriod = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate.getFullYear() === endDate.getFullYear()) {
    return `${formattingDateByRu(startDate, 'short')} - ${formattingDateByRu(endDate)}`;
  }

  return `${formattingDateByRu(startDate)} - ${formattingDateByRu(endDate)}`;
};
