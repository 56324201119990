import type { FC, PropsWithChildren } from 'react';
import { Spinner } from '@pulse-web-ui/spinner';
import { DownloadTwo } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import { ButtonDownloadStyled } from './button-download.styles';

export const ButtonDownload: FC<
  PropsWithChildren<{
    isPending: boolean;
    onClick: () => void;
    typeButton?: 'square' | undefined;
  }>
> = ({ isPending, onClick, children, typeButton }) => {
  const theme = useTheme();
  return (
    //TODO: Исправить дубликат компонента ButtonInsurance, так как при использовании ButtonInsurance падает приложения
    <ButtonDownloadStyled onClick={onClick} type={typeButton}>
      {isPending ? (
        <Spinner />
      ) : (
        <>
          <DownloadTwo color={theme.colors.icon.primary} width={24} />
          {children}
        </>
      )}
    </ButtonDownloadStyled>
  );
};
