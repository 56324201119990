import type { GetSubscriptionInfoResponse } from '../types';
import { getInsuranceStatuses } from './get-insurance-statuses';

export const getDocumentsBlock = (insurance: GetSubscriptionInfoResponse) => {
  const { isRevoked, isFinished } = getInsuranceStatuses(insurance.status);

  if (isRevoked || isFinished) return;

  return insurance.documents;
};
