import { Outlet } from 'react-router-dom';

import { ErrorBoundaryProvider } from '@app/providers/error-boundary-provider';
import { Header } from '@widgets/header';
import { Logo } from '@shared/ui';
import { useIsMobile } from '@shared/hooks';
import { Menu } from '@entities/menu';
import { Title } from '@entities/title';

import {
  ContentStyled,
  FooterStyled,
  MainStyled,
  MaxContentContainer,
  Wrapper,
  StickyPromoHeader,
} from './main-layout.styles';
import { Sidebar } from '@widgets/sidebar';
import { memo, useCallback } from 'react';
import { AnalyticEvents, sendAnalyticEvent } from '@shared/lib/web-analytic';

export const MainLayout = memo(() => {
  const isMobile = useIsMobile();

  const handleClick = useCallback(() => {
    sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_MAIN);
  }, []);

  return (
    <MainStyled>
      {isMobile && <StickyPromoHeader />}
      {isMobile && (
        <Header>
          <Logo />
        </Header>
      )}
      <Wrapper>
        {!isMobile && <Sidebar />}
        <ContentStyled>
          <MaxContentContainer>
            <Title onClick={handleClick} />
            <ErrorBoundaryProvider>
              <Outlet />
            </ErrorBoundaryProvider>
          </MaxContentContainer>
        </ContentStyled>
      </Wrapper>
      {isMobile && (
        <FooterStyled>
          <Menu />
        </FooterStyled>
      )}
    </MainStyled>
  );
});
