import {
  ButtonsWrapperForModal,
  Description,
  DescriptionSpan,
} from '../common';
import { Trans } from 'react-i18next';

export const TerminateTheContractShortModal = () => {
  return (
    <>
      <Description>
        <Trans
          components={{ span: <DescriptionSpan /> }}
          defaults='INSURANCE_DETAIL:text.terminateTheContractShort'
        />
      </Description>
      <ButtonsWrapperForModal hasFreePhoneBtn={false} />
    </>
  );
};
