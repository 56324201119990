import { makeAutoObservable } from 'mobx';
import { TABS_STATUS } from './types';

export class TabsInsuranceStore {
  activeTab: TABS_STATUS = TABS_STATUS.ACTIVE;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveTab = (e: TABS_STATUS) => {
    this.activeTab = e;
  };
}
