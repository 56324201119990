import type { InsuranceCoverage } from '@shared/types';
import { makeAutoObservable } from 'mobx';

export class InsuranceCoverageStore {
  insuranceCoverages: InsuranceCoverage[];

  constructor() {
    makeAutoObservable(this);
  }

  setInsuranceCoverages = (e: InsuranceCoverage[]) => {
    this.insuranceCoverages = e;
  };
}
