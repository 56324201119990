import type { UseFormSetError } from 'react-hook-form';
import type { PhoneValidateType } from '../types';
import { useTranslation } from 'react-i18next';
import { BusinessCodes, Codes } from '@shared/const';
import { useStores } from '@shared/lib/mobx';

export const useHandleAuthErrors = (
  setError: UseFormSetError<PhoneValidateType>,
) => {
  const { t } = useTranslation();
  const {
    GlobalStore: {
      errorBoundaryStore: { setHasError },
    },
  } = useStores();

  return (errorCode?: string, errorStatus?: number, unlockingTime?: string) => {
    const clientErrorBadRequest = errorStatus === Codes.ClientErrorBadRequest;
    if (
      errorCode === BusinessCodes.RATE_LIMIT_EXCEEDED &&
      clientErrorBadRequest &&
      unlockingTime
    ) {
      setError('phoneNumber', {
        message: t('REGISTER:error.exceededAttemptsLimit'),
      });
    }
    if (errorCode === BusinessCodes.TECHNICAL_ERROR && clientErrorBadRequest) {
      setHasError(true);
    }

    if (errorCode === BusinessCodes.VALIDATION_ERROR) {
      setError('phoneNumber', {
        message: t('REGISTER:error.wrongPhone'),
      });
    }
  };
};
