import { ArrowRight } from '@pulse-web-ui/icons';
import { SubTitleInfoDetail, TitleInfoDetail } from '../shared';
import { useTheme } from '@pulse-web-ui/theme';
import { Button, IconWrapper, SubTitleWrapper } from './info-with-modal.styles';
import { useCallback } from 'react';
import type { FC, ReactNode } from 'react';
import { useStores } from '@shared/lib/mobx';
import type { ModalEnums } from '@shared/const';

type Props = {
  title?: string;
  subTitle: ReactNode;
  modalType?: ModalEnums;
};

export const InfoWithModal: FC<Props> = ({ title, subTitle, modalType }) => {
  const theme = useTheme();

  const {
    GlobalStore: {
      modalStore: { setModal },
    },
  } = useStores();

  const handleClick = useCallback(
    () => setModal({ modal: modalType || null }),
    [modalType, setModal],
  );

  return (
    <Button onClick={handleClick}>
      <SubTitleWrapper>
        <TitleInfoDetail>{title}</TitleInfoDetail>
        <SubTitleInfoDetail>{subTitle}</SubTitleInfoDetail>
      </SubTitleWrapper>
      <IconWrapper>
        <ArrowRight color={theme.colors.icon.quaternary} />
      </IconWrapper>
    </Button>
  );
};
