import { media, mixins, typography, styled, css } from '@pulse-web-ui/theme';

export const StyledLabel = styled.div`
  text-align: center;
`;

export const StyledContent = styled.div<{
  imgUrl?: string;
  bg?: string;
}>`
  ${({ imgUrl }) =>
    imgUrl &&
    css`
      background-image: url(${imgUrl});
      background-size: 390px auto;
      background-repeat: no-repeat;
      background-position: bottom;
    `}
  position: relative;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ bg }) => bg};
  padding: 16px 12px;
  min-height: 300px;
  width: 100%;
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);

  ${mixins.flex({ justifyContent: 'space-between', alignItems: 'center' })}
  flex-direction: column;
`;

export const StyledName = styled.div`
  ${typography.medium16}
  line-height: 24px;

  ${media.desktop} {
    ${typography.medium18}
    line-height: 26px;
  }
`;

export const StyledDescription = styled.div`
  ${typography.regular14}
  line-height: 20px;
`;

export const StyledButton = styled.div`
  ${typography.medium16}
  text-align: center;
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  padding: 11px 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.neutral[0]};
`;
