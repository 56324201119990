import { Loading } from '../loading';
import { LoaderWrapper } from './loader.styles';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <Loading />
    </LoaderWrapper>
  );
};
