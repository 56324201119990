import { media, styled, typography } from '@pulse-web-ui/theme';
import { TabsList } from '@shared/ui/tab';

export const StyledLabel = styled.div`
  ${typography.medium24}
  ${({ theme }) => theme.colors.text.primary}
  line-height: 32px;
  margin-bottom: 12px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const StyledBox = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  gap: 16px;
  color: ${({ theme }) => theme.colors.text.primary};

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
`;

export const TabListStyled = styled(TabsList)`
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;
