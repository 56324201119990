import { styled } from '@pulse-web-ui/theme';

export const SportStyledBox = styled.div`
  &:first-child {
    margin-top: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
