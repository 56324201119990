import { styled, typography, media } from '@pulse-web-ui/theme';

export const ButtonDownloadStyled = styled.button<{ type?: 'square' }>`
  ${typography.medium16};
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  line-height: 24px;
  padding: ${({ type }) => (type === 'square' ? '8px' : '8px 12px')};
  height: fit-content;

  ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral[20]};
    }
  }
`;
