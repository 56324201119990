import { styled } from '@pulse-web-ui/theme';

export const InsuranceCoveragesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0px 0px 0px;
`;

export const InsuranceCoveragesContent = styled.div`
  display: flex;
  gap: 12px;
`;

export const InsuranceCoveragesImg = styled.img`
  flex: 0 0 40px;
  height: 40px;
`;
