import i18n from '@app/i18n/config';
import { ModalEnums } from '@shared/const';

export const TITLE_MODAL: Record<ModalEnums, string> = {
  [ModalEnums.TOTAL_SUM_INSURED]: i18n.t(
    'INSURANCE_DETAIL:terms.totalSumInsured',
  ),
  [ModalEnums.INSURANCE_RISKS]: i18n.t('INSURANCE_DETAIL:terms.insuranceRisks'),
  [ModalEnums.REPORT_INSURED_EVENT]: i18n.t(
    'INSURANCE_DETAIL:button.reportInsuredEvent',
  ),
  [ModalEnums.REPORT_INSURED_EVENT_SHORT]: i18n.t(
    'INSURANCE_DETAIL:button.getHelp',
  ),
  [ModalEnums.REPORT_INSURED_EVENT_SHORT_VZR]: i18n.t(
    'INSURANCE_DETAIL:button.getHelp',
  ),
  [ModalEnums.CANCEL_SUBSCRIPTION]: i18n.t(
    'INSURANCE_DETAIL:button.cancelSubscription',
  ),
  [ModalEnums.RENEW_SUBSCRIPTION]: i18n.t(
    'INSURANCE_DETAIL:title.renewSubscription',
  ),
  [ModalEnums.SPORTS_KINDS]: i18n.t('INSURANCE_DETAIL:title.sportKinds'),
  [ModalEnums.SPORT_INSURANCE_RISKS]: i18n.t(
    'INSURANCE_DETAIL:title.insuredRisks',
  ),
  [ModalEnums.TRAVEL_INSURANCE_RISKS]: i18n.t(
    'INSURANCE_DETAIL:title.insuredRisks',
  ),
  [ModalEnums.TRAVEL_ACTIVITIES]: i18n.t('INSURANCE_DETAIL:title.activities'),
  [ModalEnums.INSURED_PERSON]: i18n.t('INSURANCE_DETAIL:title.insuredPerson'),
  [ModalEnums.TERMINATE_THE_CONTRACT]: i18n.t(
    'INSURANCE_DETAIL:title.terminateTheContract',
  ),
  [ModalEnums.TERMINATE_THE_CONTRACT_SHORT]: i18n.t(
    'INSURANCE_DETAIL:title.terminateTheContract',
  ),
  [ModalEnums.ASK_QUESTION]: i18n.t('INSURANCE_DETAIL:title.askQuestion'),
  [ModalEnums.DOWNLOAD_APP]: i18n.t('INSURANCE_DETAIL:title.downloadApp'),
  [ModalEnums.MED_AND_INCIDENT_EXPENSES]: i18n.t(
    'INSURANCE_DETAIL:title.medAndIncidentExpenses',
  ),
  [ModalEnums.COUNTRIES_TRAVEL]: i18n.t('INSURANCE_DETAIL:title.countries'),
  [ModalEnums.CONTRACT_INSURANCE_RISKS]: i18n.t(
    'INSURANCE_DETAIL:title.insuredRisks',
  ),
  [ModalEnums.VIEW_CONTRACTS]: i18n.t('CONTRACT:title.viewContracts'),
  [ModalEnums.VIEW_CONTRACT]: i18n.t('CONTRACT:title.viewContract'),
};
