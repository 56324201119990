import { ProductName } from '../types';

export const excludedProducts: Set<ProductName> = new Set([
  ProductName.BIKE,
  ProductName.PARTNER,
  ProductName.CAMP,
  ProductName.MORTGAGE,
  ProductName.MOTION,
  ProductName.MITE,
]);
