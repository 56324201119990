import { useCallback, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { SubTitleInfoDetail, TitleInfoDetail } from '../shared';
import { Button, IconWrapper } from '../info-with-modal';
import { ArrowDown, ArrowUp } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import { InfoWrapper } from '../info-with-download';

interface InfoWithAccordionProps {
  title?: string;
  subTitle: ReactNode;
  hint: ReactNode;
}

export const InfoWithAccordion: FC<InfoWithAccordionProps> = ({
  title,
  subTitle,
  hint,
}) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const Arrow = isOpen ? ArrowUp : ArrowDown;

  const handleClick = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div>
      <Button onClick={handleClick}>
        <InfoWrapper>
          {title && <TitleInfoDetail>{title}</TitleInfoDetail>}
          <SubTitleInfoDetail>{subTitle}</SubTitleInfoDetail>
        </InfoWrapper>
        <IconWrapper>
          <Arrow color={theme.colors.icon.quaternary} />
        </IconWrapper>
      </Button>
      {isOpen && hint}
    </div>
  );
};
