import type { FC } from 'react';
import { Duration } from './validate-period.styles';

type Props = {
  period: string;
  duration?: string;
};

export const ValidatePeriod: FC<Props> = ({ period, duration }) => {
  return (
    <>
      {period}
      {duration && <Duration> · {duration}</Duration>}
    </>
  );
};
