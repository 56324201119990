import { InsuranceStatus } from '@shared/types';
import { Trans, useTranslation } from 'react-i18next';
import {
  BoxStyled,
  StyledInsuranceSubTitle,
  StyledPhoneLink,
} from './short-morgage.styles';
import { getInsuranceStatuses, TitleDetail } from '@entities/insurance-detail';

interface ShortMorgageMessageProps {
  status: InsuranceStatus;
}

export const ShortMorgageMessage = ({ status }: ShortMorgageMessageProps) => {
  const { t } = useTranslation();
  const { isRevoked, isNewNotActiveYet } = getInsuranceStatuses(status);
  const isVisible = !isRevoked && !isNewNotActiveYet;

  if (!isVisible) {
    return;
  }

  return (
    <BoxStyled>
      <TitleDetail>{t('INSURANCE_DETAIL:text:getHelpFromMotion')}</TitleDetail>
      <StyledInsuranceSubTitle>
        <Trans
          components={{
            a: <StyledPhoneLink />,
          }}
        >
          {t('INSURANCE_DETAIL:text:mortgageShortText')}
        </Trans>
      </StyledInsuranceSubTitle>
    </BoxStyled>
  );
};
