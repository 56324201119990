import { makeAutoObservable } from 'mobx';
import { AuthStore } from '@entities/auth';
import { UserStore } from '@entities/profile';
import { InsuranceStore } from '@entities/insurance';
import { ErrorBoundaryStore } from '@entities/error-boundary';
import { TitleStore } from '@entities/title';
import { InsuranceDetailStore } from '@entities/insurance-detail';
import { PrevInsuranceStore } from '@entities/previous-insurance';
import { QueryStore } from '@shared/lib/react-query';
import { ModalStore } from '@app/modal';
import { InsuredPersonStore } from '@entities/insured-person';
import { KindSportStore } from '@entities/kind-sport';
import { InsuranceCoverageStore } from '@entities/insurance-coverage';
import { InsuranceSubObjectStore } from '@entities/insurance-sub-object';
import { TabsInsuranceStore } from '@entities/tabs-insurance';
import { ContractStore } from '@entities/contract';
import { ContractDetailStore } from '@entities/contract-detail';

export class MainStore {
  public authStore: AuthStore;
  public userStore: UserStore;
  public insuranceStore: InsuranceStore;
  public errorBoundaryStore: ErrorBoundaryStore;
  public titleStore: TitleStore;
  public insuranceDetailStore: InsuranceDetailStore;
  public prevInsuranceStore: PrevInsuranceStore;
  public queryStore: QueryStore;
  public modalStore: ModalStore;
  public insuredPersonStore: InsuredPersonStore;
  public kindSportStore: KindSportStore;
  public insuranceCoverageStore: InsuranceCoverageStore;
  public insuranceSubObjectStore: InsuranceSubObjectStore;
  public tabsInsuranceStore: TabsInsuranceStore;
  public contractStore: ContractStore;
  public contractDetailStore: ContractDetailStore;

  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.insuranceStore = new InsuranceStore();
    this.errorBoundaryStore = new ErrorBoundaryStore();
    this.titleStore = new TitleStore();
    this.insuranceDetailStore = new InsuranceDetailStore();
    this.prevInsuranceStore = new PrevInsuranceStore();
    this.queryStore = new QueryStore();
    this.modalStore = new ModalStore();
    this.insuredPersonStore = new InsuredPersonStore();
    this.kindSportStore = new KindSportStore();
    this.insuranceCoverageStore = new InsuranceCoverageStore();
    this.insuranceSubObjectStore = new InsuranceSubObjectStore();
    this.tabsInsuranceStore = new TabsInsuranceStore();
    this.contractStore = new ContractStore();
    this.contractDetailStore = new ContractDetailStore();
    makeAutoObservable(this);
  }
}
