import { styled } from '@pulse-web-ui/theme';

export const InsuranceSubObjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0px 0px 0px;
`;

export const InsuranceSubObjectsContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;
