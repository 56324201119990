import { media, styled, typography } from '@pulse-web-ui/theme';
import { Box } from '@shared/ui';

export const StyledTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;

  ${media.desktop} {
    padding: 24px;
  }
`;

export const IconBox = styled.div`
  width: 56px;
  height: 56px;
`;

export const SubTitle = styled.div`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 24px;
`;
