import { styled, typography, media } from '@pulse-web-ui/theme';

export const Description = styled.h4`
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};

  margin: 8px 0px 0px 0px;

  ${media.desktop} {
    ${typography.regular18}
    line-height: 26px;
  }
`;

export const DescriptionSpan = styled.span`
  ${media.desktop} {
    display: block;
  }
`;
