import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/lib/mobx';
import { Error } from '@entities/error-boundary/ui/error';

import type { FC, ReactNode } from 'react';
import { Wrapper } from './error-boundary-provider.styles';

type Props = {
  errorSecondaryType?: boolean;
  children: ReactNode;
};

export const ErrorBoundaryProvider: FC<Props> = observer(
  ({ errorSecondaryType, children }) => {
    const {
      GlobalStore: {
        errorBoundaryStore: { hasError },
      },
    } = useStores();

    return (
      <>
        {hasError && <Error errorSecondaryType={errorSecondaryType} />}
        <Wrapper hasError={hasError}>{children}</Wrapper>
      </>
    );
  },
);
