import { Label } from '@shared/ui';
import { useStores } from '@shared/lib/mobx';
import { StyledModalBox } from '@shared/ui/modal-box';

export const CountriesTravelModal = () => {
  const {
    GlobalStore: {
      insuranceDetailStore: { insuranceDetail },
    },
  } = useStores();

  const territories = insuranceDetail?.territories;

  if (!territories) return null;

  return (
    <>
      {territories?.map(item => (
        <StyledModalBox key={item.code}>
          <Label>{item.name}</Label>
        </StyledModalBox>
      ))}
    </>
  );
};
