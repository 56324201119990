import { styled, media, typography } from '@pulse-web-ui/theme';

export const LabelStyled = styled.span`
  font-style: normal;
  ${typography.medium16}
  line-height: 24px;

  ${media.desktop} {
    ${typography.medium18}
  }
`;
