import type { InsuranceSubObject } from '@shared/types';
import { makeAutoObservable } from 'mobx';

export class InsuranceSubObjectStore {
  insuranceSubObjects: InsuranceSubObject[];
  insuranceSum: number;

  constructor() {
    makeAutoObservable(this);
  }

  setInsuranceSubObjects = (e: InsuranceSubObject[]) => {
    this.insuranceSubObjects = e;
  };

  setInsuranceSum = (e: number) => {
    this.insuranceSum = e;
  };
}
