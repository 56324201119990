import type { ReactNode } from 'react';

import { useIsDesktop } from '@shared/hooks';
import { MainButton } from '@shared/ui/main-button';

import {
  NavLinkStyled,
  MobileNavLinkAsButtonStyled,
} from './nav-link-as-button.styles';
import { NavLinkProps } from 'react-router-dom';

type NavLinkAsButtonVariant = 'default' | 'mobile';

interface NavLinkAsButtonProps extends NavLinkProps {
  to: string;
  color?: string;
  children: ReactNode;
  onClick?: () => void;
  variant?: NavLinkAsButtonVariant;
}

export const NavLinkAsButton = (props: NavLinkAsButtonProps) => {
  const { to, color, children, onClick, variant = 'default', ...rest } = props;
  const isDesktop = useIsDesktop();

  if (variant === 'mobile') {
    return (
      <MobileNavLinkAsButtonStyled to={to} color={color} {...rest}>
        {children}
      </MobileNavLinkAsButtonStyled>
    );
  }

  return (
    <NavLinkStyled to={to} color={color} {...rest}>
      <MainButton onClick={onClick} adaptiveWidth={!isDesktop}>
        {children}
      </MainButton>
    </NavLinkStyled>
  );
};
