import { usePostMutation } from '@shared/api';
import type { ErrorResponse } from '@shared/api';
import type { MutationOptions } from '@tanstack/react-query';
import type { ProfileResponse, ProfileRequest } from '../types';
import { Endpoints } from '@shared/const';

export const useFetchProfile = (
  options: MutationOptions<ProfileResponse, ErrorResponse, ProfileRequest>,
) => {
  const fetchResult = usePostMutation<ProfileRequest, ProfileResponse>({
    url: Endpoints.GET_PROFILE,
    options,
  });
  return fetchResult;
};
