export enum ModalEnums {
  INSURANCE_RISKS = 'insurance_risks',
  TOTAL_SUM_INSURED = 'total_sum_insured',
  REPORT_INSURED_EVENT = 'report_insured_event',
  CANCEL_SUBSCRIPTION = 'cancel_subscription',
  RENEW_SUBSCRIPTION = 'renew_subscription',
  SPORTS_KINDS = 'sports_kinds',
  SPORT_INSURANCE_RISKS = 'sport_insurance_risks',
  TRAVEL_INSURANCE_RISKS = 'travel_insurance_risks',
  TRAVEL_ACTIVITIES = 'travel_activities',
  INSURED_PERSON = 'insured_person',
  TERMINATE_THE_CONTRACT = 'terminate_the_contract',
  TERMINATE_THE_CONTRACT_SHORT = 'terminate_the_contract_short',
  ASK_QUESTION = 'ask_question',
  DOWNLOAD_APP = 'download_app',
  REPORT_INSURED_EVENT_SHORT = 'report_insured_event_short',
  REPORT_INSURED_EVENT_SHORT_VZR = 'report_insured_event_short_vzr',
  MED_AND_INCIDENT_EXPENSES = 'med_and_incident_expenses',
  COUNTRIES_TRAVEL = 'countries_travel',
  CONTRACT_INSURANCE_RISKS = 'contract_insurance_risks',
  VIEW_CONTRACTS = 'view_contracts',
  VIEW_CONTRACT = 'view_contract',
}
