import { ModalEnums, SUPPORT_TEL_VZR } from '@shared/const';
import { ButtonsWrapperForModal } from '../common';

export const ReportInsuredEventModalShortVzr = () => {
  return (
    <ButtonsWrapperForModal
      modalType={ModalEnums.REPORT_INSURED_EVENT_SHORT}
      tel={SUPPORT_TEL_VZR}
    />
  );
};
