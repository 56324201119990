import { ModalEnums } from '@shared/const';
import {
  ButtonsWrapperForModal,
  Description,
  DescriptionSpan,
} from '../common';
import { Trans } from 'react-i18next';

export const CancelSubscriptionModal = () => {
  return (
    <>
      <Description>
        <Trans
          components={{ span: <DescriptionSpan /> }}
          defaults='INSURANCE_DETAIL:text.cancelSubscription'
        />
      </Description>
      <ButtonsWrapperForModal modalType={ModalEnums.CANCEL_SUBSCRIPTION} />
    </>
  );
};
