import type { ModalEnums } from '@shared/const';
import { makeAutoObservable } from 'mobx';

export class ModalStore {
  modal: ModalEnums | null = null;
  prevModal: ModalEnums | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setModal = ({
    modal,
    prevModal,
  }: {
    modal: ModalEnums | null;
    prevModal?: ModalEnums;
  }) => {
    this.modal = modal;
    this.prevModal = prevModal || undefined;
  };
}
