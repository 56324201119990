import { BackWrapper, Header, TitleWrapper, Wrapper } from './title.styles';
import { useTitle } from '../model/hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Back } from './back';
import { VariantBackButton } from '../model/constants';
import type { FC } from 'react';

interface TitleProps {
  onClick?: () => void;
}

export const Title: FC<TitleProps> = observer(() => {
  const { t } = useTranslation();

  const { title, variantBackButton, handleGoToBack } = useTitle();

  return (
    <Wrapper>
      <TitleWrapper>
        {variantBackButton && (
          <BackWrapper>
            <Back onBack={handleGoToBack} />
            {variantBackButton === VariantBackButton.TEXT &&
              t('COMMON:button.back')}
          </BackWrapper>
        )}
        <Header size='small'>{title}</Header>
      </TitleWrapper>
      {/* TODO: Скрыть до добавления чата */}
      {/* {isDesktop && showButtonChat && (
        <Button onClick={onClick} icon={<LightningIcon />}>
          {t('COMMON:button:insuranceCase')}
        </Button>
      )} */}
    </Wrapper>
  );
});
