import { useCallback } from 'react';
import type { FC } from 'react';
import {
  IconWrapper,
  ModalContent,
  ModalHeaderStyled,
  ModalStyled,
  ModalWrapperStyled,
} from './modal.styles';
import { Close } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import { observer } from 'mobx-react-lite';
import { Portal } from '@shared/lib/react-dom';
import { useModal, useResizeModal } from '../hooks';
import { Title } from '@shared/ui';
import { ModalEnums } from '@shared/const';
import {
  InsuranceCoveragesModal,
  InsuranceSubObjectsModal,
  ReportInsuredEventModal,
  CancelSubscriptionModal,
  RenewSubscriptionModal,
  SportKindsModal,
  InsuredRisksModal,
  InsuredPersonsModal,
  TerminateTheContractModal,
  DownloadAppModal,
  ReportInsuredEventModalShort,
  TerminateTheContractShortModal,
  TravelInsuredRisksModal,
  TravelActivitieslModal,
  MedAndIncidentExpensesModal,
  CountriesTravelModal,
  ViewContractsModal,
  ReportInsuredEventModalShortVzr,
} from '@entities/insurance-detail';
import { ContractInsuranceRisksModal } from '@entities/contract-detail/ui/modals';

export const Modal: FC = observer(() => {
  const theme = useTheme();

  const { title, toogleModal, modal } = useModal();
  const { modal: modalWrapper, modalContent } = useResizeModal(modal);

  const getActualContentForModal = useCallback(() => {
    switch (modal) {
      case ModalEnums.INSURANCE_RISKS:
        return <InsuranceCoveragesModal />;
      case ModalEnums.TOTAL_SUM_INSURED:
        return <InsuranceSubObjectsModal />;
      case ModalEnums.REPORT_INSURED_EVENT:
        return <ReportInsuredEventModal />;
      case ModalEnums.REPORT_INSURED_EVENT_SHORT:
        return <ReportInsuredEventModalShort />;
      case ModalEnums.REPORT_INSURED_EVENT_SHORT_VZR:
        return <ReportInsuredEventModalShortVzr />;
      case ModalEnums.CANCEL_SUBSCRIPTION:
        return <CancelSubscriptionModal />;
      case ModalEnums.RENEW_SUBSCRIPTION:
        return <RenewSubscriptionModal />;
      case ModalEnums.SPORTS_KINDS:
        return <SportKindsModal />;
      case ModalEnums.SPORT_INSURANCE_RISKS:
        return <InsuredRisksModal />;
      case ModalEnums.TRAVEL_INSURANCE_RISKS:
        return <TravelInsuredRisksModal />;
      case ModalEnums.TRAVEL_ACTIVITIES:
        return <TravelActivitieslModal />;
      case ModalEnums.INSURED_PERSON:
        return <InsuredPersonsModal />;
      case ModalEnums.TERMINATE_THE_CONTRACT:
        return <TerminateTheContractModal />;
      case ModalEnums.TERMINATE_THE_CONTRACT_SHORT:
        return <TerminateTheContractShortModal />;
      case ModalEnums.ASK_QUESTION:
        return <ReportInsuredEventModal />;
      case ModalEnums.DOWNLOAD_APP:
        return <DownloadAppModal />;
      case ModalEnums.MED_AND_INCIDENT_EXPENSES:
        return <MedAndIncidentExpensesModal />;
      case ModalEnums.COUNTRIES_TRAVEL:
        return <CountriesTravelModal />;
      case ModalEnums.CONTRACT_INSURANCE_RISKS:
        return <ContractInsuranceRisksModal />;
      case ModalEnums.VIEW_CONTRACT:
      case ModalEnums.VIEW_CONTRACTS:
        return <ViewContractsModal />;
      default:
        return undefined;
    }
  }, [modal]);

  if (!modal) return;

  return (
    <Portal>
      <ModalWrapperStyled onClick={toogleModal}>
        <ModalStyled
          ref={modalWrapper.ref}
          height={modalWrapper.height}
          onClick={e => e.stopPropagation()}
        >
          <ModalHeaderStyled>
            <Title>{title}</Title>
            <IconWrapper onClick={toogleModal}>
              <Close width={24} color={theme.colors.icon.primary} />
            </IconWrapper>
          </ModalHeaderStyled>
          <ModalContent ref={modalContent.ref} height={modalContent.height}>
            {getActualContentForModal()}
          </ModalContent>
        </ModalStyled>
      </ModalWrapperStyled>
    </Portal>
  );
});
