import type { SubscriptionTerm } from '@shared/types';
import i18n from '@app/i18n/config';
import { ContractAboutPolicy } from '@entities/contract-detail/ui';
import { Info } from '@shared/const';

export const getAboutPolicy = (): SubscriptionTerm | undefined => {
  return {
    label: i18n.t('INSURANCE_DETAIL:terms.aboutPolicy'),
    value: i18n.t('INSURANCE_DETAIL:terms.howProtectionWorks'),
    type: Info.WITH_ACCORDION,
    hint: <ContractAboutPolicy />,
  };
};
