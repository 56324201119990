import { Trans, useTranslation } from 'react-i18next';
import i18n from '@app/i18n/config';
import {
  BoxStyled,
  StyledInsuranceSubTitle,
  TitleDetail,
} from '../shared';
import { openMobileAppLink } from '@shared/utils';
import { ExternalLink } from '@shared/ui';

export const ShortPetsMessage = () => {
  const { t } = useTranslation();
  return (
    <BoxStyled>
      <TitleDetail>
        {t('INSURANCE_DETAIL:text:getHelpFromVeterinarian')}
      </TitleDetail>
      <StyledInsuranceSubTitle>
        <Trans
          components={{
            a: <ExternalLink />,
            button: <ExternalLink onClick={openMobileAppLink} />,
          }}
        >
          {i18n.t('INSURANCE_DETAIL:text:getHelpFromVeterinarianSubTitle')}
        </Trans>
      </StyledInsuranceSubTitle>
    </BoxStyled>
  );
};
