/* eslint-disable @typescript-eslint/no-explicit-any */
interface openPdfProps {
  base64Pdf?: string;
  name?: string;
  url?: string;
}

export const openPdf = ({
  base64Pdf,
  name = 'document',
  url,
}: openPdfProps): void => {
  if (url) {
    window.open(url, '_blank');
  }

  if (!base64Pdf) return;

  const pdfBLob = new Blob([base64Pdf], {
    type: 'application/pdf',
  });

  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(pdfBLob, `${name}.pdf`);
  } else {
    const url = URL.createObjectURL(pdfBLob);
    window.open(url, '_blank');
  }
};
