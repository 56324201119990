import { styled, typography } from '@pulse-web-ui/theme';
import { ButtonInsurance } from '../../../../shared';

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px 0px 0px;
`;

export const ButtonModalStyled = styled(ButtonInsurance)`
  ${typography.medium18}
  width: 100%;
  height: 48px;
`;
