import { styled } from '@pulse-web-ui/theme';

export const AsideStyled = styled.aside`
  width: 288px;
  position: relative;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  background-color: ${({ theme }) => theme.colors.neutral[0]};
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
`;

export const TopSidebar = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
