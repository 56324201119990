import { useTranslation } from 'react-i18next';
import { Description } from '../common';
import { QRCodeDownloadApp } from '@shared/ui';

export const DownloadAppModal = () => {
  const { t } = useTranslation();

  return (
    <Description>
      {t('INSURANCE_DETAIL:text.pointCameraQRCodeForDownloadApp')}
      <QRCodeDownloadApp />
    </Description>
  );
};
