import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes, publicRoutePath } from '@shared/lib/react-router';
import { useIsAuth } from '@shared/hooks';

export const useAuth = () => {
  const isAuth = useIsAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate(publicRoutePath[PublicRoutes.AUTH]);
    }
  }, [isAuth]);
};
