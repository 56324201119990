import { styled, media } from '@pulse-web-ui/theme';

export const MainStyled = styled.main`
  width: 100vw;
  height: 100dvh;
  background-color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const ContentStyled = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;

  ${media.desktop} {
    padding-top: 64px;
  }
`;
