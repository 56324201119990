import { makeAutoObservable } from 'mobx';
import type { PrevInsuranceResponse } from './types';
import type { PreviousContract } from '@shared/types';
import {
  getPrevInsurancesForFilter,
  preparePrevInsuranceDetail,
} from './utils';

export class PrevInsuranceStore {
  prevInsurances: PreviousContract[] = [];
  prevInsuranceDetail: PrevInsuranceResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setPrevInsurances = (e: PreviousContract[]) => {
    this.prevInsurances = e;
  };

  setPrevInsuranceDetail = (e: PrevInsuranceResponse) => {
    this.prevInsuranceDetail = e;
  };

  get prevInsurancesByYear() {
    return getPrevInsurancesForFilter(this.prevInsurances);
  }

  get preparedPrevInsuranceDetail() {
    return preparePrevInsuranceDetail(this.prevInsuranceDetail);
  }
}
