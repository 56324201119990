import { InsuranceStatus } from '@shared/types';
import type { GetSubscriptionInfoResponse, SubscriptionTerm } from '../types';
import { getPolicyValidatePeriod } from './get-policy-validate-period';
import { getDuration } from './get-duration';
import i18n from '@app/i18n/config';
import { ValidatePeriod } from '@entities/insurance-detail/ui/validate-period';

export const getTermPolicyValidityPeriod = (
  insurance: GetSubscriptionInfoResponse,
): SubscriptionTerm => {
  if (insurance.status === InsuranceStatus.REVOKED) {
    return { label: '', value: '' };
  }

  const period = getPolicyValidatePeriod(
    insurance.effectiveSince,
    insurance.endsAt,
  );

  if (insurance.status === InsuranceStatus.TERMINATED) {
    return {
      label: i18n.t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
      value: <ValidatePeriod period={period} />,
    };
  }

  const duration = getDuration(insurance.currentContractDuration);

  return {
    label: i18n.t('INSURANCE_DETAIL:terms.policyValidityPeriod'),
    value: <ValidatePeriod duration={duration} period={period} />,
  };
};
