import type { FC, ReactNode } from 'react';

import { SubTitleStyled } from './sub-title.styles';

type Props = {
  children: ReactNode;
  className?: string
};

export const SubTitle: FC<Props> = ({ children, className }) => {
  return <SubTitleStyled className={className}>{children}</SubTitleStyled>;
};
