import { makeAutoObservable } from 'mobx';
import type { GetSubscriptionInfoResponse } from './types';
import { prepareInsuranceDetail } from './utils';

export class InsuranceDetailStore {
  insuranceDetail?: GetSubscriptionInfoResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setInsuranceDetail = (e: GetSubscriptionInfoResponse) => {
    this.insuranceDetail = e;
  };

  clearInsuranceDetail = () => {
    this.insuranceDetail = undefined
  }

  get preparedInsuranceDetail() {
    return prepareInsuranceDetail(this.insuranceDetail);
  }
}
