import type { GetSubscriptionInfoResponse, InsuranceDetail } from '../types';
import {
  getInsuranceStatuses,
  ShortMiteMessage,
} from '@entities/insurance-detail';
import { ProductName } from '@entities/product';
import { HeaderSubTitle } from '@entities/insurance-detail/ui/header-subtitle';
import { getAlertTexts } from './get-alert-texts';
import { getFooterBox } from './get-footer-box';
import { getDocumentsBlock } from './get-documents-block';

export const prepareDetailMiteSubscription = (
  insurance: GetSubscriptionInfoResponse,
): InsuranceDetail => {
  const { isRevoked, isFinished, isNewNotActiveYet } = getInsuranceStatuses(
    insurance.status,
  );
  
  const isOtherBiteMessageShown = Boolean(
    insurance.antimiteDetails?.insuranceSumNS,
  );

  return {
    header: {
      title: insurance?.product.name,
      subTitle: <HeaderSubTitle insurance={insurance} showFullName />,
      productIcon: ProductName.MITE,
    },
    alert: getAlertTexts(insurance),
    showAlertInfo: !isRevoked && !isFinished,
    footerBox: getFooterBox(insurance),
    documents: getDocumentsBlock(insurance),
    shortInfoBlockComponent: !isRevoked && !isNewNotActiveYet && (
      <ShortMiteMessage showOtherBiteMessage={isOtherBiteMessageShown} />
    ),
    showMessageButton: false,
  };
};
