import { ColorStatus } from '@entities/status';
import { InsuranceStatus } from '@shared/types';

export const STATUS_INSURANCE_SNIPPET = {
  [InsuranceStatus.NEW_NOT_ACTIVE_YET]: ColorStatus.INFO,
  [InsuranceStatus.ACTIVE]: ColorStatus.SUCCESS,
  [InsuranceStatus.PAYMENT_FAILED]: ColorStatus.ERROR,
  [InsuranceStatus.PAYMENT_STOPPED]: ColorStatus.WARNING,
  [InsuranceStatus.CANCELLED]: ColorStatus.WARNING,
  [InsuranceStatus.SUSPENDED]: ColorStatus.WARNING,
  [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: ColorStatus.WARNING,
  [InsuranceStatus.AWAITING_APPROVAL]: ColorStatus.ERROR,
  [InsuranceStatus.REVOKED]: ColorStatus.ERROR,
  [InsuranceStatus.FINISHED]: ColorStatus.ERROR,
  [InsuranceStatus.TERMINATED]: ColorStatus.ERROR,
};
