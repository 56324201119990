import { INSURANCE_ICON_VARIANT } from '@entities/insurance';
import { styled } from '@pulse-web-ui/theme';
import { ProductName } from '@entities/product';

export const IconWrapper = styled.div<{
  productName: ProductName;
  variant: INSURANCE_ICON_VARIANT;
  isPartner?: boolean;
}>`
  border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  height: ${({ variant }) =>
    variant === INSURANCE_ICON_VARIANT.SNIPPET ? '40px' : '56px'};
  padding: 8px;
  width: ${({ variant }) =>
    variant === INSURANCE_ICON_VARIANT.SNIPPET ? '40px' : '56px'};
  background-color: ${({ theme, productName, isPartner }) =>
    theme.colors.product[isPartner ? ProductName.PARTNER : productName]};
`;
