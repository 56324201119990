import { ClientDocumentType } from '@shared/types';
import type { DocumentType } from '@shared/types';

export const getPolicyId = (documents: DocumentType[]): string => {
  const { newDoc, firstDoc } = documents.reduce(
    (acc, doc) => {
      if (doc.clientDocumentType === ClientDocumentType.CONTRACT) {
        if (!acc.firstDoc) {
          acc.firstDoc = doc.clientDocument;
        }
        if (doc.isNew) {
          acc.newDoc = doc.clientDocument;
        }
      }
      return acc;
    },
    { newDoc: '', firstDoc: '' },
  );

  return newDoc || firstDoc;
};
