import { useEffect, useRef, useState, type FC, type ReactNode } from 'react';
import { Content, Wrapper } from './tooltip.styles';

type Props = {
  children: ReactNode;
  content: string;
};

export const Tooltip: FC<Props> = ({ children, content }) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const triggerNode = ref.current;
    const showTooltip = () => {
      setShow(true);
    };

    if (triggerNode) {
      triggerNode.addEventListener('click', showTooltip);
    }

    return () => {
      if (triggerNode) {
        triggerNode.removeEventListener('click', showTooltip);
      }
    };
  }, []);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (show) {
      timerId = setTimeout(() => {
        setShow(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [show]);

  return (
    <Wrapper>
      {show && <Content>{content}</Content>}
      <div ref={ref}>{children}</div>
    </Wrapper>
  );
};
