import { AppRoutes, BusinessCodes, Codes, RoutePath } from '@shared/const';

import { useNavigate } from 'react-router-dom';
import { useFetchProduct } from './use-fetch-product';

export const useProductItems = () => {
  const navigate = useNavigate();

  const result = useFetchProduct();
  const errorStatus = result.error?.response?.status;

  if (
    errorStatus === Codes.ClientErrorBadRequest &&
    BusinessCodes.TECHNICAL_ERROR
  ) {
    navigate(RoutePath[AppRoutes.MAX_ATTEMPTS_ERROR]);
  }

  return result;
};
