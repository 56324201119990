export enum AnalyticEvents {
  // COMMON
  USER_AUTH_OPEN = 'user_auth_open',
  USER_AUTH_TOSTEP_OTP = 'user_auth_tostep_OTP',
  USER_AUTH_OTP_OPEN = 'user_auth_OTP_open',
  USER_AUTH_SIGN_IN = 'user_auth_sign_in',
  USER_REG_OPEN = 'user_reg_open',
  USER_REG_COMPLETED = 'user_reg_completed',
  USER_MAIN_OPEN = 'user_main_open',
  INSURANCE_CASE_MAIN = 'insurance_case_main',
  NEW_PRODUCT = 'new_product',
  ALL_BOUGHT_PRODUCTS_OPEN = 'all_bought_products_open',
  LK_EXIT = 'lk_exit',
  OPEN_CHAT = 'open_chat',
  BUY_INSURANCE_OPEN = 'buy_insurance_open',
  NEW_TRAVEL = 'new_travel',
  NEW_APARTMENT = 'new_flat',
  NEW_HOUSE = 'new_house',
  NEW_PET = 'new_pet',
  NEW_SPORT = 'new_sport',
  NEW_MITE = 'new_mite',
  NEW_MORTGAGE = 'new_mortgage',

  // APARTMENT
  OPEN_BOUGHT_APARTMENT = 'open_bought_flat',
  VIEW_PREVIOUS_POLIS_APARTMENT = 'view_previous_polis_flat',
  DOWNLOAD_POLIS_APARTMENT = 'download_polis_flat',
  COPY_POLIS_APARTMENT = 'copy_polis_flat',
  DOWNLOAD_ALL_DOCS_APARTMENT = 'download_all_docs_flat',
  DOWNLOAD_DOC_APARTMENT = 'download_doc_flat',
  INSURANCE_CASE_APARTMENT = 'insurance_case_flat',
  INSURANCE_CASE_APARTMENT_CALL = 'insurance_case_flat_call',
  INSURANCE_CASE_APARTMENT_CHAT = 'insurance_case_flat_chat',
  CANCEL_APARTMENT = 'cancel_flat',
  CANCEL_APARTMENT_CALL = 'cancel_flat_call',
  CANCEL_APARTMENT_CHAT = 'cancel_flat_chat',
  RENEW_APARTMENT = 'renew_flat',

  // HOUSE
  OPEN_BOUGHT_HOUSE = 'open_bought_house',
  VIEW_PREVIOUS_POLIS_HOUSE = 'view_previous_polis_house',
  DOWNLOAD_POLIS_HOUSE = 'download_polis_house',
  COPY_POLIS_HOUSE = 'copy_polis_house',
  DOWNLOAD_ALL_DOCS_HOUSE = 'download_all_docs_house',
  DOWNLOAD_DOC_HOUSE = 'download_doc_house',
  INSURANCE_CASE_HOUSE = 'insurance_case_house',
  INSURANCE_CASE_HOUSE_CALL = 'insurance_case_house_call',
  INSURANCE_CASE_HOUSE_CHAT = 'insurance_case_house_chat',
  CANCEL_HOUSE = 'cancel_house',
  CANCEL_HOUSE_CALL = 'cancel_house_call',
  CANCEL_HOUSE_CHAT = 'cancel_house_chat',
  RENEW_HOUSE = 'renew_house',

  // SPORT
  OPEN_BOUGHT_SPORT = 'open_bought_sport',
  DOWNLOAD_POLIS_SPORT = 'download_polis_sport',
  COPY_POLIS_SPORT = 'copy_polis_sport',
  DOWNLOAD_ALL_DOCS_SPORT = 'download_all_docs_sport',
  DOWNLOAD_DOC_SPORT = 'download_doc_sport',
  INSURANCE_CASE_SPORT = 'insurance_case_sport',
  INSURANCE_CASE_SPORT_CALL = 'insurance_case_sport_call',
  INSURANCE_CASE_SPORT_CHAT = 'insurance_case_sport_chat',
  CANCEL_SPORT = 'cancel_sport',
  CANCEL_SPORT_CALL = 'cancel_sport_call',
  CANCEL_SPORT_CHAT = 'cancel_sport_chat',

  // TRAVEL
  OPEN_BOUGHT_TRAVEL = 'open_bought_travel',
  DOWNLOAD_POLIS_TRAVEL = 'download_polis_travel',
  COPY_POLIS_TRAVEL = 'copy_polis_travel',
  DOWNLOAD_ALL_DOCS_TRAVEL = 'download_all_docs_travel',
  DOWNLOAD_DOC_TRAVEL = 'download_doc_travel',
  INSURANCE_CASE_TRAVEL = 'help_travel',
  INSURANCE_CASE_TRAVEL_CALL = 'help_travel_call',

  // PET
  OPEN_BOUGHT_PET = 'open_bought_pets',
  VIEW_PREVIOUS_POLIS_PET = 'view_previous_pets',
  DOWNLOAD_ALL_DOCS_PET = 'download_all_docs_pets',
  DOWNLOAD_DOC_PET = 'download_doc_pets',

  // MOTION
  OPEN_BOUGHT_MOTION = 'open_bought_movement',
  VIEW_PREVIOUS_POLIS_MOTION = 'view_previous_movement',
  DOWNLOAD_ALL_DOCS_MOTION = 'download_all_docs_movement',
  DOWNLOAD_DOC_MOTION = 'download_doc_movement',

  // MITE
  OPEN_BOUGHT_MITE = 'open_bought_mite_polis',
  DOWNLOAD_ALL_DOCS_MITE = 'download_all_docs_mite_polis',
  DOWNLOAD_DOC_MITE = 'download_doc_mite_polis',

  // MITE_SUBSCRIBE
  OPEN_BOUGHT_MITE_SUBSCRIBE = 'open_bought_mite_subscription',
  VIEW_PREVIOUS_POLIS_MITE_SUBSCRIBE = 'view_previous_mite_subscription',
  DOWNLOAD_ALL_DOCS_MITE_SUBSCRIBE = 'download_all_docs_mite_subscription',
  DOWNLOAD_DOC_MITE_SUBSCRIBE = 'download_doc_mite_subscription',

  // MORTGAGE
  OPEN_BOUGHT_MORTGAGE = 'open_bought_mortgage',
  DOWNLOAD_ALL_DOCS_MORTGAGE = 'download_all_docs_mortgage',
  DOWNLOAD_DOC_MORTGAGE = 'download_doc_mortgage',

  // PARTNER
  OPEN_BOUGHT_PARTNER = 'open_bought_partner',
  COPY_POLIS_PARTNER = 'copy_polis_partner',
  DOWNLOAD_DOC_PARTNER = 'download_doc_partner',
  INSURANCE_CASE_PARTNER = 'insurance_case_partner',
  INSURANCE_CASE_PARTNER_CALL = 'insurance_case_partner_call',
}

export enum AnalyticEventCases {
  OPEN_BOUGHT = 'open_bought',
  VIEW_PREVIOUS_POLIS = 'view_previous_polis',
  DOWNLOAD_POLIS = 'download_polis',
  COPY_POLIS = 'copy_polis',
  DOWNLOAD_ALL_DOCS = 'download_all_docs',
  DOWNLOAD_DOC = 'download_doc',
  INSURANCE_CASE = 'insurance_case',
  INSURANCE_CASE_CALL = 'insurance_case_call',
  INSURANCE_CASE_CHAT = 'insurance_case_chat',
  CANCEL = 'cancel',
  CANCEL_CALL = 'cancel_call',
  CANCEL_CHAT = 'cancel_chat',
  RENEW = 'renew',  
}
