import i18n from '@app/i18n/config';
import { ExternalLink } from '@shared/ui';
import { openMobileAppLink } from '@shared/utils';
import { Trans } from 'react-i18next';

export const AlertMessageRevokedStatus = () => (
  <Trans
    components={{
      button: <ExternalLink onClick={openMobileAppLink} />,
    }}
  >
    {i18n.t('INSURANCE_DETAIL:alert.message.subscriptionRevoked')}
  </Trans>
);
