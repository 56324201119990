import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';
import type { AnalyticEvents } from '../constants/analytic-event';

export const sendAnalyticEvent = <T>(
  triggerEvent: AnalyticEvents,
  params?: T,
): void => {
  ym('reachGoal', triggerEvent, params);
  amplitude.getInstance().logEvent(triggerEvent, params);
};
