import { styled, media } from '@pulse-web-ui/theme';

export const ModalWrapperStyled = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.overlay.primary};
  padding: 0 8px;
  z-index: 2;

  ${media.desktop} {
    padding: 0;
  }
`;

export const ModalStyled = styled.div<{ height: number }>`
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  background-color: ${({ theme }) => theme.colors.surface.primary};
  padding: 24px 16px;
  width: 100%;
  height: ${({ height }) => (height > 0 ? height + 'px' : 'auto')};
  overflow: hidden;

  ${media.desktop} {
    padding: 24px;
    width: 544px;
  }
`;

export const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrapper = styled.div`
  flex: 0 0 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.surface.background};

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.desktop} {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div<{ height: number }>`
  height: ${({ height }) => (height > 0 ? height + 'px' : 'auto')};
  overflow-y: ${({ height }) => (height > 0 ? 'auto' : 'unset')};
  padding-right: ${({ height }) => (height > 0 ? '10px' : 0)};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.neutral[20]};
    border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  }
`;
