export enum ClientDocumentType {
  MEMO = 'Memo',
  RULES = 'Rules',
  CONTRACT = 'Contract',
  KEY_INFO_DOCUMENT = 'KeyInfoDocument',
  ADDENDUM = 'Addendum',
}

export interface DocumentType {
  clientDocument: string;
  clientDocumentType: ClientDocumentType;
  isNew?: boolean;
}

export interface DocumentContractType
  extends Pick<DocumentType, 'clientDocumentType'> {
  url: string;
}
