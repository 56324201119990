import {
  AnalyticEvents,
  AnalyticEventCases,
} from '../constants/analytic-event';
import { checkIsPartnerProduct, PartnerProductCode, ProductCode } from '@entities/product';
import { sendAnalyticEvent } from './send-analytic-event';

type AnalyticEventHandlersMap = Partial<Record<AnalyticEventCases, () => void>>;
type EventCasesMap = Record<ProductCode, AnalyticEventHandlersMap>;

export interface AnalyticEventCaseParams {
  productCode?: ProductCode | PartnerProductCode;
  productName?: string;
}

export const sendAnalyticEventCase = (
  eventCase: AnalyticEventCases,
  params: AnalyticEventCaseParams,
) => {
  const { productCode } = params;
  if (!productCode) return;

  const isPartnerProduct = checkIsPartnerProduct(productCode);

  if (isPartnerProduct) {
    const eventCasesPartnerMap: AnalyticEventHandlersMap = {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_PARTNER, params),
      [AnalyticEventCases.COPY_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.COPY_POLIS_PARTNER, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_PARTNER, params),
      [AnalyticEventCases.INSURANCE_CASE]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_PARTNER, params),
      [AnalyticEventCases.INSURANCE_CASE_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_PARTNER_CALL, params),
    };
    return eventCasesPartnerMap[eventCase]?.();
  }

  const eventCasesMap: Partial<EventCasesMap> = {
    [ProductCode.APARTMENT]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_APARTMENT, params),
      [AnalyticEventCases.VIEW_PREVIOUS_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.VIEW_PREVIOUS_POLIS_APARTMENT, params),
      [AnalyticEventCases.DOWNLOAD_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_POLIS_APARTMENT, params),
      [AnalyticEventCases.COPY_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.COPY_POLIS_APARTMENT, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_APARTMENT, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_APARTMENT, params),
      [AnalyticEventCases.INSURANCE_CASE]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_APARTMENT, params),
      [AnalyticEventCases.INSURANCE_CASE_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_APARTMENT_CALL, params),
      [AnalyticEventCases.INSURANCE_CASE_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_APARTMENT_CHAT, params),
      [AnalyticEventCases.CANCEL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_APARTMENT, params),
      [AnalyticEventCases.CANCEL_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_APARTMENT_CALL, params),
      [AnalyticEventCases.CANCEL_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_APARTMENT_CHAT, params),
      [AnalyticEventCases.RENEW]: () =>
        sendAnalyticEvent(AnalyticEvents.RENEW_APARTMENT, params),
    },
    [ProductCode.HOUSE]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_HOUSE, params),
      [AnalyticEventCases.VIEW_PREVIOUS_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.VIEW_PREVIOUS_POLIS_HOUSE, params),
      [AnalyticEventCases.DOWNLOAD_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_POLIS_HOUSE, params),
      [AnalyticEventCases.COPY_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.COPY_POLIS_HOUSE, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_HOUSE, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_HOUSE, params),
      [AnalyticEventCases.INSURANCE_CASE]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_HOUSE, params),
      [AnalyticEventCases.INSURANCE_CASE_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_HOUSE_CALL, params),
      [AnalyticEventCases.INSURANCE_CASE_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_HOUSE_CHAT, params),
      [AnalyticEventCases.CANCEL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_HOUSE, params),
      [AnalyticEventCases.CANCEL_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_HOUSE_CALL, params),
      [AnalyticEventCases.CANCEL_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_HOUSE_CHAT, params),
      [AnalyticEventCases.RENEW]: () =>
        sendAnalyticEvent(AnalyticEvents.RENEW_HOUSE, params),
    },
    [ProductCode.SPORT]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_SPORT, params),
      [AnalyticEventCases.DOWNLOAD_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_POLIS_SPORT, params),
      [AnalyticEventCases.COPY_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.COPY_POLIS_SPORT, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_SPORT, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_SPORT, params),
      [AnalyticEventCases.INSURANCE_CASE]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_SPORT, params),
      [AnalyticEventCases.INSURANCE_CASE_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_SPORT_CALL, params),
      [AnalyticEventCases.INSURANCE_CASE_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_SPORT_CHAT, params),
      [AnalyticEventCases.CANCEL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_SPORT, params),
      [AnalyticEventCases.CANCEL_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_SPORT_CALL, params),
      [AnalyticEventCases.CANCEL_CHAT]: () =>
        sendAnalyticEvent(AnalyticEvents.CANCEL_SPORT_CHAT, params),
    },
    [ProductCode.TRAVEL]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_TRAVEL, params),
      [AnalyticEventCases.DOWNLOAD_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_POLIS_TRAVEL, params),
      [AnalyticEventCases.COPY_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.COPY_POLIS_TRAVEL, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_TRAVEL, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_TRAVEL, params),
      [AnalyticEventCases.INSURANCE_CASE]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_TRAVEL, params),
      [AnalyticEventCases.INSURANCE_CASE_CALL]: () =>
        sendAnalyticEvent(AnalyticEvents.INSURANCE_CASE_TRAVEL_CALL, params),
    },
    [ProductCode.PET]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_PET, params),
      [AnalyticEventCases.VIEW_PREVIOUS_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.VIEW_PREVIOUS_POLIS_PET, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_PET, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_PET, params),
    },
    [ProductCode.MOTION]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_MOTION, params),
      [AnalyticEventCases.VIEW_PREVIOUS_POLIS]: () =>
        sendAnalyticEvent(AnalyticEvents.VIEW_PREVIOUS_POLIS_MOTION, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_MOTION, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_MOTION, params),
    },
    [ProductCode.MITE]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_MITE, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_MITE, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_MITE, params),
    },
    [ProductCode.MITE_SUBSCRIBE]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_MITE_SUBSCRIBE, params),
      [AnalyticEventCases.VIEW_PREVIOUS_POLIS]: () =>
        sendAnalyticEvent(
          AnalyticEvents.VIEW_PREVIOUS_POLIS_MITE_SUBSCRIBE,
          params,
        ),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(
          AnalyticEvents.DOWNLOAD_ALL_DOCS_MITE_SUBSCRIBE,
          params,
        ),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_MITE_SUBSCRIBE, params),
    },
    [ProductCode.MORTGAGE]: {
      [AnalyticEventCases.OPEN_BOUGHT]: () =>
        sendAnalyticEvent(AnalyticEvents.OPEN_BOUGHT_MORTGAGE, params),
      [AnalyticEventCases.DOWNLOAD_ALL_DOCS]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_ALL_DOCS_MORTGAGE, params),
      [AnalyticEventCases.DOWNLOAD_DOC]: () =>
        sendAnalyticEvent(AnalyticEvents.DOWNLOAD_DOC_MORTGAGE, params),
    },
  };

  eventCasesMap[productCode]?.[eventCase]?.();
};
