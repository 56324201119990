import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ProtectedRoute,
  PublicRoute,
  protectedRouteConfig,
  publicRouteConfig,
} from '@shared/lib/react-router';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../model/hooks/use-auth';

export const AppRouter = observer(() => {
  useAuth();

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {Object.values(protectedRouteConfig).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={<Suspense fallback={''}>{element}</Suspense>}
          />
        ))}
      </Route>
      <Route element={<PublicRoute />}>
        {Object.values(publicRouteConfig).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={<Suspense fallback={''}>{element}</Suspense>}
          />
        ))}
      </Route>
    </Routes>
  );
});
