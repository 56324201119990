import { useStores } from '@shared/lib/mobx';
import { InsuranceTextWrapper } from '../common';
import { StyledModalBox } from '@shared/ui/modal-box';

export const InsuredRisksModal = () => {
  const {
    GlobalStore: {
      insuranceCoverageStore: { insuranceCoverages },
    },
  } = useStores();

  return (
    <div>
      {insuranceCoverages.map(item => (
        <StyledModalBox key={item.code}>
          <InsuranceTextWrapper
            name={item.name}
            description={item.description}
          />
        </StyledModalBox>
      ))}
    </div>
  );
};
