import { styled } from '@pulse-web-ui/theme';
import { SubTitleStyled } from '@shared/ui/sub-title/sub-title.styles';
import { TitleStyled } from '@shared/ui/title/title.styles';

export const IconBoxStyled = styled.div`
  text-align: center;
`;

export const StyledSubTitle = styled(SubTitleStyled)`
  text-align: center;
`;

export const StyledTitle = styled(TitleStyled)`
  text-align: center;
`;
