import { Label } from '@shared/ui';
import { SportStyledBox } from './sport-kinds.styles';
import { useStores } from '@shared/lib/mobx';

export const SportKindsModal = () => {
  const {
    GlobalStore: {
      kindSportStore: { kindSports },
    },
  } = useStores();

  return (
    <>
      {kindSports.map(item => (
        <SportStyledBox key={item.code}>
          <Label>{item.name}</Label>
        </SportStyledBox>
      ))}
    </>
  );
};
