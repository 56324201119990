import { mixins, typography, styled } from '@pulse-web-ui/theme';
import { ExternalLink } from '@shared/ui';

export const StyledExternalLink = styled(ExternalLink)`
  display: flex;
  ${mixins.flex({ alignItems: 'center', gap: 8 })}
  ${typography.medium18}
  line-height: 26px;
`;

export const StyledBox = styled.div`
  ${mixins.flex({ gap: 16 })}
  flex-direction: column;
  padding-top: 16px;
`;

export const StyledTitle = styled.div`
  ${typography.medium16}
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
