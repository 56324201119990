import { useLayoutEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { createWrapperAndAppendToBody } from '../utils';

export const Portal: FC<
  PropsWithChildren<{
    wrapperId?: string;
  }>
> = ({ wrapperId = 'react-portal-wrapper', children }) => {
  const [wrapperElement, setWrapperElement] = useState<Element | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId) as HTMLElement;
    let systemCreated = false;
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element as Node);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};
