import { media, styled } from '@pulse-web-ui/theme';

export const Button = styled.button`
  align-items: flex-end;
  display: flex;
  border-radius: calc(${({ theme }) => theme.common.radius} * 4);
  justify-content: space-between;
  width: 100%;

  ${media.desktop} {
    align-items: center;
    padding: 8px 24px 8px 16px;
    margin: -8px -16px -8px -16px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.surface.background};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 24px;
`;

export const SubTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;
