import { NavLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import type { PropsWithChildren } from 'react';

interface ProductLinkProps extends NavLinkProps {
  isExternal: boolean;
  to: string;
  onClick: () => void;
}

export const ProductLink = ({
  children,
  isExternal,
  to,
  onClick,
  state,
}: PropsWithChildren<ProductLinkProps>) => {
  if (isExternal) {
    return (
      <a onClick={onClick} href={to} target='_blank'>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={to} onClick={onClick} state={state}>
      {children}
    </NavLink>
  );
};
