import { ProductName } from '@entities/product';
import { InsuranceStatus } from '@shared/types';
import type { AlertType } from '../types';
import i18n from '@app/i18n/config';
import {
  AlertMessageSuspendedStatus,
  AlertMessageRevokedStatus,
  AlertMessageFinishedStatus,
  AlertMessageForMotionProduct,
} from '../../ui/alert-texts';
import type { GetAlertTitleParams } from './get-alert-title';

interface GetAlertMessageParams extends GetAlertTitleParams {}

type InsuranceStatusAlertMessageType = Partial<
  Record<InsuranceStatus, AlertType['message']>
>;
type AlertMessageMapType = Partial<
  Record<ProductName, InsuranceStatusAlertMessageType>
>;

export const getAlertMessage = ({
  productName,
  status,
  endsAt,
}: GetAlertMessageParams) => {
  const AlertMessageMap: AlertMessageMapType = {
    [ProductName.APARTMENT]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.SUSPENDED]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.CANCELLED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.FINISHED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.TERMINATED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.REVOKED]: <AlertMessageRevokedStatus />,
    },
    [ProductName.HOUSE]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.SUSPENDED]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.CANCELLED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.FINISHED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.TERMINATED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.REVOKED]: <AlertMessageRevokedStatus />,
    },
    [ProductName.MOTION]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: (
        <AlertMessageForMotionProduct endsAt={endsAt} />
      ),
      [InsuranceStatus.SUSPENDED]: (
        <AlertMessageForMotionProduct endsAt={endsAt} />
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: (
        <AlertMessageForMotionProduct endsAt={endsAt} />
      ),
      [InsuranceStatus.CANCELLED]: (
        <AlertMessageForMotionProduct endsAt={endsAt} />
      ),
      [InsuranceStatus.FINISHED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.REVOKED]: <AlertMessageRevokedStatus />,
    },
    [ProductName.MITE_SUBSCRIBE]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.SUSPENDED]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.CANCELLED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.FINISHED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.REVOKED]: <AlertMessageRevokedStatus />,
    },
    [ProductName.PET]: {
      [InsuranceStatus.SUSPENDED_NOT_ACTIVE_YET]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.SUSPENDED]: (
        <AlertMessageSuspendedStatus endsAt={endsAt} />
      ),
      [InsuranceStatus.PAYMENT_STOPPED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.CANCELLED]: i18n.t(
        'INSURANCE_DETAIL:alert.message.applyNew',
      ),
      [InsuranceStatus.FINISHED]: <AlertMessageFinishedStatus />,
      [InsuranceStatus.REVOKED]: <AlertMessageRevokedStatus />,
    },
  };

  return AlertMessageMap[productName]?.[status] || '';
};
